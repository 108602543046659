import { faCaretRight, faFileDownload, faFileInvoiceDollar, faFileWord, faFileZipper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useState } from "react";
import { Card, FormGroup, Input, InputGroup, InputGroupText, Label, Spinner, Table } from "reactstrap";
import { Knowledgebase, FilterCandidate, FilterDescription } from "@mashvac/daedex";
import generateDaedexDocx from "../helpers/generate-daedex-docx";
import generateDaikinDocx from "../helpers/generate-daikin-docx";
import { calculateUtilization, calculatePressureDrop, FilterLayout, calculateFilterLayoutPricing } from "../helpers/filter-rules";
import { generateFilterQuote } from "../helpers/generate-pdf";
import { CostBreakdownTooltip } from "../components/CostBreakdownTooltip";
import { generateDrawingDXF } from "../helpers/generate-drawing-dxf";

export interface FilterProductDetails {
    type: "Panel" | "Combo";
    pre_filter_rating: string;
    pre_filter_depth: number;
    final_filter_rating: string;
    final_filter_depth: number;
    air_flow: number;
    width: number;
    height: number;
    pressure_drop: "clean" | "mean" | "dirty" | "user";
    user_pressure_drop: number | "";
    loading: "Front" | "Side";
    rack: "Internal" | "Bolt on";
    arrangement: "Flat" | "Angular";
    door_location: "Both Sides" | "Drive Side" | "Opposite Drive Side";
    door_opening: "Inward" | "Outward";
    door_width_drive_side: number;
    door_width_opposite_drive_side: number;
}

interface ExtendedFilterCandidate extends FilterCandidate {
    key: string;
    utilization: number;
    pressureDrop: {
        clean: number;
        mean: number;
        dirty: number;
        final_clean: number;
        final_mean: number;
        final_dirty: number;
    };
    totalPrice: number;
    priceDetails: any;
}

const KB = new Knowledgebase();
const filterDescriptions: FilterDescription[] = await KB.getFilterDescriptions();
// const filterSizes = await KB.getFilterSizes();
const preFilters: FilterDescription[] = filterDescriptions.filter(f => f.type === "PRE_FILTER");
const finalFilters: FilterDescription[] = filterDescriptions.filter(f => f.type === "FINAL_FILTER");
const preFilterRatings: string[] = Array.from(new Set(preFilters.map(pf => pf.rating)).entries()).map(e => e[0]);
const finalFilterRatings: string[] = Array.from(new Set(finalFilters.map(pf => pf.rating)).entries()).map(e => e[0]);

export default function Filters() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<{ message: string } | undefined>(undefined);
    const [_productDetails, _setProductDetails] = useState<FilterProductDetails>({
        type: "Panel",
        pre_filter_rating: "MERV_8",
        pre_filter_depth: 2,
        final_filter_rating: undefined,
        final_filter_depth: undefined,
        air_flow: 2000,
        width: 100,
        height: 100,
        pressure_drop: "mean",
        user_pressure_drop: "",
        arrangement: "Flat",
        rack: "Internal",
        loading: "Front",
        door_location: "Both Sides",
        door_opening: "Outward",
        door_width_drive_side: 10,
        door_width_opposite_drive_side: 10,
    });
    const [productDetails, setProductDetails] = useState<FilterProductDetails>(_productDetails);
    const [filterCandidates, setFilterCandidates] = useState<ExtendedFilterCandidate[]>([]);
    const [selectedCandidate, setSelectedCandidate] = useState<ExtendedFilterCandidate | undefined>(undefined);
    const [isDoorOptionsOpen, setIsDoorOptionsOpen] = useState<boolean>(false);
    const toggleDoorOptions = () => setIsDoorOptionsOpen(!isDoorOptionsOpen);
    // console.log('preFilterRatings', preFilterRatings);
    // console.log('finalFilterRatings', finalFilterRatings);
    // console.log('filterDescriptions', filterDescriptions);
    // console.log('filterCandidates', filterCandidates);
    // console.log('_productDetails', _productDetails);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else if (e.target.type === "number" || e.target.name === "pre_filter_depth" || e.target.name === "final_filter_depth") {
            try {
                value = parseFloat(e.target.value);
            } catch (e) {
                return;
            }
        } else {
            value = e.target.value;
        }

        const newState = { ..._productDetails, [e.target.name]: value };

        if (e.target.name === "type" && value === "Combo") {
            newState.final_filter_rating = "MERV_14";
            newState.final_filter_depth = 4;
        } else if (e.target.name === "type" && value === "Panel") {
            newState.final_filter_rating = undefined;
            newState.final_filter_depth = undefined;
        } else if (e.target.name === "final_filter_rating" && value === "HEPA_13") {
            newState.final_filter_depth = 12;
        } else if (e.target.name === "pressure_drop" && value !== "user") {
            newState.user_pressure_drop = "";
        }
        _setProductDetails(newState);
    }

    function processFilterCandidates(candidates: FilterCandidate[]): ExtendedFilterCandidate[] {
        const uniqueCandidates = candidates.reduce((unique, candidate) => {
            const key = `${candidate.filters.map(filter => `${filter.width}x${filter.height}x${filter.count}`).join(',')}`;
            if (!unique.keys.has(key)) {
                unique.keys.add(key);

                const utilization = calculateUtilization(candidate.face_area, _productDetails.width, _productDetails.height);
                const pressureDrop = calculatePressureDrop(_productDetails, candidate.face_velocity, filterDescriptions);
                const { totalPrice, priceDetails } = calculateFilterLayoutPricing(_productDetails, candidate, _productDetails.width);

                unique.data.push({ ...candidate, key, utilization, pressureDrop, totalPrice, priceDetails });
            }
            return unique;
        }, { keys: new Set(), data: [] });
        uniqueCandidates.data.sort((a, b) => b.utilization - a.utilization);

        return uniqueCandidates.data;
    }

    const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(undefined);
        try {
            const response: FilterCandidate[] = await KB.filterCandidates({
                bulkheadWidth: _productDetails.width,
                bulkheadHeight: _productDetails.height,
                cfm: _productDetails.air_flow,
                tsp: 1.0, // at this point, tsp is unused in calcs, but required in filterCandidate type
            });
            if (!response.length)
                throw new Error("no filters");
            const processedCandidates: ExtendedFilterCandidate[] = processFilterCandidates(response);
            setFilterCandidates(processedCandidates);
            setSelectedCandidate(processedCandidates[0]);
            setProductDetails(_productDetails);
        } catch (e) {
            console.error(e);
            setError(e);
        }
        setLoading(false);
    }

    return (
        <div className="tools-content">
            <form onSubmit={onSubmit} className="filter-form">
                <div>
                    <div className="box-label">Type</div>
                    <Input
                        name="type"
                        type="select"
                        value={_productDetails.type}
                        onChange={handleChange}
                    >
                        <option value={"Panel"}>Panel</option>
                        <option value={"Combo"}>Combo</option>
                    </Input>
                </div>
                <div className="flex-row-container">
                    <div style={{ width: '66%' }}>
                        <div className="box-label">{_productDetails.type === "Combo" ? "Pre-filter Efficiency" : "Filter Efficiency"}</div>
                        <Input
                            name="pre_filter_rating"
                            type="select"
                            value={_productDetails.pre_filter_rating}
                            onChange={handleChange}
                        >
                            {
                                preFilterRatings.map((filterRating, index) => <option key={index} value={filterRating}>{filterRating.replace(/_/g, ' ')}</option>)
                            }
                        </Input>
                    </div>
                    <div style={{ width: '30%' }}>
                        <div className="box-label">Depth</div>
                        <Input
                            name="pre_filter_depth"
                            type="select"
                            value={_productDetails.pre_filter_depth}
                            onChange={handleChange}
                        >
                            {
                                preFilters.filter(filter => filter.rating === _productDetails.pre_filter_rating).map(({ depth }, index) => <option key={index} value={depth}>{depth}"</option>)
                            }
                        </Input>
                    </div>
                </div>
                <div className={`flex-row-container ${_productDetails.type === "Combo" ? "filter-collapse-open" : "collapse-closed"}`}>
                    <div style={{ width: '66%' }}>
                        <div className="box-label">{_productDetails.type === "Combo" ? "Final-filter Efficiency" : "Filter Efficiency"}</div>
                        <Input
                            name="final_filter_rating"
                            type="select"
                            value={_productDetails.final_filter_rating}
                            onChange={handleChange}
                        >
                            {
                                finalFilterRatings.map((filterRating, index) => <option key={index} value={filterRating}>{filterRating.replace(/_/g, ' ')}</option>)
                            }
                        </Input>
                    </div>
                    <div style={{ width: '30%' }}>
                        <div className="box-label">Depth</div>
                        <Input
                            name="final_filter_depth"
                            type="select"
                            value={_productDetails.final_filter_depth}
                            onChange={handleChange}
                        >
                            {
                                finalFilters.filter(filter => filter.rating === _productDetails.final_filter_rating).map(({ depth }, index) => <option key={index} value={depth}>{depth}"</option>)
                            }
                        </Input>
                    </div>
                </div>
                <div>
                    <div className="box-label">Pressure Drop</div>
                    <Input
                        name="pressure_drop"
                        type="select"
                        value={_productDetails.pressure_drop}
                        onChange={handleChange}
                    >
                        <option value={"clean"}>Clean</option>
                        <option value={"mean"}>Mean</option>
                        <option value={"dirty"}>Dirty</option>
                        <option value={"user"}>User Specified</option>
                    </Input>
                </div>
                <div className={_productDetails.pressure_drop === "user" ? "collapse-open-pressure-drop" : "filter-collapse-closed"}>
                    <InputGroup>
                        <Input
                            name="user_pressure_drop"
                            type="number"
                            value={_productDetails.user_pressure_drop}
                            onChange={handleChange}
                            min={0}
                            step={0.1}
                        />
                        <InputGroupText style={{ fontSize: '11px' }}>inWg</InputGroupText>
                    </InputGroup>
                </div>
                <div>
                    <div className="box-label">Air Flow (cfm)</div>
                    <Input
                        name="air_flow"
                        type="number"
                        value={_productDetails.air_flow}
                        onChange={handleChange}
                        invalid={_productDetails.air_flow < 0}
                        min={0}
                    />
                </div>
                <div className="flex-row-container">
                    <div style={{ width: '48%' }}>
                        <div className="box-label">Unit Height (in)</div>
                        <Input
                            name="height"
                            type="number"
                            value={_productDetails.height}
                            onChange={handleChange}
                        />

                    </div>
                    <div style={{ width: '48%' }}>
                        <div className="box-label" >Unit Width (in)</div>
                        <Input
                            name="width"
                            type="number"
                            value={_productDetails.width}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div style={{ height: '48px'}}>
                    <div className="box-label">Loading</div>
                    <FormGroup check>
                        <Label check style={{ paddingLeft: '0px' }}>
                            <Input
                                name="loading"
                                type="radio"
                                value="Front"
                                checked={_productDetails.loading === "Front"}
                                onChange={handleChange}
                                style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px', backgroundColor: _productDetails.loading === "Front" ? '#1890cb' : 'white' }}
                            />
                            Front
                        </Label>
                        <Label check style={{ marginLeft: '15px' }} >
                            <Input
                                name="loading"
                                type="radio"
                                value="Side"
                                checked={_productDetails.loading === "Side"}
                                onChange={handleChange}
                                style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px', backgroundColor: _productDetails.loading === "Side" ? '#1890cb' : 'white' }}
                            />
                            Side
                        </Label>
                    </FormGroup>
                </div>
                <div style={{ height: '48px'}}>
                    <div className="box-label">Racks</div>
                    <FormGroup check>
                        <Label check style={{ paddingLeft: '0px' }}>
                            <Input
                                name="rack"
                                type="radio"
                                value="Internal"
                                checked={_productDetails.rack === "Internal"}
                                onChange={handleChange}
                                style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px', backgroundColor: _productDetails.rack === "Internal" ? '#1890cb' : 'white' }}
                            />
                            Internal
                        </Label>
                        <Label check style={{ marginLeft: '0px' }}>
                            <Input
                                name="rack"
                                type="radio"
                                value="Bolt on"
                                checked={_productDetails.rack === "Bolt on"}
                                onChange={handleChange}
                                style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px', backgroundColor: _productDetails.rack === "Bolt on" ? '#1890cb' : 'white' }}
                            />
                            Bolt on
                        </Label>
                    </FormGroup>
                </div>
                <div style={{ height: '48px'}}>
                    <div className="box-label">Arrangement</div>
                    <FormGroup check>
                        <Label check style={{ paddingLeft: '0px' }}>
                            <Input
                                name="arrangement"
                                type="radio"
                                value="Flat"
                                checked={_productDetails.arrangement === "Flat"}
                                onChange={handleChange}
                                style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px', backgroundColor: '#1890cb' }}
                            />
                            Flat
                        </Label>
                        <Label check className="grayed-out" style={{ marginLeft: '25px' }}>
                            <Input
                                name="arrangement"
                                type="radio"
                                value="Angular"
                                checked={_productDetails.arrangement === "Angular"}
                                onChange={handleChange}
                                style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px' }}
                                disabled
                            />
                            Angular
                        </Label>
                    </FormGroup>
                </div>

                <div className="door-options-div" onClick={toggleDoorOptions}>
                    <div className="door-label" >
                        <FontAwesomeIcon icon={faCaretRight} className={isDoorOptionsOpen ? "caret-icon open" : "caret-icon"} />
                        Door Options
                    </div>                   
                </div>
                <div  className="flex-column-container" style={{ marginTop: '-.5em' }}>
                    <div className={isDoorOptionsOpen ? "collapse-open-door-options" : "collapse-closed-door-options"}>
                        <div className="box-label" >Location</div>
                        <Input
                            name="door_location"
                            type="select"
                            value={_productDetails.door_location}
                            onChange={handleChange}
                        >
                            <option value={"Both Sides"}>Both Sides</option>
                            <option value={"Drive Side"}>Drive Side</option>
                            <option value={"Opposite Drive Side"}>Opposite Drive Side</option>
                        </Input>
                    </div>
                    <div className={`flex-row-container ${isDoorOptionsOpen ? "collapse-open-door-options" : "collapse-closed-door-options"}`}>
                        <div style={{ width: '48%' }}>
                            <div className="box-label">Drv. Side Width</div>
                            <Input
                                name="door_width_drive_side"
                                type="number"
                                value={_productDetails.door_width_drive_side}
                                onChange={handleChange}
                            />

                        </div>
                        <div style={{ width: '48%' }}>
                            <div className="box-label">Opp. Side Width</div>
                            <Input
                                name="door_width_opposite_drive_side"
                                type="number"
                                value={_productDetails.door_width_opposite_drive_side}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className={isDoorOptionsOpen ? "collapse-open-door-options" : "collapse-closed-door-options"}>
                        <div className="box-label" style={{ marginTop: '-2px' }}>Opening</div>
                        <FormGroup check>
                            <Label check style={{ paddingLeft: '0px' }} >
                                <Input
                                    name="door_opening"
                                    type="radio"
                                    value="Outward"
                                    checked={_productDetails.door_opening === "Outward"}
                                    onChange={handleChange}
                                    style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px', backgroundColor: _productDetails.door_opening === "Outward" ? '#1890cb' : 'white' }}
                                />
                                Outward
                            </Label>
                            <Label check style={{ marginLeft: '-5px' }}>
                                <Input
                                    name="door_opening"
                                    type="radio"
                                    value="Inward"
                                    checked={_productDetails.door_opening === "Inward"}
                                    onChange={handleChange}
                                    style={{ marginLeft: '5px', marginTop: '5px', marginRight: '5px', backgroundColor: _productDetails.door_opening === "Inward" ? '#1890cb' : 'white' }}
                                />
                                Inward
                            </Label>
                        </FormGroup>
                    </div>
                </div>
                <button type="submit">Submit</button>
            </form>

            <div className="filter-result">
                {loading && <div className="loading">
                    <Spinner />
                </div>}
                {!loading && error && (<div>
                    <h3>Error</h3>
                    <pre>{error.message}</pre>
                </div>)}
                {!loading && filterCandidates && selectedCandidate &&
                    <div className="filter-result-items">
                        <Card className="filter-table-container">
                            <Table
                                hover
                                size="sm"
                                className="filter-table"
                            >
                                <thead>
                                    <tr >
                                        <th style={{ width: '16%', textAlign: 'left' }}>Sizing (Count)</th>
                                        <th style={{ width: '14%' }}>Face Velocity</th>
                                        <th style={{ width: '14%' }}>Filter Area</th>
                                        <th style={{ width: '14%' }}>Utilization</th>
                                        <th style={{ width: '20%' }}>Air Pressure Drop</th>
                                        <th style={{ width: '14%' }}>Price</th>
                                        <th style={{ width: '6%' }}></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>(ft/min)</th>
                                        <th>(ft²)</th>
                                        <th>(%)</th>
                                        <th>Clean/Mean/Dirty (inWg)</th>
                                        <th>($)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterCandidates?.map((candidate) => {
                                        return (
                                            <tr key={candidate.key}>
                                                <td style={{ textAlign: 'left' }}>{candidate.filters.map((filter: any, index: number) => (
                                                    <span key={index}>
                                                        {filter.height} x {filter.width} ({filter.count})
                                                        {index < candidate.filters.length - 1 && ', '}
                                                        <br />
                                                    </span>
                                                ))}
                                                </td>
                                                <td>{candidate.face_velocity.toFixed(1)}</td>
                                                <td>{candidate.face_area.toFixed(1)}</td>
                                                <td>{candidate.utilization.toFixed(1)}</td>
                                                <td>
                                                    <span style={productDetails.pressure_drop === 'clean' ? { fontWeight: 'bold' } : {}}>
                                                        {candidate.pressureDrop.clean?.toFixed(2) ?? 'n/a'}
                                                    </span> /{' '}
                                                    <span style={productDetails.pressure_drop === 'mean' ? { fontWeight: 'bold' } : {}}>
                                                        {candidate.pressureDrop.mean?.toFixed(2) ?? 'n/a'}
                                                    </span> /{' '}
                                                    <span style={productDetails.pressure_drop === 'dirty' ? { fontWeight: 'bold' } : {}}>
                                                        {candidate.pressureDrop.dirty?.toFixed(2) ?? 'n/a'}
                                                    </span>
                                                    {productDetails.type === "Combo" && (
                                                        <>
                                                            <br />
                                                            <span style={productDetails.pressure_drop === 'clean' ? { fontWeight: 'bold' } : {}}>
                                                                {candidate.pressureDrop.final_clean?.toFixed(2) ?? 'n/a'}
                                                            </span> /{' '}
                                                            <span style={productDetails.pressure_drop === 'mean' ? { fontWeight: 'bold' } : {}}>
                                                                {candidate.pressureDrop.final_mean?.toFixed(2) ?? 'n/a'}
                                                            </span> /{' '}
                                                            <span style={productDetails.pressure_drop === 'dirty' ? { fontWeight: 'bold' } : {}}>
                                                                {candidate.pressureDrop.final_dirty?.toFixed(2) ?? 'n/a'}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                                <td>{candidate.totalPrice.toFixed(0)}</td>
                                                <td style={{ padding: '0px 0px', textAlign: 'right' }}>
                                                    <button
                                                        className={candidate.key === selectedCandidate.key ? 'selected' : 'not-selected'}
                                                        onClick={() => setSelectedCandidate(candidate)}
                                                    >
                                                        <span>
                                                            {candidate.key === selectedCandidate.key ? <FontAwesomeIcon icon="check" className="ps-1" /> : "Select"}
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Card>

                        <Card className="selected-filter-container">
                            <div className="filter-button-group">
                                <button className='filter-icon' color="primary" title='Download drawing' disabled={productDetails.type === 'Combo' ? true : false} onClick={() => {
                                    generateDrawingDXF({
                                        data: {
                                            product: productDetails,
                                            selection: selectedCandidate,
                                        }
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faFileZipper} />
                                </button>
                                <button className='filter-icon' title='Download quote' onClick={() => {
                                    generateFilterQuote({
                                        data: {
                                            product: productDetails,
                                            selection: selectedCandidate,
                                        }
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                </button>
                                <button className="filter-icon" title='Download Daikin submittal' onClick={() => {
                                    generateDaikinDocx({
                                        docType: productDetails.type === 'Combo' ? 'Combo Filter' : 'Panel Filter',
                                        data: {
                                            product: productDetails,
                                            selection: selectedCandidate
                                        }
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faFileWord} />
                                </button>
                                <button className="filter-icon" title='Download Daedex submittal' disabled={productDetails.type === 'Combo' ? true : false} onClick={() => {
                                    generateDaedexDocx({
                                        docType: 'Filter',
                                        filterData: {
                                            productDetails,
                                            selectedCandidate
                                        }
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faFileDownload} />
                                </button>
                            </div>

                            <span className="selected-filter-header">Selection</span>
                            <div className="selected-filter-layout">
                                <FilterLayout filters={selectedCandidate.rects} unitWidth={productDetails.width} unitHeight={productDetails.height} />
                            </div>
                            <div className="selected-filter-stats">
                                <StatsRow label="Utilization" value={selectedCandidate.utilization.toFixed(1)} symbol="%" />
                                <StatsRow label="Face Velocity" value={selectedCandidate.face_velocity.toFixed(1)} symbol="ft/min" />
                                <StatsRow label="Type" value={productDetails.type} symbol="" />
                                <StatsRow label={productDetails.type === "Combo" ? "Pre-filter Media" : "Media"} value={`${productDetails.pre_filter_depth}" ${productDetails.pre_filter_rating.replace(/_/g, ' ')}`} symbol="" />
                                <StatsRow label={productDetails.type === "Combo" ? "Pre-filter APD" : "Pressure Drop"} value={
                                    <span style={{ padding: 0 }}>
                                        <span style={{ fontWeight: productDetails.pressure_drop === 'clean' ? 'bold' : 'normal', padding: 0 }}>
                                            {removeLeadingZeros(selectedCandidate.pressureDrop.clean?.toFixed(2) ?? 'n/a')}
                                        </span>
                                        {' / '}
                                        <span style={{ fontWeight: productDetails.pressure_drop === 'mean' ? 'bold' : 'normal', padding: 0 }}>
                                            {removeLeadingZeros(selectedCandidate.pressureDrop.mean?.toFixed(2) ?? 'n/a')}
                                        </span>
                                        {' / '}
                                        <span style={{ fontWeight: productDetails.pressure_drop === 'dirty' ? 'bold' : 'normal', padding: 0 }}>
                                            {selectedCandidate.pressureDrop.dirty?.toFixed(2) ?? 'n/a'}
                                        </span>
                                    </span>} symbol="inWg" />
                                {productDetails.type === "Combo" && <StatsRow label={"Final-filter Media"} value={`${productDetails.final_filter_depth}" ${productDetails.final_filter_rating.replace(/_/g, ' ')}`} symbol="" />}
                                {productDetails.type === "Combo" && <StatsRow label={"Final-filter APD"} value={
                                    <span style={{ padding: 0 }}>
                                        <span style={{ fontWeight: productDetails.pressure_drop === 'clean' ? 'bold' : 'normal', padding: 0 }}>
                                            {removeLeadingZeros(selectedCandidate.pressureDrop.final_clean?.toFixed(2) ?? 'n/a')}
                                        </span>
                                        {' / '}
                                        <span style={{ fontWeight: productDetails.pressure_drop === 'mean' ? 'bold' : 'normal', padding: 0 }}>
                                            {removeLeadingZeros(selectedCandidate.pressureDrop.final_mean?.toFixed(2) ?? 'n/a')}
                                        </span>
                                        {' / '}
                                        <span style={{ fontWeight: productDetails.pressure_drop === 'dirty' ? 'bold' : 'normal', padding: 0 }}>
                                            {selectedCandidate.pressureDrop.final_dirty?.toFixed(2) ?? 'n/a'}
                                        </span>
                                    </span>} symbol="inWg" />}
                                <StatsRow label="Sizing (Count)" value={selectedCandidate.filters?.map((filter: any, index: number) => (
                                    <span key={index} style={{ paddingLeft: index === 0 ? '0px' : '10px' }}>
                                        {filter.width} x {filter.height} ({filter.count})
                                        {index < selectedCandidate.filters.length - 1 && ', '}
                                        <br />
                                    </span>
                                ))} height={`${selectedCandidate.filters?.length > 1 ? (selectedCandidate.filters?.length * 24) : '32'}px`} />
                                <StatsRow label={<span style={{ paddingLeft: '0px' }}>Cost <CostBreakdownTooltip unitCost={selectedCandidate.priceDetails} type="filter" /></span>} value={selectedCandidate.totalPrice.toFixed(0)} symbol="$" />
                            </div>
                        </Card>
                    </div>
                }
            </div>
        </div>)
}

function removeLeadingZeros(str) {
    return str.replace(/^0(\.)/, '$1');
}

const StatsRow = ({
    label,
    value,
    symbol,
    height = "auto"
}: { label: any, value: any, symbol?: string, height?: string }) => (
    <div className="stats-row-container" >
        <div className="stats-row-label" >
            <span style={{ height }}>{label}</span>
        </div>
        <div style={{ minWidth: '150px' }}>
            {symbol ? (
                <span >
                    {value}
                    <span style={{ fontSize: '12px', paddingLeft: '3px' }}>{symbol}</span>
                </span>
            ) : (
                <span >
                    {value}
                </span>
            )}
        </div>
    </div>
);