import React, { useState } from 'react';
import { Button, ModalBody, ModalFooter, Input} from 'reactstrap';
import { Product_Insert_Input, Quote, useUpdateManyProductsMutation, useUpdateQuoteMutation } from '../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { quoteSumTotal } from '../helpers/quote';
import parseMoney, { formatMoney } from '../helpers/money-utils';

// TODO: Do we want to show a loading indicator and close after success?
const UpdateQuoteSettings = ({
  quote,
  setIsOpen,
  refetch,
  costs,
  sizings
}: {
  quote: Quote,
  setIsOpen: Function,
  refetch: Function,
  costs: any,
  sizings: any,
}) => {
  const [name, setName] = useState<string>(quote.name);
  const [loading, setLoading] = useState(false);
  const [updateQuote] = useUpdateQuoteMutation();
  const isValid = name !== undefined && (name.length > 0 && name.length < 255);

  const [updateManyProducts] = useUpdateManyProductsMutation();

  const allProductsSelected = (quote.products.find(e => e.configuration === null) === undefined);

  const currentPrice = parseMoney(quoteSumTotal(quote.products, sizings?.control_panel_sizing, costs?.cost));
  const allECPlusControlsPrice = parseMoney(quoteSumTotal(quote.products, sizings?.control_panel_sizing, costs?.cost, "all ec plus"));
  const allBasicControlsPrice = parseMoney(quoteSumTotal(quote.products, sizings?.control_panel_sizing, costs?.cost, "all basic"));
  const allLargeScalePrice = parseMoney(quoteSumTotal(quote.products, sizings?.control_panel_sizing, costs?.cost, "large scale pricing"));
  const allNormalScalePrice = parseMoney(quoteSumTotal(quote.products, sizings?.control_panel_sizing, costs?.cost, "normal scale pricing"));

  let ecPlusControlsDiff = allECPlusControlsPrice - currentPrice;
  let ecPlusControlsDiffSign = "+";
  if (ecPlusControlsDiff === 0) ecPlusControlsDiffSign = "±";
  else if (ecPlusControlsDiff < 0){
    ecPlusControlsDiffSign = "-";
    ecPlusControlsDiff *= -1;
  } 
  let allBasicControlsPriceDiff = allBasicControlsPrice - currentPrice;
  let allBasicControlsPriceDiffSign = "+";
  if (allBasicControlsPriceDiff === 0) allBasicControlsPriceDiffSign = "±";
  else if (allBasicControlsPriceDiff < 0){
    allBasicControlsPriceDiffSign = "-";
    allBasicControlsPriceDiff *= -1;
  } 
  let allLargeScalePriceDiff = allLargeScalePrice - currentPrice;
  let allLargeScalePriceDiffSign = "+";
  if (allLargeScalePriceDiff === 0) allLargeScalePriceDiffSign = "±";
  else if (allLargeScalePriceDiff < 0){
    allLargeScalePriceDiffSign = "-";
    allLargeScalePriceDiff *= -1;
  } 
  let allNormalScalePriceDiff = allNormalScalePrice - currentPrice;
  let allNormalScalePriceDiffSign = "+";
  if (allNormalScalePriceDiff === 0) allNormalScalePriceDiffSign = "±";
  else if (allNormalScalePriceDiff < 0){
    allNormalScalePriceDiffSign = "-";
    allNormalScalePriceDiff *= -1;
  } 

  const onInput = (_: string, value: string) => {
    setName(value)
  };

  const save = () => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    updateQuote({
      variables: {
        pk_columns: { id: quote.id },
        _set: { name },
      },
      onCompleted: () => {
        refetch();
        setLoading(false);
        setIsOpen(false);
      },
      onError: () => {
        setLoading(false);
      }
    });
  };

  const updateAll = async (action: string) => {
    const products = quote.products.map((p) => {
      return {
        BACnet: p.BACnet,
        air_flow: p.air_flow,
        air_flow_orientation: p.air_flow_orientation,
        altitude: p.altitude,
        backdraft_dampers: p.backdraft_dampers,
        configuration_id: p.configuration_id,
        configuration_quantity: p.configuration_quantity,
        configured_fan_count: p.configured_fan_count,
        discounted_fan_price: p.discounted_fan_price,
        fan_layout: p.fan_layout,
        height: p.height,
        id: p.id,
        job_id: p.job_id,
        large_scale_pricing: p.large_scale_pricing,
        local_HMI: p.local_HMI,
        mas_ec_plus: p.mas_ec_plus,
        model_number: p.model_number,
        outdoor_rating: p.outdoor_rating,
        over_one_hundred_percent_redundancy: p.over_one_hundred_percent_redundancy,
        power: p.power,
        pressure_transducers: p.pressure_transducers,
        quote_id: p.quote_id,
        set_voltage: p.set_voltage,
        tag: p.tag,
        total_static_pressure: p.total_static_pressure,
        voltage_override: p.voltage_override,
        width: p.width,
        } as Product_Insert_Input;
    });
    
    if (action === "LargeScale") products.forEach((p) => {p.large_scale_pricing = true});
    else if (action === "NormalScale") products.forEach((p) => {p.large_scale_pricing = false});
    else if (action ==="ECPlus") products.forEach((p) => {p.mas_ec_plus = true});
    else if (action ==="Basic") products.forEach((p) => {p.mas_ec_plus = false});
    else return;
    
    setLoading(true);
    await updateManyProducts({
      variables: {
        objects: products
      },
      onCompleted: (data) => {
        refetch();
        setLoading(false);
        setIsOpen(false);

        console.log(`Successfully updated ${data.insert_product.affected_rows} products`);
      },
      onError: (error) => {
        setLoading(false);
        console.log("Failed to updated products:",error);
      }
    });
  }

  return (
    <div>
      <form onSubmit={(e) => {
        e.preventDefault();
      }}>
        <div className="modal-header">
          <h3>Update quote settings</h3>
          <FontAwesomeIcon
            style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
            onClick={() => { if (!loading) setIsOpen(false); }}
            icon={faXmarkCircle} />
        </div>
        <ModalBody>
          <div className="d-flex flex-column mb-3">
            <label>Name</label>
            <Input
              autoFocus
              name="name"
              min={1}
              max={999}
              value={name}
              onInput={(e) => onInput((e.target as any).name, (e.target as any).value)}
            />
          </div>
          {allProductsSelected && <>
            <hr/>
            <div>Update all arrays in quote</div>
            <div className="modal-button-holder">
              <div className="modal-button-row">
                <Button className="modal-row-button" onClick={() => updateAll("ECPlus")}>Update to EC+</Button>
                <div className="modal-row-number">{`(${ecPlusControlsDiffSign}${formatMoney(ecPlusControlsDiff)})`}</div>
              </div>
              <div className="modal-button-row">
                <Button className="modal-row-button" onClick={() => updateAll("Basic")}>Update to basic controls</Button>
                <div className="modal-row-number">{`(${allBasicControlsPriceDiffSign}${formatMoney(allBasicControlsPriceDiff)})`}</div>
              </div>
              <div className="modal-button-row">
                <Button className="modal-row-button" onClick={() => updateAll("LargeScale")}>Enable quantity discount</Button>
                <div className="modal-row-number">{`(${allLargeScalePriceDiffSign}${formatMoney(allLargeScalePriceDiff)})`}</div>
              </div>
              <div className="modal-button-row">
                <Button className="modal-row-button" onClick={() => updateAll("NormalScale")}>Disable quantity discount</Button>
                <div className="modal-row-number">{`(${allNormalScalePriceDiffSign}${formatMoney(allNormalScalePriceDiff)})`}</div>
              </div>
            </div>
          </>}
        </ModalBody>
        <ModalFooter className="justify-content-start mx-2">
          <Button type='submit' disabled={loading || !isValid} onClick={save}>Update Quote</Button>
        </ModalFooter>
      </form>
    </div>
  );
};

export default UpdateQuoteSettings;