import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKeycloak } from "@react-keycloak/web";
import "./Header.css"
import { ReactComponent as MasLogo } from "../assets/logo.svg"
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

type HeaderProps = {
  appSectionChange?: (section: string) => void;
  appSection?: string;
};

const Header = ({appSectionChange, appSection}: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectionsOpen, setIsSelectionsOpen] = useState(false); 
  const { keycloak } = useKeycloak();
  const [isJobsOpen, setIsJobsOpen] = useState(true);

  const navigate = useNavigate();
  const toggle = () => setIsOpen((prevState) => !prevState);

  const routes = [
    'Profile',
    'Users',
    'Jobs',
    'Upload Configurations',
    'Upload Model Files'
  ];

  const toggleSelections = () => setIsSelectionsOpen((prevState) => !prevState);

  const selections = [
    'MAS EC Selections',
    'Filters',
    'Coils'
  ];

  const currentPathname = window.location.pathname;
  const dropdownItems = routes.map(r => {
    const route = `/${r.toLowerCase().split(' ').join('-')}`;
    const active = route === currentPathname;

    return (
      <DropdownItem
        key={r}
        active={active}
        onClick={() => navigate(route)}
      >
        {r}
      </DropdownItem>
    );
  });

  const dropdownSelectionItems = selections.map(s => {
    const active = s === appSection;

    return (
      <DropdownItem
        key={s}
        active={active}
        onClick={() => handleAppSectionChange(s)}
      >
        {s}
      </DropdownItem>
    );
  });

  const handleAppSectionChange = (s: string) => {
    appSectionChange(s);
    if(s === "Filters") {
      setIsJobsOpen(false) 
      navigate('/tools/filters');
    } else if(s === "Coils") {
      setIsJobsOpen(false) 
      navigate('/tools/coils');
    } else {
      setIsJobsOpen(true)
      navigate('/jobs');
    }
  }

  useEffect(() => {
    if (currentPathname === '/tools/filters') {
        appSectionChange('Filters');
        setIsJobsOpen(false);
    } else if (currentPathname === '/tools/coils') {
      appSectionChange('Coils');
      setIsJobsOpen(false);
    }
}, [appSectionChange, currentPathname]); 

  const getToolsPath = () => {
    switch (appSection) {
      case 'Filters':
        return '/tools/filters';
      case 'Coils':
        return '/tools/coils';
      default:
        return '/tools';
    }
  };

  const isNewUIEnabled = process.env.NODE_ENV === 'development';

  return !keycloak.authenticated
    ? <></>
    : (<div className="header">
      <div className='flex selector'>
        <a href="/jobs">
          <div className='logo'>
            <MasLogo />
          </div>
        </a>
        <div >
        {isNewUIEnabled ? (
          <Dropdown isOpen={isSelectionsOpen} toggle={toggleSelections}>
              <DropdownToggle className="app-selector" caret >
                <span className="title">
                  <h1>Leo</h1>
                <h3>{appSection}</h3>
              </span>
            </DropdownToggle>
            <DropdownMenu>
              {dropdownSelectionItems}
            </DropdownMenu>
          </Dropdown>
        ) : (
          <div className="app-selector">
            <span className="title">
              <h1>Leo</h1>
              <h3>MAS EC Selections</h3>
            </span>
            <span className="down-arrow">
              <FontAwesomeIcon style={{ fontSize: 25 }} icon={faCaretDown} />
            </span>
          </div>
        )}
        </div>
        <Collapse isOpen={isJobsOpen} horizontal>
          <Link to="/jobs">Jobs</Link>
        </Collapse>
        <Link to={getToolsPath()}>Tools</Link>
      </div>
      <div className="flex">
        <span className="user-name">
          {(keycloak.userInfo as any)?.name}
        </span>
        <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
          <DropdownToggle>
            <FontAwesomeIcon icon="bars" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem key='logout' onClick={() => keycloak.logout()}>Logout</DropdownItem>
            {dropdownItems}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>);
};

export default Header;