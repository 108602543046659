import { Link } from "react-router-dom";
import "./Tools.css"
import Flats from "./Flats";
import Calculator from "./Calculator";
import Search from "./Search";
import Filters from "./Filters";
import Coils from "./Coils";

export function ToolsPage({ route, appSection }: { route?: string, appSection?: string }) {

    return (<div className="page tools">
        <div className="tools-sidebar">
            {   appSection === "MAS EC Selections" &&
                <>
                <Link to="/tools/calculator" className={route === "calculator" ? "active" : ""}>Calculator</Link>
                <Link to="/tools/flats" className={route === "flats" ? "active" : ""}>Flats</Link>
                <Link to="/tools/search" className={route === "search" ? "active" : ""}>Advanced Search</Link>
                </>
            }
            {   appSection === "Filters" &&
                <>
                <Link to="/tools/filters" className={route === "filters" ? "active" : ""}>Filters</Link>
                </>
            }
            {   appSection === "Coils" &&
                <>
                <Link to="/tools/coils" className={route === "coils" ? "active" : ""}>Coils</Link>
                </>
            }
        </div>
        {route === "search" &&
            <Search />
        }
        {route === "flats" &&
            <Flats />
        }
        {route === "calculator" &&
            <Calculator />
        }
        {route === "filters" &&
            <Filters />
        }
        {route === "coils" &&
            <Coils />
        }
    </div >)
}