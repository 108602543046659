import React, { CSSProperties } from 'react';
import filters from "./filters.json"
import { FilterProductDetails } from '../pages/Filters';
import { FilterDescription } from "@mashvac/daedex";

export const calculatePressureDrop = (productDetails: FilterProductDetails, fpm: number, filterDescriptions: FilterDescription[]) => {    
    const { a, b, c, d } = filterDescriptions.find(f => f.rating === productDetails.pre_filter_rating && f.depth === productDetails.pre_filter_depth).pressureDropPolynomial;
    const dirty: number = filterDescriptions.find(f => f.rating === productDetails.pre_filter_rating && f.depth === productDetails.pre_filter_depth).dirtyPressureDrop;
    const clean: number = (a * Math.pow(fpm, 3)) + b * Math.pow(fpm, 2) + (c * fpm) + d;

    let result: any = {
        clean,
        mean: (clean + dirty) / 2,
        dirty
    };

    if (productDetails.type === "Combo") {
        const { a, b, c, d } = filterDescriptions.find(f => f.rating === productDetails.final_filter_rating && f.depth === productDetails.final_filter_depth).pressureDropPolynomial;
        const final_dirty: number = filterDescriptions.find(f => f.rating === productDetails.final_filter_rating && f.depth === productDetails.final_filter_depth).dirtyPressureDrop;
        const final_clean: number = (a * Math.pow(fpm, 3)) + b * Math.pow(fpm, 2) + (c * fpm) + d;
        result.final_clean = final_clean;
        result.final_mean = (final_clean + final_dirty) / 2;
        result.final_dirty = final_dirty;
    }
    return result;
}

export const calculateUtilization = (filterArea: number, unitWidth: number, unitHeight: number): number => {
    let utilization: number = (filterArea * 144) / (unitWidth * unitHeight) * 100;
    return utilization;
}

// A filters JSON is being used in the cost calculations to reference price info. Once we have place for filter/frames price info to live, the json can be deleted
function calculateMediaCosts(sizing: any, rating: string, depth: number) {
    let mediaCostTotal: number = 0;
    let countTotal: number = 0;
    let framesCostTotal: number = 0;

    sizing.forEach(size => {
        const mediaCostPerFilter: number = filters.find(f => f.rating === rating && f.depth === depth).cost[size.height + 'x' + size.width];
        const frameCostPerFilter: number = filters.find(f => f.rating === rating && f.depth === depth).cost['frame'];

        let mediaCostTotalPerSize: number = mediaCostPerFilter * size.count;
        countTotal += size.count;
        mediaCostTotal += mediaCostTotalPerSize;
        framesCostTotal += frameCostPerFilter * size.count;
    });
    return { mediaCostTotal, countTotal, framesCostTotal };
}

export const calculateFilterLayoutPricing = (productDetails: FilterProductDetails, candidate: any, unitWidth: number) => {
    const sm: number = unitWidth >= 120 ? 300 : 150;
    const misc: number = unitWidth >= 120 ? 300 : 150;
    const filterRows: number = new Set(candidate.rects.map(item => item.y)).size;
    let finalFilter: any = { mediaCostTotal: 0, countTotal: 0, framesCostTotal: 0 };
    let totalPrice: number = 0;

    let { mediaCostTotal, countTotal, framesCostTotal } = calculateMediaCosts(candidate.filters, productDetails.pre_filter_rating, productDetails.pre_filter_depth);

    if (productDetails.type === "Combo") {
        finalFilter = calculateMediaCosts(candidate.filters, productDetails.final_filter_rating, productDetails.final_filter_depth);
        mediaCostTotal += finalFilter.mediaCostTotal;
        countTotal += finalFilter.countTotal;
        framesCostTotal += finalFilter.framesCostTotal;
    }

    const freight: number = (mediaCostTotal + (productDetails.loading === "Front" ? framesCostTotal : 0)) * 0.3;
    let perimeterBlankOffs: number = (filterRows / (productDetails.type === "Panel" ? 2 : 1)) * sm;
    if (productDetails.loading === "Front") { perimeterBlankOffs = 0; }
    if (productDetails.loading === "Side") { framesCostTotal = 0; }
    const additionalSheets: number = (productDetails.type === "Panel" ? 2 : 4) * sm;


    if (productDetails.loading === 'Side' && productDetails.type === "Panel") {
        totalPrice = mediaCostTotal + freight + perimeterBlankOffs + additionalSheets + misc; 
    } else if (productDetails.loading === "Front" && productDetails.type === "Panel") {
        totalPrice = mediaCostTotal + framesCostTotal + freight + additionalSheets + misc; 
    } else if (productDetails.loading === "Side" && productDetails.type === "Combo") {
        totalPrice = mediaCostTotal + freight + perimeterBlankOffs + additionalSheets + misc; 
    } else if (productDetails.loading === "Front" && productDetails.type === "Combo") {
        totalPrice = mediaCostTotal + framesCostTotal + freight + additionalSheets + misc; 
    }

    let priceDetails = {
        mediaCostTotal,
        framesCostTotal,
        countTotal,
        finalFilter,
        freight,
        perimeterBlankOffs,
        additionalSheets,
        misc,
        totalPrice
    };
    return { totalPrice, priceDetails };
};

interface FilterLayoutProps {
    filters: Array<{
        width: number;
        height: number;
        x: number;
        y: number;
    }>;
    unitWidth: number;
    unitHeight: number;
}
 
export const FilterLayout = ({ filters, unitWidth, unitHeight }: FilterLayoutProps): JSX.Element => {
    const maxWidth: number = 285;
    const maxHeight: number = 285;
    const scaleWidth: number = maxWidth / unitWidth;
    const scaleHeight: number = maxHeight / unitHeight;
    const scale: number = Math.min(scaleWidth, scaleHeight);
    // console.log('scale', scale, 'scaleWidth', scaleWidth, 'scaleHeight', scaleHeight);
    
    const layoutContainer: CSSProperties = {
        position: 'relative',
        width: `${unitWidth * scale}px`,
        height: `${unitHeight * scale}px`,
        border: '1px solid black',
        fontSize: '12px',
        textAlign: 'center',
    };

    const getColor = (width: number, height: number): string => {
        if (width === 24 && height === 24) {
            return 'black';
        } else if ((width === 20 && height === 24) || (width === 24 && height === 20)) {
            return '#1890cb';
        } else if (width === 20 && height === 20) {
            return 'green';
        } else if ((width === 12 && height === 24) || (width === 24 && height === 12)) {
            return 'purple';
        } else {
            return 'black';
        }
    };

    // At larger unit dimensions the filter size appear to small to render the dimensions within the div/filter,
    // so the following 3 functions set up a key to conditionally display dimensions below the layout
    const dimensionsSet = new Set();
    filters.forEach(filter => {
        dimensionsSet.add(`${filter.width} x ${filter.height}`);
    });

    const keyContainer = {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '10px'
    };

    const colorBox = (color: string) => ({
        width: '12px',
        height: '12px',
        backgroundColor: color,
        display: 'inline-block'
    });

    return (
        <div>
            <div style={layoutContainer}>
                {filters.map((filter: any, index: number) => (
                    <div key={index} style={{
                        position: 'absolute',
                        left: `${filter.x * scale}px`,
                        bottom: `${filter.y * scale}px`,
                        width: `${filter.width * scale}px`,
                        height: `${filter.height * scale}px`,
                        border: `1px solid ${getColor(filter.width, filter.height)}`,
                        boxSizing: 'border-box'
                    }}>
                        {scale >= 1.5 ? `${filter.width} x ${filter.height}` : ``}
                    </div>
                ))}
            </div>
            {scale < 1.5 && (
                <div style={keyContainer}>
                    {dimensionsSet.has("24 x 24") && <div><div style={colorBox('black')}></div> 24 x 24</div>}
                    {(dimensionsSet.has("20 x 24") || dimensionsSet.has("24 x 20")) && <div><div style={colorBox('#1890cb')}></div> 20 x 24</div>}
                    {dimensionsSet.has("20 x 20") && <div><div style={colorBox('green')}></div> 20 x 20</div>}
                    {(dimensionsSet.has("12 x 24") || dimensionsSet.has("24 x 12")) && <div><div style={colorBox('purple')}></div> 12 x 24</div>}
                </div>
            )}
        </div>
    );
};