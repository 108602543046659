import { ChangeEvent, useState } from "react";
import { Card, CardBody, Col, Button, FormFeedback, Input, InputGroup, InputGroupText, Modal,  ModalBody,  ModalFooter,  Spinner, UncontrolledTooltip } from "reactstrap";
import { faFileWord, faCircleExclamation, faFileDownload, faXmarkCircle, faUpload, faInfoCircle, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateCoilQuote } from "../helpers/generate-pdf";
import generateDaedexDocx from "../helpers/generate-daedex-docx";
import generateDaikinDocx from "../helpers/generate-daikin-docx";
import { Client } from "@mashvac/mom";

export default function Coils() {
    const [performanceResults, setPerformanceResults] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [checkboxMBH, setCheckboxMBH] = useState<boolean>(false);
    const [checkboxGPM, setCheckboxGPM] = useState<boolean>(false);
    const [coilsPerBank, setCoilsPerBank] = useState<number>(1);
    const [circuitingType, setCircuitingType] = useState<string>('Auto');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [jsonString, setJsonString] = useState<string>('');
    const [jsonData, setJsonData] = useState<any>({});
    const [productDetails, setProductDetails] = useState<any>({
        air_flow: 15000,
        altitude: 0,
        entering_air_dry_bulb: 80,
        entering_air_wet_bulb: 67,
        leaving_air_dry_bulb: 55,
        leaving_air_wet_bulb: 54,
        mbh: 0,
        fluid: 'Water',
        glycol_mass_percent: 0,
        entering_fluid_temp: 45,
        desired_leaving_fluid_temp: 55,
        gallons_per_minute: 0,
        max_fluid_pressure_drop: 20,
        // tag: 'test coil',
        coils_per_bank: 1,
        tube_diameter: 0.625,
        tube_face_spacing: 1.5,
        tube_row_spacing: 1.299,
        fin_id: 'C',
        rows: 8,
        fpi: 10,
        fin_height: 48,
        fin_length: 96,
        circuiting_type: 'Auto',
        nfeeds: 0,
        fin_material: 'aluminum',
        fin_thickness: 0.0075,
        tube_material: 'copper',
        tube_thickness: 0.020,
        connection_size: 1.25,
        number_of_connections: 2,
        total_coils: 1,
        coil_hand: 'RightHand',
        casing_material: 'galvanized',
        casing_gauge: 16,
        casing_type: 'Flanged',
        connection_material: 'Copper',
        connection_type: 'mpt',
        header_material: 'copper',
        coil_coating: 'None',
        door_location: 'Both sides',
        door_opening: "Outward",
        door_width_drive_side: 10,
        door_width_opposite_drive_side: 10,
        drain_pan_material: 'Stainless Steel',
        drain_pan_connection_side: 'Drive Side',
        intermediate_drain_pan: false,
        turbulators: false
    })

    // Some of the performance values needed are only accessible from the CoilString property
    // So we need to extract them below
    const processCoilResponse = (response: any[]) => {
        response.forEach((item) => {
            if (item.CoilString) {
                try {
                    const internalVolume1Regex = /"InternalVolume1":(\d+\.\d+)/;
                    const operatingWeightRegex = /"OperatingWeight":(\d+\.\d+)/;
                    const tubeWallThicknessRegex = /"TubeWallThickness":(\d+\.\d+)/;
                    const npassRegex = /\/ (\d+) Pass"\]/;

                    const internalVolume1Match = item.CoilString.match(internalVolume1Regex);
                    const operatingWeightMatch = item.CoilString.match(operatingWeightRegex);
                    const tubeWallThicknessMatch = item.CoilString.match(tubeWallThicknessRegex);
                    const npassMatch = item.CoilString.match(npassRegex);

                    item.InternalVolume1 = internalVolume1Match ? Number(internalVolume1Match[1]) : null;
                    item.OperatingWeight = operatingWeightMatch ? Number(operatingWeightMatch[1]) : null;
                    item.TubeWallThickness = tubeWallThicknessMatch ? Number(tubeWallThicknessMatch[1]) : null;
                    item.Npass = npassMatch ? parseInt(npassMatch[1], 10) : null;

                } catch (error) {
                    console.error("Error extracting values:", error);
                }
            }
        });
        return response;
    }

    const getWaterCoilPerformance = async () => {
        const client = new Client();
        // const clientWithKey = new Client({
        //     accessKey: masKey
        // });
        let data: any;

        const cwcInput = {
            "ACFM": 0, // no UI input
            "Altitude": productDetails.altitude,
            "CalculatePrice": true, // no UI input
            "CasingGauge": productDetails.casing_gauge,
            "CasingMaterial": productDetails.casing_material,
            "CasingType": productDetails.casing_type,
            "CircuitingType": productDetails.nfeeds !== 0 ? 'Auto' : productDetails.circuiting_type,
            "Coating": productDetails.coil_coating,
            "CoilHand": productDetails.coil_hand,
            "CoilsPerBank": productDetails.coils_per_bank,
            "ConnectionMaterial": productDetails.connection_material,
            "ConnectionType": productDetails.connection_type,
            "DesiredCapacity": productDetails.mbh * 1000,
            "DesiredLeavingAirDryBulb": productDetails.leaving_air_dry_bulb,
            "DesiredLeavingAirWetBulb": productDetails.leaving_air_wet_bulb,
            "DesiredLeavingFluidTemp": productDetails.desired_leaving_fluid_temp,
            "EnteringAirDryBulb": productDetails.entering_air_dry_bulb,
            "EnteringAirWetBulb": productDetails.entering_air_wet_bulb,
            "EnteringFluidTemp": productDetails.entering_fluid_temp,
            "FH": productDetails.fin_height,
            "FL": productDetails.fin_length,
            "FinID": productDetails.fin_id,
            "FinMaterial": productDetails.fin_material,
            "FinThickness": productDetails.fin_thickness,
            "Fluid": productDetails.fluid,
            "FluidsideFoulingFactor": 0, // no UI input
            "Fpi": productDetails.fpi,
            "FpiMax": 14, // no UI input
            "GPM": productDetails.gallons_per_minute,
            "GlycolMassPercent": productDetails.glycol_mass_percent, 
            "IntermediateDrainPan": productDetails.intermediate_drain_pan,
            "MaximumFluidPressureDrop": productDetails.max_fluid_pressure_drop,
            "Nfeeds": productDetails.nfeeds,
            "Nrows": productDetails.rows,
            "NumberOfConnections": productDetails.number_of_connections,
            "OppositeEndConnections": false, // no UI input
            "Qty": productDetails.total_coils,
            "ReturnConnectionSize": productDetails.connection_size,
            "ReturnHeaderDiameter": productDetails.connection_size,
            "SCFM": productDetails.air_flow,
            "StackingFlange": true, // no UI input, always needs to be true
            "SupplyConnectionSize": productDetails.connection_size,
            "SupplyHeaderDiameter": productDetails.connection_size,
            "TubeMaterial": productDetails.tube_material,
            "TubeWallThickness": productDetails.tube_thickness,
            "Turbulators": productDetails.turbulators
        };
        setCoilsPerBank(cwcInput.CoilsPerBank);
        setCircuitingType(productDetails.circuiting_type);
        // console.log('cwcInput: ', cwcInput); 

        try {
            const response = await client.calculateWaterCoilPerformance(cwcInput);
            data = processCoilResponse([response.coil.Outputs]); 
        } catch (e) {
            console.error(e);
            setError(e.Error);
        } finally {
            setPerformanceResults(data);
        }
        setLoading(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else if (
            e.target.type === "number" || 
            e.target.name === "rows" ||
            e.target.name === "fpi" ||
            e.target.name === "connection_size" ||
            e.target.name === "number_of_connections" ||
            e.target.name === "tube_thickness" ||
            e.target.name === "casing_gauge"
        ) {
            try {
                value = parseFloat(e.target.value);
            } catch (e) {
                console.error("Parse error: ", e);
                return;
            }
        } else {
            value = e.target.value;
        }

        const newState = { ...productDetails, [e.target.name]: value };
        // console.log('newState: ', e.target.name, e.target.value, newState); 

        if (e.target.name === "coils_per_bank") {
            newState.total_coils = parseInt(value);
        } else if (e.target.name === "mbh") {
            newState.leaving_air_dry_bulb = 0;
            newState.leaving_air_wet_bulb = 0;
        } else if (e.target.name === "gallons_per_minute") {
            newState.desired_leaving_fluid_temp = 0;
        } else if (e.target.name === "desired_leaving_fluid_temp") {
            newState.gallons_per_minute = 0;
        } else if (e.target.name === "circuiting_type") {
            if (Number.isFinite(Number(value))) {
                newState.nfeeds = parseInt(value);
            }
        } else if (e.target.name === "fin_thickness_material") {
            const [finMaterial, finThickness] = value.split("-");
            newState.fin_material = finMaterial;
            newState.fin_thickness = parseFloat(finThickness);
        } else if (e.target.name === "fin_height") {
            if (parseFloat(e.target.value) >= 55) {
                newState.coils_per_bank = 2;
                newState.total_coils = 2;
            }
        } else if (e.target.name === "drain_pan_material_connection_side") {
            const [drainPanMaterial, drainPanConnectionSide] = value.split("-");
            newState.drain_pan_material = drainPanMaterial;
            newState.drain_pan_connection_side = drainPanConnectionSide;
        } else if (e.target.name === "fin_id"){
            switch (e.target.value) {
                case "C":
                case "D":
                case "E":
                    newState.tube_thickness = 0.020;
                    newState.tube_diameter = 0.625;
                    newState.tube_face_spacing = 1.5;
                    newState.tube_row_spacing = 1.299;
                    break;
                case "F":
                case "G":
                case "H":
                    newState.tube_thickness = 0.016;
                    newState.fin_height = 50;
                    newState.tube_diameter = 0.5;
                    newState.tube_face_spacing = 1.25;
                    newState.tube_row_spacing = 1.08;
                    break;
                case "J":
                case "K":
                    newState.tube_thickness = 0.014;
                    newState.tube_diameter = 0.375;
                    newState.tube_face_spacing = 1;
                    newState.tube_row_spacing = 0.866;
                    break;
                case "L":
                    newState.tube_thickness = 0.014;
                    newState.tube_diameter = 0.375;
                    newState.tube_face_spacing = 1.25;
                    newState.tube_row_spacing = 1.08;
                    break;
                default:
                    break;
            }
        } else if (e.target.name === "fluid" && e.target.value === "Water") {
            newState.glycol_mass_percent = 0;
        }
        setProductDetails(newState);
    }
    // console.log('productDetails: ', productDetails);

    const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {   
            setLoading(true);
            setError(null);
            setPerformanceResults([]);
            getWaterCoilPerformance();
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleJson = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data: { [key: string]: any } = {};
        setJsonString(e.target.value);

        const keyNames = ["Altitude", "CasingGauge", "CasingMaterial", "CasingType1", "CircuitingType", "CoilCoating", "CoilHand", "NSlabs", "ConnectionMaterial", "ConnectionType", "DesiredCapacity", "DesiredLeavingAirDryBulb", "DesiredLeavingAirWetBulb", "DesiredLeavingFluidTemp", "EnteringAirDryBulb", "EnteringAirWetBulb", "EnteringFluidTemp", "FH", "FL", "FinID", "FinMaterial", "FinThickness", "Fluid", "Fpi", "GPM", "GlycolMassPercent", "IsIntermediateDrainPan", "MaximumFluidPressureDrop", "Nfeeds", "Nrows", "NumberOfConnections", "Qty", "SCFM", "isStackingFlange", "SupplyConnectionsSize", "TubeMaterial", "TubeWallThickness", "Turbulators"];
    
        try {
            const jsonData = JSON.parse(e.target.value);
            // console.log('jsonData: ',  jsonData); 
            keyNames.forEach(key => {
                if (jsonData.Inputs && jsonData.Inputs.hasOwnProperty(key)) {
                    data[key] = jsonData.Inputs[key];
                } else if (jsonData.Geometry && jsonData.Geometry.hasOwnProperty(key)) {
                    data[key] = jsonData.Geometry[key];
                }
            });
            setJsonData(data);
            setIsValid(true);
        } catch (error) {
            console.error("Invalid JSON input", error);
            setIsValid(false);
        } 
    }

    const importJson = (data: { [key: string]: any }) => {
        const casingMaterialMapping = { 0: 'galvanized', 1: 'aluminum', 2: 'stainless304', 3: 'stainless316' };
        const connectionMaterialMapping = { 0: 'Copper', 1: 'Steel', 3: 'RedBrass' };
        const connectionTypeMapping = { 0: 'mpt', 1: 'fpt', 2: 'swt', 3: 'grooved', 4: 'none' };
        const coilCoatingMapping = { 0: 'None', 1: 'ElectroFin', 2: 'ElectroFinUV', 3: 'Heresite', 4: 'BlygoldAntiCorrosive', 5: 'BlygoldAntiMicrobial', 8: 'BlackPolyCoatedFin', 9: 'Finkote2', 10: 'Finkote2UV', 11: 'FinkoteCc', 12: 'FinkoteHp', 13: 'FinkoteZx' };
        const fluidMapping = { 0: 'Water', 1: 'PropyleneGlycol', 2: 'EthyleneGlycol' };
        const finIdMapping = {
            0: { fin_id: 'C', tube_diameter: 0.625, tube_face_spacing: 1.5, tube_row_spacing: 1.299 },
            1: { fin_id: 'D', tube_diameter: 0.625, tube_face_spacing: 1.5, tube_row_spacing: 1.299 },
            2: { fin_id: 'E', tube_diameter: 0.625, tube_face_spacing: 1.5, tube_row_spacing: 1.299 },
            3: { fin_id: 'F', tube_diameter: 0.5, tube_face_spacing: 1.25, tube_row_spacing: 1.08 },
            4: { fin_id: 'G', tube_diameter: 0.5, tube_face_spacing: 1.25, tube_row_spacing: 1.08 },
            5: { fin_id: 'H', tube_diameter: 0.5, tube_face_spacing: 1.25, tube_row_spacing: 1.08 },
            7: { fin_id: 'J', tube_diameter: 0.375, tube_face_spacing: 1, tube_row_spacing: 0.866 },
            8: { fin_id: 'K', tube_diameter: 0.375, tube_face_spacing: 1, tube_row_spacing: 0.866 },
            9: { fin_id: 'L', tube_diameter: 0.375, tube_face_spacing: 1.25, tube_row_spacing: 1.08 }
        };
        const finDetails = finIdMapping[data.FinID] || finIdMapping[0];

        setProductDetails({
            ...productDetails, 
            air_flow: data.SCFM,
            altitude: data.Altitude,
            entering_air_dry_bulb: data.EnteringAirDryBulb,
            entering_air_wet_bulb: data.EnteringAirWetBulb,
            leaving_air_dry_bulb: data.DesiredLeavingAirDryBulb,
            leaving_air_wet_bulb: data.DesiredLeavingAirWetBulb,
            mbh: (data.DesiredCapacity / 1000).toFixed(2),
            fluid: fluidMapping[data.Fluid] || 'Water',
            glycol_mass_percent: data.GlycolMassPercent,
            entering_fluid_temp: data.EnteringFluidTemp,
            desired_leaving_fluid_temp: data.DesiredLeavingFluidTemp,
            gallons_per_minute: data.GPM,
            max_fluid_pressure_drop: data.MaximumFluidPressureDrop,
            coils_per_bank: data.NSlabs,
            tube_diameter: finDetails.tube_diameter,
            tube_face_spacing: finDetails.tube_face_spacing,
            tube_row_spacing: finDetails.tube_row_spacing,
            fin_id: finDetails.fin_id,
            rows: data.Nrows,
            fpi: data.Fpi,    
            fin_height: data.FH,
            fin_length: data.FL,
            circuiting_type: 'Auto',
            nfeeds: data.Nfeeds,
            fin_material: data.FinMaterial === 0 ? 'copper' : 'aluminum',
            fin_thickness: data.FinThickness,
            tube_thickness: data.TubeWallThickness,
            connection_size: data.SupplyConnectionsSize,
            number_of_connections: data.NumberOfConnections,
            coil_hand: data.CoilHand === 0 ? 'RightHand' : 'LeftHand',
            casing_material: casingMaterialMapping[data.CasingMaterial] || 'galvanized',
            casing_gauge: data.CasingGauge,
            connection_material: connectionMaterialMapping[data.ConnectionMaterial] || 'Copper',
            connection_type: connectionTypeMapping[data.ConnectionType] || 'mpt',
            coil_coating: coilCoatingMapping[data.CoilCoating] || 'None',
            intermediate_drain_pan: data.IsIntermediateDrainPan,
            turbulators: data.Turbulators
        });
        if(data.GPM !== undefined && data.GPM !== 0) {
            setCheckboxGPM(true);
        } else {
            setCheckboxGPM(false);
        }
        if(data.DesiredLeavingAirDryBulb === 0 && data.DesiredLeavingAirWetBulb === 0) {
            setCheckboxMBH(true);
        } else {
            setCheckboxMBH(false);
        }
        setIsOpen(false);
        setJsonString('');
        setIsValid(false);
        setPerformanceResults([]);
    }
    
    return (
        <div className="coils-content">
            <form onSubmit={onSubmit} className="form-container">
                <p className="coil-header-top" >Airside</p>
                <div className="input-row">
                    <div className="input-container">                    
                        <div className="box-label">Air Volume (CFM)</div> {/* SCFM */}
                        <Input
                            name="air_flow"
                            type="number"
                            value={productDetails.air_flow}
                            onChange={handleChange}
                            min="0"
                            invalid={productDetails.air_flow < 0}
                        />
                    </div>
                    <div className="input-container">
                        <div className="box-label">Altitude (Ft)</div> {/* Altitude */}
                        <Input
                            name="altitude"
                            type="number"
                            value={productDetails.altitude}
                            onChange={handleChange}
                            min="0"
                            max="12000"
                        />
                    </div>
                    <div className="input-container-group"> 
                        <div className="box-label">Ent. DB / WB (°F)</div> {/* EnteringAirDryBulb / EnteringAirWetBulb */}
                        <InputGroup>
                            <Input
                                name="entering_air_dry_bulb"
                                type="number"
                                className="input-border-right"
                                value={productDetails.entering_air_dry_bulb}
                                onChange={handleChange}
                                invalid={productDetails.entering_air_dry_bulb < -40 || productDetails.entering_air_dry_bulb > 120 || productDetails.entering_air_dry_bulb < productDetails.leaving_air_dry_bulb}
                                step='.1'
                                min="-40"
                                max="120"                        
                            />
                            <Input
                                name="entering_air_wet_bulb"
                                type="number"
                                value={productDetails.entering_air_wet_bulb}
                                onChange={handleChange}
                                invalid={productDetails.entering_air_wet_bulb < -40 || productDetails.entering_air_wet_bulb > 120 || productDetails.entering_air_wet_bulb < productDetails.leaving_air_wet_bulb}
                                step='.1'
                                min="-40"
                                max="120"
                            />
                            <FormFeedback>
                            {(productDetails.entering_air_wet_bulb < productDetails.leaving_air_wet_bulb || productDetails.entering_air_dry_bulb < productDetails.leaving_air_dry_bulb) && "Ent. air must be greater than Lvg. air"}
                            </FormFeedback>
                        </InputGroup>
                    </div>
                    <div className="input-container-group"> 
                        <div className="box-label">Lvg. DB / WB (°F)</div> {/* DesiredLeavingAirDryBulb / DesiredLeavingAirWetBulb */}
                        <InputGroup>
                            <Input
                                name="leaving_air_dry_bulb"
                                type="number"
                                className="input-border-right"
                                value={productDetails.leaving_air_dry_bulb}
                                onChange={handleChange}
                                invalid={(productDetails.leaving_air_dry_bulb < 40 && productDetails.mbh === 0) || productDetails.leaving_air_dry_bulb > 120 || productDetails.leaving_air_dry_bulb > productDetails.entering_air_dry_bulb}
                                step='.1'
                                min="40"
                                max="120"
                                disabled={checkboxMBH}                                                
                            />
                            <Input
                                name="leaving_air_wet_bulb"
                                type="number"
                                value={productDetails.leaving_air_wet_bulb}
                                onChange={handleChange}
                                invalid={(productDetails.leaving_air_wet_bulb < 40 && productDetails.mbh === 0) || productDetails.leaving_air_wet_bulb > 120 || productDetails.leaving_air_wet_bulb > productDetails.entering_air_wet_bulb}
                                step='.1'
                                min="40"
                                max="120"
                                disabled={checkboxMBH}
                            />
                            <FormFeedback>
                                {(productDetails.entering_air_wet_bulb < productDetails.leaving_air_wet_bulb || productDetails.entering_air_dry_bulb < productDetails.leaving_air_dry_bulb) ? "Ent. air must be greater than Lvg. air" : ""}
                            </FormFeedback>
                        </InputGroup>
                    </div>
                    <div className="input-container-group"> 
                        <div className="box-label">MBH Required</div> {/* DesiredCapacity */}
                        <InputGroup>
                            <InputGroupText>
                                <Input
                                    type="checkbox"
                                    checked={checkboxMBH}
                                    onChange={(e) => setCheckboxMBH(e.target.checked)}
                                    addon
                                />
                            </InputGroupText>
                            <Input
                                name="mbh"
                                type="number"
                                value={productDetails.mbh}
                                onChange={handleChange}
                                disabled={!checkboxMBH}
                            />
                        </InputGroup>
                    </div>
                </div>

                <p className="coil-header">Internal Fluid</p>
                <div className="input-row">
                    <div className="input-container">
                        <div className="box-label">Fluid</div>  {/* Fluid */}
                        <Input
                            name="fluid"
                            type="select"
                            value={productDetails.fluid}
                            onChange={handleChange}
                    >
                            <option value={"Water"}>Water</option>
                            <option value={"PropyleneGlycol"}>Propylene Glycol</option>
                            <option value={"EthyleneGlycol"}>Ethylene Glycol</option>
                        </Input>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Glycol Percent</div> {/* GlycolMassPercent */}
                        <Input
                            name="glycol_mass_percent"
                            type="number"
                            value={productDetails.glycol_mass_percent}
                            onChange={handleChange}
                            min="0"
                            max="100" 
                            disabled={productDetails.fluid === "Water"}
                        />
                    </div>
                    <div className="input-container-group"> 
                    <div className="box-label">Ent. / Lvg. Temp (°F)</div> {/* EnteringFluidTemp / DesiredLeavingFluidTemp */}
                        <InputGroup>
                            <Input
                                name="entering_fluid_temp"
                                type="number"
                                className="input-border-right"
                                value={productDetails.entering_fluid_temp}
                                onChange={handleChange}
                                invalid={productDetails.entering_fluid_temp < 30 || productDetails.entering_fluid_temp > 200}
                                step='.1'
                                min="30"
                                max="200"                        
                            />
                            <Input
                                name="desired_leaving_fluid_temp"
                                type="number"
                                value={productDetails.desired_leaving_fluid_temp}
                                onChange={handleChange}
                                invalid={(productDetails.desired_leaving_fluid_temp < 30 && productDetails.gallons_per_minute === 0) || productDetails.desired_leaving_fluid_temp > 200}
                                min="30"
                                max="200"
                                disabled={checkboxGPM}
                            />
                        </InputGroup>
                    </div>
                    <div className="input-container-group">
                        <div className="box-label">Gallons Per Minute</div> {/* GPM */}
                        <InputGroup>
                            <InputGroupText>
                                <Input
                                    type="checkbox"
                                    checked={checkboxGPM}
                                    onChange={(e) => setCheckboxGPM(e.target.checked)}
                                    addon
                                />
                            </InputGroupText>
                            <Input
                                name="gallons_per_minute"
                                type="number"
                                value={productDetails.gallons_per_minute}
                                onChange={handleChange}
                                disabled={!checkboxGPM }
                            />
                        </InputGroup>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Max Pressure Drop</div> {/* MaximumFluidPressureDrop */}
                        <Input
                            name="max_fluid_pressure_drop"
                            type="number"
                            value={productDetails.max_fluid_pressure_drop}
                            onChange={handleChange}
                            invalid={productDetails.max_fluid_pressure_drop < 0}
                        />
                    </div>
                </div>

                <p className="coil-header">Physical Data</p>
                <div className="input-row">
                    {/* Name isn't necessary until Coil Selection moves to Jobs */}
                    {/* <div className="input-container">                    
                        <div className="box-label">Name</div>
                        <Input
                            name="tag"
                            type="text"
                            value={productDetails.tag}
                            onChange={handleChange}
                        />
                    </div> */}
                    <div className="input-container">
                        <div className="box-label">Coils per Bank</div> {/* CoilsPerBank */}
                        <Input
                            name="coils_per_bank"
                            type="number"
                            value={productDetails.coils_per_bank}
                            onChange={handleChange}
                            invalid={productDetails.coils_per_bank < 0}
                            min="1"
                            max="4"
                        />
                    </div>  
                    <div className="input-container">
                        <div className="box-label">Tube Geometry - Pattern</div> {/* FinID */}
                        <Input
                            name="fin_id"
                            type="select"
                            value={productDetails.fin_id}
                            onChange={handleChange}
                        >
                            <option value={"C"}>5/8 1.5” x 1.299” - Waffle</option>
                            <option value={"D"}>5/8 1.5” x 1.299” - Flat</option>
                            <option value={"E"}>5/8 1.5” x 1.299” - Sine Wave</option>
                            <option value={"F"}>1/2 1.25” x 1.08” - Sine Wave</option>   
                            <option value={"G"}>1/2 1.25” x 1.08” - Waffle</option>
                            <option value={"H"}>1/2 1.25” x 1.08” - Flat</option>
                            <option value={"J"}>3/8 1” x 0.866” - Sine Wave</option>
                            <option value={"K"}>3/8 1” x 0.866” - Louver</option>
                            <option value={"L"}>3/8 1.25” x 1.08” - Sine Wave</option>
                        </Input>
                    </div>
                    <div className="input-container-group">
                        <div className="box-label">Rows / Fin Density (fpi)</div> {/* Nrows / Fpi */}
                        <InputGroup>
                            <Input
                                name="rows"
                                type="select"
                                value={productDetails.rows}
                                onChange={handleChange}
                            >
                                <option value={0}>Auto</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                            </Input>
                            <Input
                                name="fpi"
                                type="select"
                                value={productDetails.fpi}
                                onChange={handleChange}
                            >
                                <option value={0}>Auto</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                            </Input>
                        </InputGroup>
                    </div>
                    <div className="input-container-group">
                        <div className="box-label">Fin Height / Length (in)</div> {/* FH / FL */}
                        <InputGroup>
                            <Input
                                name="fin_height"
                                type="number"
                                value={productDetails.fin_height}
                                onChange={handleChange}
                                invalid={productDetails.fin_height < 9 }
                                step={productDetails.tube_diameter === 0.625 ? "1.5" : "1"}
                                min="9"
                                max="54"
                            />
                            <Input
                                name="fin_length"
                                type="number"
                                value={productDetails.fin_length}
                                onChange={handleChange}
                                invalid={productDetails.fin_length < 9 || productDetails.fin_length > 205}
                                min="9"
                                max="205"
                            />
                        </InputGroup>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Circuiting</div> {/* CircuitingType */}
                        <Input
                            name="circuiting_type"
                            type="select"
                            value={productDetails.circuiting_type}
                            onChange={handleChange}
                        >
                            <optgroup label="Auto-Select">
                                <option value="Auto">Auto-Select</option>
                            </optgroup>
                            <optgroup label="Quick-Select">
                                <option value="OneEighthCircuit">One-Eighth</option>
                                <option value="QuarterCircuit">Quarter</option>
                                <option value="OneThirdCircuit">One-Third</option>
                                <option value="ThreeEighthsCircuit">Three-Eighths</option>
                                <option value="HalfCircuit">Half</option>
                                <option value="FiveEighthsCircuit">Five-Eighths</option>
                                <option value="TwoThirdsCircuit">Two-Thirds</option>
                                <option value="ThreeQuarterCircuit">Three-Quarter</option>
                                <option value="SevenEighthsCircuit">Seven-Eighths</option>
                                <option value="FullCircuit">Full</option>
                                <option value="OneAndOneHalfCircuit">One and One Half</option>
                                <option value="DoubleCircuit">Double</option>
                            </optgroup>
                            <optgroup label="Specific-Select">
                                {Array.from({ length: 122 }, (_, i) => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                ))}
                            </optgroup>
                        </Input>
                    </div>
                    <div className="input-container-group">
                        <div className="box-label">Connection Size</div> {/* SupplyConnectionSize, NumberOfConnections */}
                        <InputGroup> 
                            <Input 
                                name="connection_size"
                                type="select"
                                value={productDetails.connection_size}
                                onChange={handleChange}
                            >
                                <option value={0}>Auto</option>
                                <option value={0.75}>0.75</option>
                                <option value={1.00}>1.00</option>
                                <option value={1.25}>1.25</option>
                                <option value={1.50}>1.50</option>
                                <option value={2.00}>2.00</option>
                                <option value={2.50}>2.50</option>
                                <option value={3.00}>3.00</option>
                                <option value={4.00}>4.00</option>
                            </Input>
                            <Input
                                name="number_of_connections"
                                type="select"
                                value={productDetails.number_of_connections}
                                onChange={handleChange}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                            </Input>
                        </InputGroup>
                    </div>
                    {/* <div className="input-container">
                        <div className="box-label">Fin Material</div>                       
                        <Input
                            name="fin_material"
                            type="select"
                            value={productDetails.fin_material}
                            onChange={handleChange}
                        >
                            <option value={"aluminum"}>Aluminum</option>
                            <option value={"copper"}>Copper</option>
                        </Input>
                    </div> */}
                    <div className="input-container">
                        <div className="box-label">Fin Material - Thickness (in)</div> {/* FinMaterial - FinThickness */}
                        <Input
                            name="fin_thickness_material"
                            type="select"
                            value={`${productDetails.fin_material}-${productDetails.fin_thickness}`}
                            onChange={handleChange}
                        >
                            <option value="aluminum-0.006">Aluminum - 0.0060</option>
                            <option value="aluminum-0.0075">Aluminum - 0.0075</option>
                            <option value="aluminum-0.010">Aluminum - 0.010</option>
                            <option value="copper-0.006">Copper - 0.0060</option>
                            <option value="copper-0.0075">Copper - 0.0075</option>
                        </Input>
                    </div>
                    {/* <div className="input-container">
                        <div className="box-label">Tube Material</div>
                        <Input
                            name="tube_material"
                            type="select"
                            value={productDetails.tube_material}
                            onChange={handleChange}
                        >
                            <option value={"copper"}>Copper</option>
                        </Input>
                    </div> */}
                    <div className="input-container">
                        <div className="box-label" style={{ width: '110%' }}>Tube Material - Thickness (in)</div> {/* TubeMaterial - TubeWallThickness */}
                        <Input
                            name="tube_thickness"
                            type="select"
                            value={productDetails.tube_thickness}
                            onChange={handleChange}
                        >
                            { productDetails.tube_diameter === 0.625 &&
                                <>
                                    <option value={0.020}>Copper - 0.020</option>
                                    <option value={0.025}>Copper - 0.025</option>
                                    <option value={0.035}>Copper - 0.035</option>
                                    <option value={0.049}>Copper - 0.049</option>
                                </>
                            }
                            { productDetails.tube_diameter === 0.5 &&
                                <>
                                    <option value={0.016}>Copper - 0.016</option>
                                    <option value={0.025}>Copper - 0.025</option>
                                </>
                            }
                            { productDetails.tube_diameter === 0.375 &&
                                <>
                                    <option value={0.014}>Copper - 0.014</option>
                                    <option value={0.016}>Copper - 0.016</option>
                                    <option value={0.022}>Copper - 0.022</option>
                                </>
                            }
                        </Input>
                    </div>
                </div>

                <p className="coil-header">Construction</p>
                <div className="input-row">
                    <div className="input-container">
                        <div className="box-label">Total Coils</div> {/* Qty */}
                        <Input
                            name="total_coils"
                            type="number"
                            value={productDetails.total_coils}
                            onChange={handleChange}
                        />
                </div>
                    <div className="input-container">
                        <div className="box-label">Coil Hand</div> {/* CoilHand */}
                        <Input
                            name="coil_hand"
                            type="select"
                            value={productDetails.coil_hand}
                            onChange={handleChange}
                        >
                            <option value={"RightHand"}>Right</option>
                            <option value={"LeftHand"}>Left</option>
                        </Input>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Casing Material</div> {/* CasingMaterial */}
                        <Input
                            name="casing_material"
                            type="select"
                            value={productDetails.casing_material}
                            onChange={handleChange}
                        >
                            <option value={"galvanized"}>Galvanized</option>
                            <option value={"stainless304"}>304 Stainless</option>
                            <option value={"stainless316"}>316 Stainless</option>
                            <option value={"aluminum"}>Aluminum</option>
                        </Input>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Casing Gauge</div> {/* CasingGauge */}
                        <Input
                            name="casing_gauge"
                            type="select"
                            value={productDetails.casing_gauge}
                            onChange={handleChange}
                        >
                            <option value={18}>18 ga.</option>
                            <option value={16}>16 ga.</option>
                            <option value={14}>14 ga.</option>
                            <option value={12}>12 ga.</option>
                        </Input>
                    </div>
                    {/* <div className="input-container">
                        <div className="box-label">Casing Type</div>
                        <Input
                            name="casing_type"
                            type="select"
                            value={productDetails.casing_type}
                            onChange={handleChange}
                        >
                            <option value={"Flanged"}>Flanged</option>
                        </Input>
                    </div> */}
                    <div className="input-container">
                        <div className="box-label">Connection Material</div> {/* ConnectionMaterial */}
                        <Input
                            name="connection_material"
                            type="select"
                            value={productDetails.connection_material}
                            onChange={handleChange}
                        >
                            <option value={"Copper"}>Copper</option> 
                            <option value={"Steel"}>Steel</option>
                            <option value={"RedBrass"}>Red Brass</option>
                        </Input>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Connection Type</div> {/* ConnectionType */}
                        <Input
                            name="connection_type"
                            type="select"
                            value={productDetails.connection_type}
                            onChange={handleChange}
                        >
                            <option value={"mpt"}>MPT</option>
                            <option value={"fpt"}>FPT</option>
                            <option value={"swt"}>SWT</option>
                            <option value={"grooved"}>Grooved</option>
                        </Input>
                    </div>
                    {/* <div className="input-container">
                        <div className="box-label">Header Material</div> 
                        <Input
                            name="header_material"
                            type="select"
                            value={productDetails.header_material}
                            onChange={handleChange}
                        >
                            <option value={"copper"}>Copper</option>
                        </Input>
                    </div> */}
                    <div className="input-container">
                        <div className="box-label">Coil Coating</div> {/* CoilCoating */}
                        <Input
                            name="coil_coating"
                            type="select"
                            value={productDetails.coil_coating}
                            onChange={handleChange}
                        >
                            <option value={"None"}>None</option>
                            <option value={"Finkote2"}>Finkote 2</option>
                            <option value={"Finkote2UV"}>Finkote 2 w/ UV Topcoat</option>
                            <option value={"FinkoteCc"}>FinkoteCc</option>
                            <option value={"FinkoteHp"}>FinkoteHp</option>
                            <option value={"FinkoteZx"}>FinkoteZx (Zpex)</option>
                            <option value={"Heresite"}>Heresite</option>
                            {/* <option value={"Heresite UV"}>Heresite UV</option> */}
                            {/* <option value={"Heresite Hydrophilic"}>Heresite Hydrophilic</option> */}
                            <option value={"ElectroFin"}>ElectroFin</option>
                            <option value={"ElectroFinUV"}>ElectroFin UV</option>
                            <option value={"BlygoldAntiCorrosive"}>Blygold Anti-Corrosive</option>
                            <option value={"BlygoldAntiMicrobial"}>Blygold Anti-Microbial</option>
                            <option value={"BlackPolyCoatedFin"}>Black Poly Coated Fin</option>
                        </Input>
                    </div>
                </div>

                <p className="coil-header">Options</p>
                <div className="input-row">
                    <div className="input-container">
                        <div className="box-label">Drain Pan - Side</div>
                        <Input
                            name="drain_pan_material_connection_side"
                            type="select"
                            value={`${productDetails.drain_pan_material}-${productDetails.drain_pan_connection_side}`}
                            onChange={handleChange}
                        >
                            <option value={"stainless_steel-drive_side"}>Stainless Steel - Drive Side</option>
                            <option value={"stainless_steel-opposite_drive_side"}>Stainless Steel - Opposite Drive Side</option>
                            <option value={"galvanized_steel-drive_side"}>Galvanized Steel - Drive Side</option>
                            <option value={"galvanized_steel-opposite_drive_side"}>Galvanized Steel - Opposite Drive Side</option>
                        </Input>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Door Location</div>
                        <Input
                            name="door_location"
                            type="select"
                            value={productDetails.door_location}
                            onChange={handleChange}
                        >
                            <option value={"both_sides"}>Both Sides</option>
                            <option value={"drive_side"}>Drive Side</option>
                            <option value={"opposite_drive_side"}>Opposite Drive Side</option>
                        </Input>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Door Opening</div>
                        <Input
                            name="door_opening"
                            type="select"
                            value={productDetails.door_opening}
                            onChange={handleChange}
                        >
                            <option value={"outward"}>Outward</option>
                            <option value={"inward"}>Inward</option>
                        </Input>
                    </div>
                    <div className="input-container">
                        <div className="box-label">Drive Side Door Width (in)</div> 
                            <Input
                                name="door_width_drive_side"
                                type="number"
                                value={productDetails.door_width_drive_side}
                                onChange={handleChange}
                            />
                    </div>
                    <div className="input-container">
                        <div className="box-label">Opp. Side Door Width (in)</div> 
                            <Input
                                name="door_width_opposite_drive_side"
                                type="number"
                                value={productDetails.door_width_opposite_drive_side}
                                onChange={handleChange}
                            />
                    </div>
                    {/* <div className="input-container-group">
                        <div className="box-label" style={{ width: '120%' }}>Door Width Drv. / Opp. Drv. (in)</div> 
                        <InputGroup>
                            <Input
                                name="door_width_drive_side"
                                type="number"
                                value={productDetails.door_width_drive_side}
                                onChange={handleChange}
                            />
                            <Input
                                name="door_width_opposite_drive_side"
                                type="number"
                                value={productDetails.door_width_opposite_drive_side}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </div> */}
                    <div className="input-container" style={{ marginTop: '1em', paddingLeft: '.5em' }}>
                        <label> {/* IntermediateDrainPan */}
                            <Input 
                                type="checkbox" 
                                name="intermediate_drain_pan" 
                                value={productDetails.intermediate_drain_pan}
                                onChange={handleChange} 
                            />
                            Inter. Drain Pan
                        </label>
                    </div>
                    <div className="input-container" style={{ marginTop: '1em', paddingLeft: '.5em'}}>
                        <label className={productDetails.tube_diameter  === 0.625 ? 'grayed-out' : ''} > {/* Turbulators */}
                            <Input 
                                type="checkbox" 
                                name="turbulators" 
                                value={productDetails.turbulators}
                                onChange={handleChange} 
                                disabled={productDetails.tube_diameter !== 0.625}
                            />
                            Turbulators
                        </label>
                    </div>
                </div>
                <div className="button-row">
                    <button 
                        type="button"
                        className="modal-button" 
                        onClick={() => setIsOpen(true)}
                    >
                        <FontAwesomeIcon icon={faUpload} style={{ marginRight: '0.5em', fontSize: '1em' }} />
                        Import JSON
                    </button>
                    <button type="submit" className="coil-button" >Submit</button>
                </div>
            </form>

            <div className="coil-results">
                {loading && 
                    <div className="loading">
                        <Spinner />
                    </div>
                }
                {error && 
                    <div className="error">
                        <FontAwesomeIcon icon={faCircleExclamation} className="error-icon" /><br />{' Error: '}{` ${error}. `}
                    </div>
                }
                {performanceResults?.length > 0 && performanceResults.map((performanceResult: any) => (
                    <Card className={`results-card ${circuitingType === 'Auto' ? 'extend-width' : ''}`} key={performanceResult.ModelNumber}>
                        <div className="results-header">
                            <span className="product-model">
                                {performanceResult.ModelNumber}
                            </span>
                            <div className="coil-button-group">
                                <button className='icon' title='Download quote' onClick={() => {
                                    generateCoilQuote({
                                        data: {
                                            product: productDetails,
                                            performance: performanceResult,
                                        }
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                </button>
                                <button className="icon" title='Download Daikin submittal' onClick={() => {
                                    generateDaikinDocx({
                                        docType: 'Chilled Water Coil',
                                        data: {
                                            product: productDetails,
                                            performance: performanceResult,
                                        }
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faFileWord} />
                                </button>
                                <button className="icon" title='Download Daedex submittal' onClick={() => {
                                    generateDaedexDocx({
                                        docType: 'Coil',
                                        coilData: {
                                            product: productDetails,
                                            performance: performanceResult,
                                        },
                                    })
                                }}>
                                    <FontAwesomeIcon icon={faFileDownload} />
                                </button>
                            </div>
                        </div>
                        <CardBody className="d-flex result-body">
                            <Col
                                lg="12"
                                md="12"
                                sm="12"
                                className="d-flex flex-column justify-content-between result-content"
                            >
                            <div className="d-flex flex-column justify-content-around result-details-container">
                                <div className="d-flex justify-content-between result-details">
                                    <div className="result-detail-item">
                                        <p>Rows / FPI (Fin Thickness)</p>
                                        <b>{`${performanceResult.Nrows} / ${performanceResult.Fpi} (${performanceResult.FinThickness} in)`}</b>
                                    </div>
                                    <div className="result-detail-item">
                                        <p>Lvg Air DB / WB</p>
                                        <b>{`${performanceResult.LeavingAirDryBulb.toFixed(1)} / ${performanceResult.LeavingAirWetBulb.toFixed(1)} °F`}</b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between result-details">
                                    <div className="result-detail-item">
                                        <p>Total Capacity {coilsPerBank >= 2 && '/ Per Coil'}</p>
                                        <b>{`${(performanceResult.TotalCapacity / 1000).toFixed(1)} ${coilsPerBank >= 2 ? `/ ${((performanceResult.TotalCapacity / 1000) / coilsPerBank).toFixed(1)}` : ''} MBH`}</b>
                                    </div>
                                    <div className="result-detail-item">
                                        <p>Sensible Capacity {coilsPerBank >= 2 && '/ Per Coil'}</p>
                                        <b>{`${(performanceResult.SensibleCapacity / 1000).toFixed(1)} ${coilsPerBank >= 2 ? `/ ${((performanceResult.SensibleCapacity / 1000) / coilsPerBank).toFixed(1)}` : ''} MBH`}</b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between result-details">
                                    <div className="result-detail-item">
                                        <p>Face Velocity</p>
                                        <b>{`${performanceResult.FaceVelocity.toFixed(2)} fpm`}</b>
                                    </div>
                                    <div className="result-detail-item">
                                        <p>Lvg Fluid Temp</p>
                                        <b>{`${performanceResult.LeavingFluidTemp.toFixed(1)} °F`}</b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between result-details">
                                    <div className="result-detail-item">
                                        <p>Fluid Flow Rate {coilsPerBank >= 2 && '/ Per Coil'}</p>
                                        <b>{`${performanceResult.GPM.toFixed(2)} ${coilsPerBank >= 2 ? `/ ${(performanceResult.GPM / coilsPerBank).toFixed(2)}` : ''} GPM`}</b>
                                    </div>
                                    <div className="result-detail-item">
                                        <p>Fluid Pressure Drop</p>
                                        <b>{`${performanceResult.FluidPressureDrop.toFixed(2)} ft H20`}</b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between result-details">
                                    <div className="result-detail-item">
                                        <p>Air Pressure Drop</p>
                                        <b>{`${performanceResult.AirsidePressureDrop.toFixed(2)} in H20`}</b>
                                    </div>
                                    <div className="result-detail-item">
                                        <p>Coil Circuiting</p>
                                        <b>{`${circuitingType === 'Auto' ? 'Optimized at ' : ''}${performanceResult.Nfeeds} Feeds / ${performanceResult.Npass} Pass`}</b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between result-details">
                                    <div className="result-detail-item">
                                        <p>Connection Size</p>
                                        <b>{`(${performanceResult.NumberOfConnections}) ${performanceResult.ReturnConnectionSize.toFixed(2)}"`}</b>
                                    </div>
                                    <div className="result-detail-item">
                                        <p>Fluid Velocity</p>
                                        <b>{`${performanceResult.FluidVelocity.toFixed(2)} ft/s`}</b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between result-details">
                                    <div className="result-detail-item">
                                        <p>Dry Weight (Each)</p>
                                        <b>{`${performanceResult.CoilWeight.toFixed(1)} Lbs`}</b>
                                    </div>
                                    <div className="result-detail-item">
                                        <p>Price (Each) Less Freight</p>
                                        <b>{`$${(performanceResult.Price * .80).toFixed(0)}`}</b>
                                    </div>
                                </div>                               
                            </div>
                            </Col>
                        </CardBody>
                    </Card>
                ))}
            </div>
            <Modal isOpen={isOpen} toggle={() => {setIsOpen(false); setJsonString('');}} autoFocus={false}>
                <div className="modal-header">
                    <h3>
                        Import JSON
                        <FontAwesomeIcon
                            id="import-json-info"
                            style={{ fontSize: 14, cursor: "pointer", alignSelf: "flex-end", paddingLeft: "5px", paddingBottom: "3px" }}
                            icon={faInfoCircle} />
                        <UncontrolledTooltip 
                            placement='right' 
                            target="import-json-info" 
                            className="tooltip-container"
                            style={{ maxWidth: '400px', textAlign: 'left', maxHeight: '60px', pointerEvents: 'none', display: 'block', zIndex: '1575' }}
                            >
                            <p><i>Total Coils</i> isn't available to import. 
                                <br/><i>Circuiting</i> will be set to <i>Auto-Select</i>.</p>
                        </UncontrolledTooltip>
                    </h3> 
                    <FontAwesomeIcon
                        style={{ fontSize: 20, cursor: "pointer", marginLeft: "5px" }}
                        onClick={() => { if (!loading) setIsOpen(false); setJsonString(''); }}
                        icon={faXmarkCircle} />
                </div>
                <ModalBody>
                    <Input
                        autoFocus
                        placeholder="Paste JSON"
                        name="json"
                        type="textarea"
                        className="large-textarea"
                        value={jsonString}
                        onInput={handleJson}
                        invalid={jsonString !== '' && !isValid}
                    />
                    <FormFeedback>
                         Invalid JSON
                    </FormFeedback>
                </ModalBody>
                <ModalFooter className="justify-content-start mx-2">
                    <Button 
                        disabled={loading || !isValid} 
                        onClick={() => {
                            importJson(jsonData);
                        }}
                    >Import</Button>
                </ModalFooter>
                </Modal>
        </div>
    )
}