import { Card, CardBody, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import featuredItems from "../config/featuredFans.json"

import {
  Control_Panel_Sizing,
  Product_Set_Input,
} from "../generated/graphql";

import FanCurveChart from "./FanCurveChart";

import { JSObjectToRPMCoefficient, RPMCoefficient } from "../helpers/file-checker";
import { formatMoney } from "../helpers/money-utils";

import "./ConfigurationCard.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { calculateAltitudeCorrectionFactor } from "../helpers/generate-configuration-query";
import { FanSelection } from "@mashvac/daedex";

const ConfigurationCard = ({
  product,
  configId,
  fanSelection,
  controlPanel,
  selectedConfigurationId,
  selectedFanCount,
  onSelect,
  onExpand,
}: {
  product: Product_Set_Input;
  configId: number;
  fanSelection: FanSelection;
  controlPanel: Control_Panel_Sizing | undefined;
  selectedConfigurationId: number | null;
  selectedFanCount: number | null;
  onSelect: (model: string | null, id: number | null, price: number | null , fanCount: number | null) => void;
  onExpand: Function;
}) => {
  const altitudeCorrectionFactor = calculateAltitudeCorrectionFactor(product?.altitude);
  const backdraftDampersCorrectionValue = product?.backdraft_dampers ? 0.2 : 0;

    let adjustedCoefficients: RPMCoefficient[] = [];

    for (let RPMKey of Object.keys(fanSelection.fan_desc.rpm_coefficients)){
        adjustedCoefficients.push(JSObjectToRPMCoefficient(fanSelection.fan_desc.rpm_coefficients, parseInt(RPMKey)));
    }

    adjustedCoefficients = adjustedCoefficients.map(c => Object.assign({}, c, {
        pressure: {
            a: c.pressure.a,
            b: c.pressure.b,
            c: c.pressure.c,
            d: (c.pressure.d / altitudeCorrectionFactor) - backdraftDampersCorrectionValue,
        }
    }));

  const selected =
    selectedConfigurationId === configId &&
    selectedFanCount === fanSelection.fans.amount;

  return (
    <Card className={`configuration-card ${selected ? 'selected' : ''} ${fanSelection.fan_desc.manufacturer === "Peerless Blowers" ? 'warning-card' : ''} ${fanSelection.selection_data.redundancy > 1 ? 'high-redundancy' : ''}`}>
      <div className="configuration-header">
        {featuredItems.indexOf(fanSelection.model) >= 0 && (<span className="featured-item">
          <FontAwesomeIcon icon={faStar} />
        </span>)}
        <span className="product-model">
          {fanSelection.fans.amount} - {fanSelection.model}
        </span>
        <span className="product-cost">
          {formatMoney(fanSelection.cost_breakdown.total_cost ?? 0)}
        </span>
        <button className="basic" onClick={() => onExpand({
          fanSelection,
          sizing: controlPanel,
          fanCount: fanSelection.fans.amount
        })}>Inspect</button>
        <button
          className={selected ? 'selected' : 'not-selected'}
          onClick={() => 
            selected
              ? onSelect(null, null, null, null)
              : onSelect(fanSelection.model, configId, Math.ceil(fanSelection.cost_breakdown.total_cost), fanSelection.fans.amount)
          }
        >
          <span>
            {selected && <FontAwesomeIcon icon="check" className="ps-1" />}
            <span className={selected && fanSelection.model.length > 20 ? "long-model-name" : ""}>
              {selected ? " Selected" : "Select"}
            </span>
          </span>
        </button>
      </div>
      <CardBody className="d-flex configuration-body">
        <Col
          lg="7"
          md="7"
          sm="7"
          className="d-flex flex-column justify-content-between configuration-content"
        >
          <div className="d-flex flex-column justify-content-around configuration-details-container">
            <div className="d-flex justify-content-between configuration-details">
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>EFFICIENCY</p>
                <b>{(fanSelection.selection_data.efficiency)?.toFixed(2)}%</b>
              </div>
              <div className="configuration-detail-item">
                <p>FLA</p>
                <b>{fanSelection.selection_data.fla?.toFixed(2)} A</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>REDUNDANCY</p>
                <b>{Math.round(Math.max(0.01, fanSelection.selection_data.redundancy * 100))}%</b>
              </div>
              <div className="configuration-detail-item">
                <p>MCA</p>
                <b>{fanSelection?.selection_data.mca.toFixed(2)} A</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>WEIGHT</p>
                <b>
                  {Math.round(fanSelection.selection_data.array_weight ?? 0)}{" "}
                  LBS
                </b>
              </div>
              <div className="configuration-detail-item">
                <p>MOCP</p>
                <b>{Math.round(fanSelection?.selection_data.mocp)} A</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>MOTOR POWER</p>
                <b>{fanSelection.fan_desc.nominal_hp.toFixed(2) || 0} HP</b>
              </div>
              <div className="configuration-detail-item">
                <p>INLET SOUND</p>
                <b>{fanSelection.selection_data.return_sum.toFixed(2) || 0}</b>
              </div>
            </div>
            <div className="d-flex justify-content-between configuration-details">
              <div className="configuration-detail-item">
                <p>INPUT POWER</p>
                <b>{fanSelection.selection_data.horsepower.toFixed(2)} HP</b>
              </div>
              <div className="configuration-detail-item">
                <p>OUTLET SOUND</p>
                <b>{fanSelection.selection_data.discharge_sum.toFixed(2) || 0}</b>
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg="5"
          md="5"
          sm="5"
          className="d-flex flex-column justify-content-between configuration-actions"
        >
          <div style={{ height: "150px" }}>
            <FanCurveChart
              tsp={product.total_static_pressure ?? 0}
              cfm={fanSelection.cfm ?? 0}
              fanCount={fanSelection.fans.amount}
              coefficients={adjustedCoefficients}
              model={fanSelection.model}
              minimum
            />
          </div>
          <div className="d-flex justify-content-between configuration-details">
            <div className="configuration-detail-item">
              <p>BLADE DIAMETER</p>
              <b>{fanSelection.fan_desc.blade_diameter} MM</b>
            </div>
            <div className="configuration-detail-item">
              <p>FAN DEPTH</p>
              <b>{fanSelection.fan_desc.depth} IN</b>
            </div>
          </div>
        </Col>
      </CardBody>
    </Card>
  );
};

export default ConfigurationCard;
