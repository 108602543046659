// import { findDesignCoefficients, findDesignPoint, findMaxCFM, generateRPMCurve, generateSystemCurve } from "./fan-curve";
// import { calculateAltitudeCorrectionFactor, calculateEfficiency, calculateFLA, calculateFanArrayWeight, calculateMCANew, calculateMOCPNew, calculateOperatingInputHorserpower, calculateOperatingRPM, calculateRPMSoundPower, calculateRedundancy } from "./generate-configuration-query";
import { toast } from 'react-toastify';
// import { getPanelAll } from "./calculate-custom-controls";
import { generateSubmittalDocx } from '@mashvac/daedex-common/src/documents/submittal.js';
import { saveAs } from 'file-saver';
// import { RPMCoefficient } from "./file-checker";

// const generateFanData = ({
//     product,
//     fanFitData,
//     sizings
//   }) => {

//   const altitudeCorrectionFactor = calculateAltitudeCorrectionFactor(product?.altitude);
//   const backdraftDampersCorrectionValue = product?.backdraft_dampers ? 0.2 : 0;
//   const adjustedTSP = (product?.total_static_pressure ?? 0) * altitudeCorrectionFactor + backdraftDampersCorrectionValue;

//   const fla = calculateFLA(product.configuration?.fla_460, product.voltage_override, product.configuration?.min_v_fla, product.configuration?.max_v_fla);
//   const fanCount = product.configured_fan_count ?? 1;
//   const airVolumeEach = product.air_flow / fanCount;

//   const rawCoefficients = !!product.configuration?.rmp_coefficients
//     ? JSON.parse(product.configuration?.rmp_coefficients) : null;
//   let coefficients = [];
//   if (!!rawCoefficients) {
//     const RCKeys = Object.keys(rawCoefficients);
//     coefficients = RCKeys.map(k => rawCoefficients[k]);
//   }

//   const unadjustedDesignCoefficients = findDesignCoefficients({
//     cfm: product.air_flow,
//     tsp: product?.total_static_pressure ?? 0,
//     fanCount,
//     coefficients,
//     model: product.configuration?.model
//   });

//   const designCoefficients = findDesignCoefficients({
//     cfm: product.air_flow,
//     tsp: adjustedTSP,
//     fanCount,
//     coefficients,
//     model: product.configuration?.model
//   });

//   const designRPM = calculateOperatingRPM({
//     RPMUpperBound: designCoefficients.upper.rpm,
//     RPMLowerBound: designCoefficients.lower.rpm,
//     Interpolation: designCoefficients.interpolation
//   });

//   const horsepower = designCoefficients.upper.coefficient?.powerHp && designCoefficients.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
//     product.air_flow,
//     designCoefficients.fanCount || 0,
//     designCoefficients.lower.coefficient?.powerHp,
//     designCoefficients.upper.coefficient?.powerHp,
//     designCoefficients.interpolation
//   ): 0;

//   const efficiency = calculateEfficiency({
//     fanCount,
//     tsp: adjustedTSP,
//     cfm: product.air_flow,
//     horsepower
//   });

//   const maxRPM = Math.max(...(Object.keys(rawCoefficients).map((k) => parseInt(k))));
//   const maxRPMCoefficients = coefficients.find((c) => c.RPM === maxRPM)
//   const maxCFM = maxRPMCoefficients
//     ? findMaxCFM({
//       tsp: adjustedTSP,
//       coefficient: maxRPMCoefficients.pressure,
//     })
//     : 0;

//   const redundancy = calculateRedundancy({
//     fanCount,
//     maxCFM: maxCFM,
//     designCFM: product.air_flow,
//   });

//   // Calculate controls weight 
//   const mca = calculateMCANew({ 
//     fla: fla, 
//     fanCount: fanCount, 
//     voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage, 
//     mas_ec_plus: product.mas_ec_plus
//   })

//   const mocp = calculateMOCPNew({
//     fla: fla, 
//     fanCount: product.configured_fan_count ?? 1,
//     voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage,
//     mas_ec_plus: product.mas_ec_plus,
//   });

//   const productPanel = getPanelAll(product.configured_fan_count, mocp, sizings, product.mas_ec_plus, product.outdoor_rating);

//   const arrayWeight = calculateFanArrayWeight({
//     fanCount,
//     fanWeight: product.configuration?.fan_weight,
//     panelWeight: productPanel.sizing.weight,
//     bulkheadHeight: product.height || 0,
//     bulkheadWidth: product.width || 0,
//   });

//   const omitCabinetDeduct = true;
//   const fullLoadCurrent = product.configuration?.fla_460 ?? product.configuration?.fla_400;
//   const soundRPM = !!designCoefficients.upper.coefficient && !!designCoefficients.lower.coefficient
//     ? calculateRPMSoundPower(fanCount, product.air_flow / fanCount, designCoefficients.lower.coefficient, designCoefficients.upper.coefficient, designCoefficients.interpolation)
//     : null;

//   const adjustedCoefficients = coefficients.map(c => Object.assign({}, c, {
//     pressure: {
//       a: c.pressure.a,
//       b: c.pressure.b,
//       c: c.pressure.c,
//       d: (c.pressure.d/altitudeCorrectionFactor) - backdraftDampersCorrectionValue
//     }
//   }));

//   const designPoints = 25;
//   const designRPMCurve = !!designRPM
//   ? generateRPMCurve({
//     fanCount,
//     rpmCoefficient: {
//       RPM: Math.round(designRPM),
//       pressure: unadjustedDesignCoefficients.coefficient,
//     } as RPMCoefficient,
//     points: designPoints,
//     dashed: true
//   })
//   : null;

//   const systemCurve = maxRPMCoefficients ? generateSystemCurve({
//     cfm: product.air_flow,
//     tsp: product.total_static_pressure,
//     fanCount,
//     points: designPoints,
//     maxRpmPressureCoefficients: maxRPMCoefficients.pressure
//   }): null;

//   // console.log('UNadjusted', unadjustedDesignCoefficients)
//   console.log('designRPM, adjustedCoefficients', designRPM, adjustedCoefficients)
//   console.log('coefficients', coefficients)
   
//   return {
//     type: "fan",
//     tag: product.tag,
//     nominal: {
//       model: product.configuration?.model, // Fan Model
//       fanCount: fanCount, // Fan Quantity
//       manufacturer: product.configuration?.model?.startsWith('N88') ? 'Rosenberg' : 'Ziehl-Abegg',
//       bladeMaterial: product.configuration?.blade_material ?? '', // Blade Material
//       horsePower: product.configuration?.nominal_hp, // Horsepower (each)
//       maxRpm: maxRPM,
//       weight: {
//         each: Math.round(product.configuration?.fan_weight), // Fan Weight
//         total: Math.round(arrayWeight) // Array Weight
//       },
//       // performanceCurves: coefficients.map(coeff => ({
//       //   rpm: coeff.RPM,
//       //   pressureCoefficients: [
//       //     coeff.pressure.a,
//       //     coeff.pressure.b,
//       //     coeff.pressure.c,
//       //     coeff.pressure.d
//       //   ],
//       //   powerCoefficients: []
//       // })).reverse()
//       performanceCurves: [
//         { rpm: 600, pressureCoefficients: [-1.6258190786476092e-10, -1.12747e-7, 0, 0.9783438317268488], powerCoefficients: [] },
//         { rpm: 1200, pressureCoefficients: [-1.2584920370519378e-10, -9.494959416666417e-8, 29.65507423151165e-7, 1.736659809283948], powerCoefficients: [] },
//         { rpm: 1600, pressureCoefficients: [-1.0157078698131979e-10, -8.73104909383e-8, -1.0228531e-6, 2.71389185], powerCoefficients: [] },
//         { rpm: 2000, pressureCoefficients: [-8.608969312646585e-11, -7.942504097373004e-8, -2.9678924728880948e-6, 3.9072590331095056], powerCoefficients: [] },
//         { rpm: 2400, pressureCoefficients: [-7.760335377165854e-11, -6.044366532994121e-8, -1.5245712425114935e-5, 5.313535], powerCoefficients: [] },
//         { rpm: 2800, pressureCoefficients: [-7.081391650034735e-11, -4.5465598933221556e-8, -2.7725983014235468e-5, 6.93447609005134], powerCoefficients: [] },
//         { rpm: 3200, pressureCoefficients: [-6.438936680478672e-11, -3.7561385958954383e-8, -2.745826438927121e-5, 8.74965952713547], powerCoefficients: [] },
//         { rpm: 3500, pressureCoefficients: [-5.610891935005739e-11, -4.698636675142905e-8, -9.67507090802554e-6, 10.72692342960707], powerCoefficients: [] }
//       ]
//     },
//     performance: {
//       cfm: {
//         each: parseInt(airVolumeEach.toString()), // CFM (each)
//         total: Math.round(product.air_flow ?? 0) // CFM (total)
//       },
//       tsp: product.total_static_pressure.toFixed(2), // TSP
//       inputHorsePower: {
//         each: horsepower.toFixed(2), // Input HP (each)
//         total: (horsepower * fanCount).toFixed(2) // Input HP (Total) // TODO: check this
//       },
//       rpm: Math.round(designRPM), // RPM
//       efficiency: (efficiency * 100).toFixed(2), // Efficiency
//       redundancy: Math.round(Math.max(0.01, (redundancy * 100))), // Redundency
//       controls: {
//         location: product.outdoor_rating ? "NEMA 4 (Outdoor)" : "NEMA 1 (Indoor)", // FLA (each)
//         disconnect: "100", // Disconnect
//         input: { min: 0, max: 10 } // Contol Input
//       },
//       electrical: {
//         voltage: {
//           value: product.voltage_override ?? product.configuration?.voltage,
//           phase: 3
//         },
//         fla: fla,
//         mca: mca.toFixed(2),
//         mocp: Math.round(mocp)
//       },
//       systemCurve: { rpm: 2486, pressureCoefficients: [-6.438936680478672e-11 * 0.7 - 5.610891935005739e-11 * 0.3, -3.7561385958954383e-8 * 0.7 - 4.698636675142905e-8 * 0.3, - 2.745826438927121e-5 * 0.7 - 9.67507090802554e-6 * 0.3, 8.74965952713547 * 0.7 + 10.72692342960707 * 0.3] },
//       // systemCurve: {
//       //   rpm: designRPM,
//       //   pressureCoefficients: adjustedCoefficients.map(c => [
//       //     c.pressure.a,
//       //     c.pressure.b,
//       //     c.pressure.c,
//       //     c.pressure.d
//       //   ])
//       // },
//       sound: soundRPM ? {
//         discharge: [
//           Math.round(soundRPM?.hz63.unitDischarge ?? 0),
//           Math.round(soundRPM?.hz125.unitDischarge ?? 0),
//           Math.round(soundRPM?.hz250.unitDischarge ?? 0),
//           Math.round(soundRPM?.hz500.unitDischarge ?? 0),
//           Math.round(soundRPM?.hz1000.unitDischarge ?? 0),
//           Math.round(soundRPM?.hz2000.unitDischarge ?? 0),
//           Math.round(soundRPM?.hz4000.unitDischarge ?? 0),
//           Math.round(soundRPM?.hz8000.unitDischarge ?? 0)
//         ],
//         return: [
//           Math.round(soundRPM?.hz63.unitReturn ?? 0),
//           Math.round(soundRPM?.hz125.unitReturn ?? 0),
//           Math.round(soundRPM?.hz250.unitReturn ?? 0),
//           Math.round(soundRPM?.hz500.unitReturn ?? 0),
//           Math.round(soundRPM?.hz1000.unitReturn ?? 0),
//           Math.round(soundRPM?.hz2000.unitReturn ?? 0),
//           Math.round(soundRPM?.hz4000.unitReturn ?? 0),
//           Math.round(soundRPM?.hz8000.unitReturn ?? 0)
//         ]
//       } : null
//     },
//     options: []
//   };
// }

const generateCoilData = ({
    data
  }) => {

  return {
    // type: "chilled_water_coil",
    type: "coil",
    configuration: {
      nominal: {
        model: data.performance.ModelNumber,
        coilsPerBank: data.product.coils_per_bank, 
        rows: data.performance.Nrows,
        finsPerInch: data.performance.Fpi.toFixed(1), 
        weight: { 
          dry: Math.round(data.performance.CoilWeight), 
          operating: Math.round(data.performance.OperatingWeight),
        }, 
        casingMaterial: data.product.casing_gauge,
        fin: { 
          height: data.performance.FH, 
          length: data.performance.FL, 
          material: data.product.fin_material.charAt(0).toUpperCase() + data.product.fin_material.slice(1),
          thickness: data.performance.FinThickness,
        },
        tube: { 
          material: data.product.tube_material.charAt(0).toUpperCase() + data.product.tube_material.slice(1), 
          thickness: data.performance.TubeWallThickness.toFixed(3),
          diameter: data.product.tube_diameter,
          faceSpacing: data.product.tube_face_spacing, 
          rowSpacing: data.product.tube_row_spacing, 
          // connectionSide: ["Right", "Top"]
        },
        connection: { 
          material: data.product.connection_material.charAt(0).toUpperCase() + data.product.connection_material.slice(1), 
          size: data.performance.SupplyConnectionSize, 
          side: data.product.coil_hand.replace("Hand", ""), 
          type: data.product.connection_type.toUpperCase(),
        },
      },
      performance: {
        air: { 
          airflow: data.product.air_flow, 
          capacity: { 
            total: (data.performance.TotalCapacity / 1000).toFixed(1), // convert to MBH
            sensible: (data.performance.SensibleCapacity / 1000).toFixed(1) // convert to MBH
          }, 
          pressureDrop: data.performance.AirsidePressureDrop.toFixed(2), 
          temp: { 
            entering: { 
              dryBulb: data.product.entering_air_dry_bulb.toFixed(1), 
              wetBulb: data.product.entering_air_wet_bulb.toFixed(1),
            }, 
            leaving: { 
              dryBulb: data.performance.LeavingAirDryBulb.toFixed(1), 
              wetBulb: data.performance.LeavingAirWetBulb.toFixed(1)
            } 
          }, 
          faceVelocity: Math.round(data.performance.FaceVelocity),
        },
        water: { 
          fluid: data.product.fluid,
          temp: { 
            entering: data.product.entering_fluid_temp.toFixed(1), 
            leaving: data.performance.LeavingFluidTemp.toFixed(1)
          }, 
          flowRate: data.performance.GPM.toFixed(2), 
          pressureDrop: data.performance.FluidPressureDrop.toFixed(2), 
          fluidVelocity: data.performance.FluidVelocity.toFixed(2), 
          volume: (data.performance.InternalVolume1 * 7.480519).toFixed(1) // convert to gallons
        }
      },
    },
    options: [
      {
          type: "drain_pan",
          material: data.product.drain_pan_material,
          connectionSide: data.product.drain_pan_connection_side
      }
    ]
  }
}

// TODO: Add ability to handle Combo filter / multiple sizes on daedex-common package
const generateFilterData = ({
    data
  }) => {

    return {
        type: "filter",
        configuration: {
          nominal: {
              filterType: data?.productDetails?.type,
              efficiency: data?.productDetails?.pre_filter_rating.replace(/_/g, ' '),
              size: { 
                  width: data?.selectedCandidate?.filters[0]?.width, // currently commented out in daedex-common
                  height: data?.selectedCandidate?.filters[0]?.height // currently commented out in daedex-common
              },
              loading: data?.productDetails?.loading,
              quantity: data?.selectedCandidate?.filters[0]?.count // currently commented out in daedex-common
          },
          performance: {
              pressureDrop: { 
                  clean: data?.selectedCandidate?.pressureDrop?.clean.toFixed(2), 
                  mean: data?.selectedCandidate?.pressureDrop?.mean.toFixed(2), 
                  dirty: data?.selectedCandidate?.pressureDrop?.dirty.toFixed(2) 
              } 
          },
      },
      options: []
    }
}

const generateDaedexDocx = async ({
    docType,
    coilData,
    filterData,
    jobName,
    products,
    fanFitData,
    sizings
  }: {
    docType: 'Coil' | 'Fan' | 'Filter',
    coilData?: {
      product: any,
      performance: any
    },
    filterData?: any,
    products?: any[],
    jobName?: string,
    fanFitData?: any,
    sizings?: any[]
  }) => {

  let numBlank = 0;
  products?.forEach((p) => {
    if(p.configuration !== undefined && p.configuration === null) numBlank++;
  });

  if(numBlank > 0){
    toast.error("Unable to create submittal. Not all products have a config set.");
    return ;
  }
    
  // console.log('products', products)
  let productElements: any[] = [];

  // if (docType === 'Fan') {
  //   productElements = products?.map((p) => {
  //     return generateFanData({
  //         fanFitData,
  //         sizings,
  //         product: p,
  //     });
  //   });
  // }

  if (docType === 'Coil') {
    productElements = [generateCoilData({ data: coilData})];
  }

  if (docType === 'Filter') {
    productElements = [generateFilterData({ data: filterData})];
  }

  const projectData = {
    project: {
      name: '',
      date: new Date(),
      product: "Daedex",
      quantity: '',
      altitude: '',
      owner: {
          name: "",
          orgName: ""
      }
    },
    unit: {
      tag: '',
      sections: productElements
    }
  };
  // console.log( 'projectData', projectData);
  
  const doc = await generateSubmittalDocx(projectData);
  saveAs(await doc.toBlob(), `Daedex ${docType} Submittal.docx`)
};
  
export default generateDaedexDocx;