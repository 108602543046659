import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Spinner,
} from "reactstrap";
import ConfigurationCard from "./ConfigurationCard";

import {
  Control_Panel_Sizing,
  Product_Set_Input,
  useGetControlPanelSizingQuery,
  useGetCostsQuery,
  useGetFanFitsQuery,
} from "../generated/graphql";

import ExpandedConfigurationDetails from './ExpandedConfigurationDetails';
import configFilters, { shownFilters, ConfigFilter } from '../helpers/config-filters';
import { getPanelAll } from '../helpers/calculate-custom-controls';
import { Knowledgebase, FanSelection, ProductSpec } from "@mashvac/daedex";
import parseMoney from '../helpers/money-utils';

const ConfigurationResults = ({
  product,
  sort,
  order,
  selectedConfigurationId,
  selectedFanCount,
  onSelect,
  expandedDetails,
  setExpandedDetails,
  basicFilters,
  filtersConfig,
  activeFilters,
  viewExpanded,
  setViewExpanded,
  filtersOpen,
  expandedValid,
  setExpandedValid,
  fanConfigIDs,
  fanConfigs,
  panelSizings,
  productLocked,
}: {
  product: Product_Set_Input;
  sort: string;
  order: string;
  selectedConfigurationId: number | null;
  selectedFanCount: number | null;
  onSelect: (model: string | null, id: number | null, price: number | null , fanCount: number | null) => void;
  expandedDetails: {
    fanSelection: FanSelection;
    fanCount: number;
    sizing?: Control_Panel_Sizing
  } | null,
  setExpandedDetails: Function;
  basicFilters: {
    active: boolean;
    amount: number;
    show_infinitum: boolean;
  };
  filtersConfig: ConfigFilter;
  activeFilters: typeof shownFilters;
  viewExpanded: boolean;
  setViewExpanded: Function;
  filtersOpen: boolean;
  expandedValid : boolean;
  setExpandedValid: Function;
  fanConfigIDs: any;
  fanConfigs: any;
  panelSizings: Control_Panel_Sizing[];
  productLocked: boolean;
}) => {
  const [fanSelections, setFanSelections] = useState<FanSelection[]>([]);
  const { data: panelSizing } = useGetControlPanelSizingQuery();
  const { data: fanFitData } = useGetFanFitsQuery();
  const { data: costs } = useGetCostsQuery();
  const [loading, setLoading] = useState<boolean>(true);
  const [streaming, setStreaming] = useState<boolean>(true);
  const [declutteredLoaded, setDeclutteredLoaded] = useState<boolean>(false);

  // UseRef keeps track of latest query TS between react updates so we can cancel old queries
  const latestQueryTS = useRef(0);

  const KB = useMemo(() => new Knowledgebase({debug: false, trace: false}), []);


  useEffect(() => {
    /*
    Strategy:
    Get first 2 of each fan amt in decluttered query, 
    save in own [] and update fanSelections when done
    Get all of each fan amt in all query, 
    skip first 2 of each amt per fan and start adding the rest to fanSelections in groups of 10s when decluttered query is done
    To ensure we don't have any missing fans from race conditions, 
    once both queries finish, 
    replace fanSelections with complete all query results
    */

    // set up configuration
    let selectionConfig : ProductSpec = {
        cfm: product.air_flow,
        tsp: product.total_static_pressure,
        width: product.width,
        height: product.height,
        power: parseInt(product.power),
        altitude: product.altitude,
    };
    if (product.backdraft_dampers) selectionConfig.backdraftDampers = true;
    if (product.over_one_hundred_percent_redundancy) selectionConfig.overHundredRedundancy = true;
    if (product.pressure_transducers) selectionConfig.pressureTransducers = true;
    if (product.mas_ec_plus){
        selectionConfig.masECPlus = true;
        if (product.outdoor_rating) selectionConfig.ECPlusType = "outdoor";
        else selectionConfig.ECPlusType = "indoor";
    } 
    if (product.voltage_override !== 460 && product.voltage_override !== 208) selectionConfig.voltageOverride = product.voltage_override;
    if (product.large_scale_pricing){
        selectionConfig.highVolumePricing = true;
        if (product.discounted_fan_price !== ""){
            selectionConfig.highVolumeFanPrice = parseMoney(product.discounted_fan_price);
        }
    }

    let workingConfigs = [];
    let allConfigs = [];
    let kbErrorsDeclutter = [];
    let kbErrorsAll = [];
    // Save the current TS and update the latest query TS
    let startingTS = Date.now();
    if (startingTS > latestQueryTS.current) latestQueryTS.current = startingTS;

    const queryDaedexDecluttered = async (selectionConfig: ProductSpec) => {
      try {
          
          const selections = KB.fanSelectDecluttered(selectionConfig);
          for await (const element of selections){
            if(startingTS < latestQueryTS.current) break;
            workingConfigs.push(element);
          }
          // If this isn't the most recent query, don't update anything
          if(startingTS < latestQueryTS.current) return;

          setFanSelections(workingConfigs);
          setDeclutteredLoaded(true);
          setLoading(false);
      } catch (error) {
        kbErrorsDeclutter.push(error);
      }
    }

    const queryDaedexComplete = async (selectionConfig: ProductSpec) => {
      let streamingConfigsAddPiecemeal = [];
      try {
          
          const selections = KB.fanSelect(selectionConfig);
          const streamingTarget:number = 20;
          let currentModel:string = "";
          let currentModelElementAmt:number = 0;
          let streamingMsg = "";
          for await (const element of selections){
            // If this isn't the most recent query, don't update anything
           if(startingTS < latestQueryTS.current) break;

            // save all configs so we can set all when queries are done, in case we lost any to race conditions
            allConfigs.push(element);

            // Skip the first 2 results for each model for streaming elements, since we already got those
            if(element.model !== currentModel){
              currentModel = element.model;
              currentModelElementAmt = 0;
            }
            currentModelElementAmt++;
            if(currentModelElementAmt > 2){
              streamingConfigsAddPiecemeal.push(element);
            }
            // Add configs 20 at a time, might dial in a better number later
            // Make sure decluttered results are in first so we don't replace them because of a race condition
            if(streamingConfigsAddPiecemeal.length > streamingTarget && declutteredLoaded){
              workingConfigs.push(...streamingConfigsAddPiecemeal);
              setFanSelections(workingConfigs);
              streamingConfigsAddPiecemeal = [];
              if (streamingMsg.length > 4) streamingMsg = "";
              else streamingMsg += "."
              console.log(`(${startingTS}) Streaming configurations.${streamingMsg}`);
            }
          }
      } catch (error) {
        kbErrorsAll.push(error);
      }
    }

    const declutteredPromise = queryDaedexDecluttered(selectionConfig);
    const completePromise = queryDaedexComplete(selectionConfig);

    // Once both the queries are done, update the fanSelections with the complete data just in case
    Promise.all([declutteredPromise,completePromise]).then(() => {
      if (startingTS < latestQueryTS.current){
        console.log("Stale query cancelled");
      } else {
        console.log("All streaming is complete");
        if(kbErrorsDeclutter.length > 0) console.log("Errors for decluttered configs query",kbErrorsDeclutter);
        if(kbErrorsAll.length > 0) console.log("Errors for all configs query",kbErrorsAll);
        setFanSelections(allConfigs);
        setStreaming(false);
      }
    })

  }, [KB, declutteredLoaded, product, product.air_flow, product.altitude, product.backdraft_dampers, product.discounted_fan_price, product.height, product.large_scale_pricing, product.mas_ec_plus, product.outdoor_rating, product.over_one_hundred_percent_redundancy, product.power, product.pressure_transducers, product.total_static_pressure, product.voltage_override, product.width]);

  const minFansAmt = configFilters.minFansPerModelFS(fanSelections);

  const randNum = Math.floor(Math.random() * 100000);

  if (!viewExpanded){
    if (loading) {
      if(randNum > 0) return <div style={{ marginTop: 30 }}><Spinner>Loading...</Spinner></div>;
      else{
        // This is all spikes' fault.......
        console.log("Congratulations! You saw the secret easter egg! 🥳")
        return <div className="egg-holder">
          <img src={require("../assets/egg.jpg")} className="show-egg" alt=":)"></img>
          <Spinner style={{marginTop: 30}}>Loading...</Spinner>
        </div>;
      } 
    }
    if (!fanSelections.length)
      return <div className="empty">No configurations found.</div>;
    if (!fanFitData?.fan_fit.length)
      return <div className="empty">No fan fits found.</div>;
  } else {
    if (!fanConfigs) return <div style={{ marginTop: 30 }}><Spinner>Loading...</Spinner></div>;
  }

  const expandView = function(input: any){
    setViewExpanded(true);
    setExpandedDetails(input);
  }

  const selectedConfig = selectedConfigurationId ? fanConfigs.find((config) => config.id === selectedConfigurationId) : null;

  return (
    <>
      {viewExpanded && (
        <ExpandedConfigurationDetails
          product={product}
          fanSelection={expandedDetails?.fanSelection}
          fanCount={expandedDetails?.fanCount}
          fanFit={fanFitData?.fan_fit.find(
            (f) => {
              if(expandedDetails?.fanSelection === undefined) return false;
              return f.fan_diameter === expandedDetails?.fanSelection.fan_desc.blade_diameter &&
                      f.manufacturer === expandedDetails?.fanSelection.fan_desc.manufacturer
            }
          )}
          allSizings={panelSizing?.control_panel_sizing}
          costs={costs?.cost ?? []}
          selectedConfigurationId={selectedConfigurationId}
          selectedConfiguration={selectedConfig}
          selectedFanCount={selectedFanCount}
          expandedValid={expandedValid}
          setExpandedValid={setExpandedValid}
          setExpandedDetails={setExpandedDetails}
          expandedDetails={expandedDetails}
          knowledgeBase={KB}
          productLocked={productLocked}
        />
      )}
      {!viewExpanded && (<div className={!filtersOpen ? "configuration-list" : "configuration-list narrow-configurations"}>
        {
        fanSelections
          .filter(configFilters.customFilterFS(filtersOpen, activeFilters, filtersConfig))
          .filter(configFilters.declutterFilterFS(basicFilters.active, basicFilters.show_infinitum, minFansAmt))
          .sort(configFilters.getSortFunction(sort, order))
          .slice(0, basicFilters.active ? 10 : fanSelections.length )
          .map((fs) => (
            <ConfigurationCard
              key={`${fs.model}-${fs.fans.amount}`}
              product={product}
              configId={fanConfigIDs[fs.model]}
              fanSelection={fs}
              controlPanel={getPanelAll(fs.fans.amount,fs.selection_data.mocp,panelSizings,fs.options.includes(""),fs.options.includes("")).sizing}
              selectedConfigurationId={selectedConfigurationId}
              selectedFanCount={selectedFanCount}
              onSelect={onSelect}
              onExpand={expandView}
            />
          ))}
          {streaming && <div className="streaming-loading-div"><Spinner className="streaming-loading-item"></Spinner><div className="streaming-loading-item">Loading more configurations...</div></div>}
      </div>)}
    </>
  );
};

export default ConfigurationResults;