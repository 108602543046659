import { Fan_Fit } from "../generated/graphql";
import { calculateTsp, findDesignCoefficients } from "./fan-curve";
import { Coefficient, RPMCoefficient } from "./file-checker";
import parseMoney from "./money-utils";

const cutoffCoefficients: { [key: string]: Coefficient} = {
  "N88-50701": {
    a: 2.4921e-12,
    b: 5.6495e-7,
    c: 6.5708e-5,
    d: -5.7739e-2
  },
  "N88-56700": {
    a: 2.5035e-12,
    b: 3.7278e-7,
    c: 5.6808e-5,
    d: -4.4526e-2
  },
  "N88-56708": {
    a: 1.422e-11,
    b: 2.7605e-7,
    c: 3.158e-4,
    d: -2.6258e-1
  },
  "500x 150 20-11,19-0": {
    a: -1.475341843e-11,
    b: 7.447453678e-07,
    c: -1.314425092e-04,
    d: 0.044781933,
  },
  "560x 168 20-11,19-0": {
    a: -1.5217752578584e-11,
    b: 6.1259076911348e-07,
    c: -4.9824397177224e-04,
    d: 0.469504895,
  },
  "500x 150 18-7,46-0": {
    a: 1.0623742618939e-09,
    b: -7.3747465523779e-06,
    c: 1.8769060605704e-02,
    d: -12.78166442,
  },
};
export type ProductParams = {
  width: number;
  height: number;
  backdraft_dampers: boolean;
};
export type ConfigurationParams = {
  plate_dimensions: number;
  damper_id?: number;
  manufacturer?: string;
  model?: string;
};
export type FitParams = {
  manufacturer: string;
  fan_diameter: number;
  left_padding: number;
  right_padding: number;
  middle_column_padding: number;
  top_padding: number;
  bottom_padding: number;
  middle_row_padding: number;
};

export const calculateMaximumNumberOfFans = (
  product: ProductParams,
  config: ConfigurationParams,
  fit?: Fan_Fit
) => {
  if (!fit) return 0;
  if (config.plate_dimensions + fit.middle_column_padding === 0) return 0;
  if (config.plate_dimensions + fit.middle_row_padding === 0) return 0;
  const adjusted_plate_dimensions = product.backdraft_dampers
  ? config.damper_id + 3.5
  : config.plate_dimensions;
  const cols = Math.floor(
    (product.width -
      fit.left_padding -
      fit.right_padding +
      fit.middle_column_padding) /
    (adjusted_plate_dimensions + fit.middle_column_padding)
  );
  const rows = Math.floor(
    (product.height -
      fit.top_padding -
      fit.bottom_padding +
      fit.middle_row_padding) /
    (adjusted_plate_dimensions + fit.middle_row_padding)
  );
  return cols * rows;
};

export interface FanArray {
  fanWeight: number;
  fanCount: number;
  panelWeight: number;
  bulkheadWidth: number;
  bulkheadHeight: number;
}

export const calculateFanArrayWeight = (fanArray: FanArray) => {
  const { fanWeight, fanCount, panelWeight, bulkheadHeight, bulkheadWidth } =
    fanArray;

  return (
    fanWeight * fanCount +
    (3.3 * bulkheadHeight * bulkheadWidth) / 144 +
    panelWeight +
    100
  );
};

export const calculateCoefficient = ({
  cfmPerFan,
  rpmCoefficientLB,
  rpmCoefficientUB,
  interpolation,
}: {
  cfmPerFan: number;
  rpmCoefficientLB: Coefficient;
  rpmCoefficientUB: Coefficient;
  interpolation: number;
}) =>
  (((rpmCoefficientUB.a - rpmCoefficientLB.a) * interpolation) + rpmCoefficientLB.a) * Math.pow(cfmPerFan, 3) +
  (((rpmCoefficientUB.b - rpmCoefficientLB.b) * interpolation) + rpmCoefficientLB.b) * Math.pow(cfmPerFan, 2) +
  (((rpmCoefficientUB.c - rpmCoefficientLB.c) * interpolation) + rpmCoefficientLB.c) * cfmPerFan +
  (((rpmCoefficientUB.d - rpmCoefficientLB.d) * interpolation) + rpmCoefficientLB.d);

export enum Frequency {
  HZ_63 = 63,
  HZ_125 = 125,
  HZ_250 = 250,
  HZ_500 = 500,
  HZ_1000 = 1000,
  HZ_2000 = 2000,
  HZ_4000 = 4000,
  HZ_8000 = 8000,
}

const getMinFrequencyValue = (frequency: number) => {
  switch (frequency) {
    case Frequency.HZ_63:
      return 64;
    case Frequency.HZ_125:
      return 54;
    case Frequency.HZ_250:
      return 48;
    case Frequency.HZ_500:
      return 41;
    case Frequency.HZ_1000:
      return 39;
    case Frequency.HZ_2000:
      return 41;
    case Frequency.HZ_4000:
      return 46;
    case Frequency.HZ_8000:
      return 51;
  }

  return 0;
};

const radiatedValues = {
  63: 10,
  125: 10,
  250: 18,
  500: 21,
  1000: 22,
  2000: 28,
  4000: 37,
  8000: 30,
}
const inletCabinetDeduct = {
  63: -2,
  125: -4,
  250: -6,
  500: -6,
  1000: -6,
  2000: -6,
  4000: -6,
  8000: -6,
}
const outletCabinetDeduct = {
  63: -2,
  125: -4,
  250: -6,
  500: -6,
  1000: -6,
  2000: -6,
  4000: -6,
  8000: -6,
}

export const calculateSoundPower = ({
  cfmPerFan,
  frequency,
  inletCoefficentLB,
  outletCoefficentLB,
  inletCoefficentUB,
  outletCoefficentUB,
  fanCount,
  interpolation,
}: {
  cfmPerFan: number;
  fanCount: number;
  frequency: Frequency;
  inletCoefficentLB: Coefficient;
  outletCoefficentLB: Coefficient;
  inletCoefficentUB: Coefficient;
  outletCoefficentUB: Coefficient;
  interpolation: number;
}) => {
  const minValueAtFrequency = getMinFrequencyValue(frequency);

  const unitDischarge =
    Math.round(calculateCoefficient({ cfmPerFan, rpmCoefficientLB: outletCoefficentLB, rpmCoefficientUB: outletCoefficentUB, interpolation }))
    + 10 * Math.log10(fanCount) +
    outletCabinetDeduct[frequency];

  const unitReturn =
    Math.round(calculateCoefficient({ cfmPerFan, rpmCoefficientLB: inletCoefficentLB, rpmCoefficientUB: inletCoefficentUB, interpolation })) +
    10 * Math.log10(fanCount) +
    inletCabinetDeduct[frequency];

  let radiated = 0;

  if (unitDischarge >= unitReturn) radiated = unitDischarge - radiatedValues[frequency];
  else if (unitDischarge < unitReturn)
    radiated = unitDischarge - 10 - (unitDischarge - unitReturn);

  if (radiated <= minValueAtFrequency) radiated = minValueAtFrequency;

  return {
    radiated,
    unitDischarge,
    unitReturn,
  };
};

export const calculateFLA = (
  default_v_fla: number | null = null, 
  voltage_override: number | null = null, 
  min_v_fla: number | null = null, 
  max_v_fla: number | null = null
) => {
  if (voltage_override === null || voltage_override === 460 || voltage_override === 208){
    return default_v_fla;
  } else {
    return calculateInterpolatedFla(min_v_fla, max_v_fla, voltage_override);
  }
}

export const calcVATransformerLoad = (
  mas_ec_plus: boolean,
  fla: number,
  fanCount: number,
  voltage: number
) => {
  let rightVA = null;
  if (mas_ec_plus) rightVA = 100;
  else {
    if ((fla * fanCount)+(fla*.25)+(75/voltage) > 40) rightVA = 250;
    else rightVA = 75;
  }
  return (75 + rightVA)/voltage;
}

export const calculateMCANew = ({
  fla,
  fanCount,
  voltage,
  mas_ec_plus
} : {
  fla: number;
  fanCount: number;
  voltage: number;
  mas_ec_plus: boolean;
}) => 1.25 * fla + (fanCount - 1) * fla + calcVATransformerLoad(mas_ec_plus, fla, fanCount, voltage);

export const calculateMCA = ({
  fla,
  fanCount,
}: {
  fla: number;
  fanCount: number;
}) => 1.25 * fla + (fanCount - 1) * fla;

// Similar to the MCA, the specific voltage and associated FLA must be specified by the user.
// Unlike the MCA, the MOCP must be a specific breaker size.
// Those breaker sizes are: 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 125,
// 150, 175, 200, 225, 250, 300, 350, 400, 450, 500, 600, 700, 800, 1000, 1200, 1600, 2000,
// 2500, 3000, 4000, 5000, and 6000.
// Finally, the rounded MOCP value must be greater than the MCA.
// If the MOCP is not greater than the MCA then the MOCP is bumped up to the next available breaker size.

export const calculateMOCPNew = ({
  fla,
  fanCount,
  voltage,
  mas_ec_plus
}:{
  fla: number;
  fanCount: number;
  voltage: number;
  mas_ec_plus: boolean;
}) => {
  const VATransformerLoad = calcVATransformerLoad(mas_ec_plus, fla, fanCount, voltage);
  const unroundedAmperage = 2.25 * fla + (fanCount - 1) * fla + VATransformerLoad;
  const fuseSizes = [
    15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 125, 150, 175,
    200, 225, 250, 300, 350, 400, 450, 500, 600, 700, 800, 1000, 1200, 1600,
    2000, 2500, 3000, 4000, 5000, 6000,
  ];

  // Find the largest fuse that's smaller than unroundedAmperage
  let fuseIdx = fuseSizes.findIndex((fuseSize) => fuseSize > unroundedAmperage);
  if (fuseIdx !== 0 && fuseIdx !== fuseSizes.length - 1) fuseIdx--;

  // First check, if mca is greater than mocp fuse number, bump it up to next size
  const mca = calculateMCANew({ fla, fanCount, voltage, mas_ec_plus });
  if (fuseSizes[fuseIdx] < mca) fuseIdx++;

  // Second check, if current breaker size is less than some check, bump it up again
  if (fuseSizes[fuseIdx] < (1.15 * (fla * fanCount + VATransformerLoad))) fuseIdx++;

  return fuseSizes[fuseIdx];
}

export const calculateMOCP = ({
  fla,
  fanCount,
}: {
  fla: number;
  fanCount: number;
}) => {
  const unroundedAmperage = 2.25 * fla + (fanCount - 1) * fla;
  const fuseSizes = [
    15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 125, 150, 175,
    200, 225, 250, 300, 350, 400, 450, 500, 600, 700, 800, 1000, 1200, 1600,
    2000, 2500, 3000, 4000, 5000, 6000,
  ];

  let roundedFuseSize = fuseSizes[0];
  for (let i of fuseSizes) {
    if (unroundedAmperage - i > 0) {
      roundedFuseSize = i;
    } else if (roundedFuseSize < i) {
      // our list is ordered, so if the fuse size less than the current size,
      // we have already found the minimum and we can exit the loop
      break;
    }
  }
  const mca = calculateMCA({ fla, fanCount });
  if (roundedFuseSize < mca) {
    const index = fuseSizes.indexOf(roundedFuseSize);
    roundedFuseSize = fuseSizes[index + 1];
  }
  return roundedFuseSize;
};

export const calculateRedundancy = ({
  maxCFM,
  designCFM,
  fanCount,
}: {
  maxCFM: number;
  designCFM: number;
  fanCount: number;
}) => (maxCFM * (fanCount - 1)) / designCFM;

export const getCostBreakdown = ({
  fanCost,
  fanCount,
  controlPanelCost,
  bulkheadHeight,
  bulkheadWidth,
  costPerSQFT,
  miscFlatCost,
  miscVariableCost,
  hasTransducers,
  hasDampers,
  damperCost,
  largeScalePricing,
  discountedFanCost,
}: {
  fanCost: number;
  fanCount: number;
  controlPanelCost: number;
  bulkheadHeight: number;
  bulkheadWidth: number;
  costPerSQFT: number;
  miscFlatCost: number;
  miscVariableCost: number;
  hasTransducers: boolean;
  hasDampers: boolean;
  damperCost: number;
  largeScalePricing: boolean;
  discountedFanCost: number | null;
}) => {
  if(largeScalePricing){
    if (typeof discountedFanCost === "string"){
      if(discountedFanCost === "") discountedFanCost = null;
      else discountedFanCost = parseMoney(discountedFanCost);
    } 
  }
  const totalFanCost = (largeScalePricing && discountedFanCost !== undefined && discountedFanCost !== null) ? discountedFanCost * fanCount : fanCost * fanCount;
  const originalFanCost = fanCost * fanCount;
  const squareInchesInSquareFoot = 144;
  const bulkheadCost =
    ((bulkheadHeight * bulkheadWidth) / squareInchesInSquareFoot) * costPerSQFT;
  const miscCost = miscFlatCost + miscVariableCost * (fanCount - 1);
  const optionsCost = (hasDampers ? (damperCost * fanCount) : 0) + (hasTransducers ? 250 + 50 * fanCount : 0);
  // const transducerCost = hasTransducers ? 250 + 50 * fanCount : 0;
  const freightFanCost = largeScalePricing? 50 * fanCount : 100 * fanCount;
  const freightControlPanelCost = largeScalePricing? 25 : 75;
  // const totalCost = Math.ceil(totalFanCost + bulkheadCost + controlPanelCost + miscCost + optionsCost + transducerCost + freightControlPanelCost + freightFanCost);
  const totalCost = Math.ceil(totalFanCost + bulkheadCost + controlPanelCost + miscCost + optionsCost + freightControlPanelCost + freightFanCost);
  const undiscountedTotalCost = Math.ceil(originalFanCost + bulkheadCost + controlPanelCost + miscCost + optionsCost + (100 * fanCount) + 75);

  return {
    totalFanCost,
    bulkheadCost,
    controlPanelCost,
    miscCost,
    optionsCost,
    // transducerCost,
    freightControlPanelCost,
    freightFanCost,
    totalCost,
    originalFanCost,
    largeScalePricing,
    discountedFanCost,
    undiscountedTotalCost
  }
};

export const calculateProductCost = (productDetails: {
  fanCost: number;
  fanCount: number;
  controlPanelCost: number;
  bulkheadHeight: number;
  bulkheadWidth: number;
  costPerSQFT: number;
  miscFlatCost: number;
  miscVariableCost: number;
  hasTransducers: boolean;
  hasDampers: boolean;
  damperCost: number;
  largeScalePricing: boolean;
  discountedFanCost: number;
}) => {
  return getCostBreakdown(productDetails).totalCost;
};

export const calculateOperatingInputHorserpower = (
  cfm: number,
  fanCount: number,
  powerCoefficientLB: Coefficient,
  powerCoefficientUB: Coefficient,
  interpolation: number
) => {
  const hp =
    (((powerCoefficientUB.a - powerCoefficientLB.a) * interpolation) + powerCoefficientLB.a) * Math.pow(cfm / fanCount, 3) +
    (((powerCoefficientUB.b - powerCoefficientLB.b) * interpolation) + powerCoefficientLB.b) * Math.pow(cfm / fanCount, 2) +
    (((powerCoefficientUB.c - powerCoefficientLB.c) * interpolation) + powerCoefficientLB.c) * (cfm / fanCount) +
    (((powerCoefficientUB.d - powerCoefficientLB.d) * interpolation) + powerCoefficientLB.d);
  return hp;
};

export const calculateEfficiency = ({
  tsp,
  cfm,
  horsepower,
  fanCount,
}: {
  tsp: number;
  cfm: number;
  horsepower: number;
  fanCount: number;
}) => {
  const spaceAndPressure = tsp * 248.84 * cfm * 0.0004719;
  const power = horsepower * 0.7457 * 1000 * fanCount;

  return spaceAndPressure / power;
};

export const calculateOperatingRPM = ({
  RPMUpperBound, 
  RPMLowerBound, 
  Interpolation,
} : {
  RPMUpperBound: number, 
  RPMLowerBound: number, 
  Interpolation: number,
}) => {
  if (RPMUpperBound === 0) return 0;
  return Math.round((RPMUpperBound - RPMLowerBound) * Interpolation + RPMLowerBound);
}

export type RPMSoundPower = {
  hz63: SoundPowerData;
  hz125: SoundPowerData;
  hz250: SoundPowerData;
  hz500: SoundPowerData;
  hz1000: SoundPowerData;
  hz2000: SoundPowerData;
  hz4000: SoundPowerData;
  hz8000: SoundPowerData;
};

export type SoundPowerData = {
  radiated: number;
  unitDischarge: number;
  unitReturn: number;
};

export const calculateRPMSoundPower = (
  fanCount: number,
  cfmPerFan: number,
  rpmCoefficientLB: RPMCoefficient,
  rpmCoefficientUB: RPMCoefficient,
  interpolation: number
): RPMSoundPower => ({
  hz63: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_63,
    inletCoefficentLB: rpmCoefficientLB.inletHz_63,
    inletCoefficentUB: rpmCoefficientUB.inletHz_63,
    outletCoefficentLB: rpmCoefficientLB.outletHz_63,
    outletCoefficentUB: rpmCoefficientUB.outletHz_63,
    interpolation,
  }),
  hz125: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_125,
    inletCoefficentLB: rpmCoefficientLB.inletHz_125,
    inletCoefficentUB: rpmCoefficientUB.inletHz_125,
    outletCoefficentLB: rpmCoefficientLB.outletHz_125,
    outletCoefficentUB: rpmCoefficientUB.outletHz_125,
    interpolation,
  }),
  hz250: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_250,
    inletCoefficentLB: rpmCoefficientLB.inletHz_250,
    inletCoefficentUB: rpmCoefficientUB.inletHz_250,
    outletCoefficentLB: rpmCoefficientLB.outletHz_250,
    outletCoefficentUB: rpmCoefficientUB.outletHz_250,
    interpolation,
  }),
  hz500: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_500,
    inletCoefficentLB: rpmCoefficientLB.inletHz_500,
    inletCoefficentUB: rpmCoefficientUB.inletHz_500,
    outletCoefficentLB: rpmCoefficientLB.outletHz_500,
    outletCoefficentUB: rpmCoefficientUB.outletHz_500,
    interpolation,
  }),
  hz1000: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_1000,
    inletCoefficentLB: rpmCoefficientLB.inletHz_1000,
    inletCoefficentUB: rpmCoefficientUB.inletHz_1000,
    outletCoefficentLB: rpmCoefficientLB.outletHz_1000,
    outletCoefficentUB: rpmCoefficientUB.outletHz_1000,
    interpolation,
  }),
  hz2000: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_2000,
    inletCoefficentLB: rpmCoefficientLB.inletHz_2000,
    inletCoefficentUB: rpmCoefficientUB.inletHz_2000,
    outletCoefficentLB: rpmCoefficientLB.outletHz_2000,
    outletCoefficentUB: rpmCoefficientUB.outletHz_2000,
    interpolation,
  }),
  hz4000: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_4000,
    inletCoefficentLB: rpmCoefficientLB.inletHz_4000,
    inletCoefficentUB: rpmCoefficientUB.inletHz_4000,
    outletCoefficentLB: rpmCoefficientLB.outletHz_4000,
    outletCoefficentUB: rpmCoefficientUB.outletHz_4000,
    interpolation,
  }),
  hz8000: calculateSoundPower({
    fanCount,
    cfmPerFan,
    frequency: Frequency.HZ_8000,
    inletCoefficentLB: rpmCoefficientLB.inletHz_8000,
    inletCoefficentUB: rpmCoefficientUB.inletHz_8000,
    outletCoefficentLB: rpmCoefficientLB.outletHz_8000,
    outletCoefficentUB: rpmCoefficientUB.outletHz_8000,
    interpolation,
  }),
});

// The design information for the maximum number of fans available to fit
// in the given space has been found. Now we must calculate the design
// information for each configurable array until a configuration will not
// operate at the given conditions. The steps shown in the first iteration
// of finding the design coefficients are repeated for each quantity of fans
// until that calculated TSP at the maximum RPM (aka the (TSPUB)=Possible Check)
// is less than the design TSP.
export const iterateFanCount = (
  cfm: number,
  designTsp: number,
  fanCount: number,
  coefficients: RPMCoefficient[],
  model: string
) => {
  const designs = [];
  const cutoff: Coefficient = cutoffCoefficients[model];
  for (let i = fanCount; i > 0; i--) {
    if (cutoff) {
      const {a, b, c, d} = cutoff;
      const cutoffTSP = calculateTsp({
        cfm,
        fanCount: i,
        coefficient: { a, b, c, d }
      });
      if (cutoffTSP < designTsp)
        continue;
    }
    const design = findDesignCoefficients({
      cfm,
      tsp: designTsp,
      fanCount: i,
      coefficients,
      model
    });

    if (!design || (design.upper.tsp && design.upper.tsp < designTsp)) {
      break;
    }
    design.fanCount = i;
    designs.push(design);
  }
  return designs;
};

export const calculateSoundPowerDischargeSum = (freq?: RPMSoundPower) => {
  if (!freq) {
    return 0;
  }
  return (
    10 *
    Math.log10(
      Math.pow(10, freq.hz63.unitDischarge / 10) +
      Math.pow(10, freq.hz125.unitDischarge / 10) +
      Math.pow(10, freq.hz250.unitDischarge / 10) +
      Math.pow(10, freq.hz500.unitDischarge / 10) +
      Math.pow(10, freq.hz1000.unitDischarge / 10) +
      Math.pow(10, freq.hz2000.unitDischarge / 10) +
      Math.pow(10, freq.hz4000.unitDischarge / 10) +
      Math.pow(10, freq.hz8000.unitDischarge / 10)
    )
  );
};

export const calculateSoundPowerReturnSum = (freq?: RPMSoundPower) => {
  if (!freq) {
    return 0;
  }
  return (
    10 *
    Math.log10(
      Math.pow(10, freq.hz63.unitReturn / 10) +
      Math.pow(10, freq.hz125.unitReturn / 10) +
      Math.pow(10, freq.hz250.unitReturn / 10) +
      Math.pow(10, freq.hz500.unitReturn / 10) +
      Math.pow(10, freq.hz1000.unitReturn / 10) +
      Math.pow(10, freq.hz2000.unitReturn / 10) +
      Math.pow(10, freq.hz4000.unitReturn / 10) +
      Math.pow(10, freq.hz8000.unitReturn / 10)
    )
  );
};

export const calculateInterpolatedFla = (
  minVfla: number,
  maxVfla: number,
  voltage: number
) => {
  let minV = 0;
  let maxV = 0;
  if (voltage >= 200 && voltage <= 240){
    minV = 200;
    maxV = 240;
  } else if (voltage >= 380 && voltage <= 480){
    minV = 380;
    maxV = 480;
  } else {
    return -1;
  }
  return ((maxV - voltage)*minVfla + (voltage - minV)*maxVfla)/(maxV-minV);
};

export const calculateAltitudeCorrectionFactor = (
  altitude: number
) => {
  if (altitude === null || isNaN(altitude) || altitude === 0 || altitude === undefined) return 1;
  let altitudeRatio = null;
  let minLerp = null;
  let maxLerp = null;
  if (altitude < 4999.5){
    altitudeRatio = altitude/5000;
    minLerp = 1;
    maxLerp = 1.2;
  } else {
    altitudeRatio = (altitude - 5000)/5000;
    minLerp = 1.2;
    maxLerp = 1.45;
  }
  return minLerp + altitudeRatio * (maxLerp - minLerp);
}

const generateConfigurationQuery = {
  calculateFanArrayWeight,
  calculateMaximumNumberOfFans,
  calculateCoefficient,
  calculateSoundPower,
  calculateRPMSoundPower,
  calculateFLA,
  calculateMCA,
  calculateMOCP,
  calculateMCANew,
  calculateMOCPNew,
  calculateRedundancy,
  calculateProductCost,
  calculateEfficiency,
  calculateOperatingRPM,
  iterateFanCount,
};

export default generateConfigurationQuery;
