import { Configuration, Control_Panel_Sizing } from "../generated/graphql";
import { DesignParameters } from "./fan-curve";

export interface Coefficient {
  a: number;
  b: number;
  c: number;
  d: number;
}

export interface RPMCoefficient {
  RPM: number;
  pressure: Coefficient;
  powerHp: Coefficient;
  inletHz_63: Coefficient;
  inletHz_125: Coefficient;
  inletHz_250: Coefficient;
  inletHz_500: Coefficient;
  inletHz_1000: Coefficient;
  inletHz_2000: Coefficient;
  inletHz_4000: Coefficient;
  inletHz_8000: Coefficient;
  outletHz_63: Coefficient;
  outletHz_125: Coefficient;
  outletHz_250: Coefficient;
  outletHz_500: Coefficient;
  outletHz_1000: Coefficient;
  outletHz_2000: Coefficient;
  outletHz_4000: Coefficient;
  outletHz_8000: Coefficient;
}

export const JSObjectToRPMCoefficient = (parsedJSON: object, rpm: number) => {
  const newRPMCoefficients: RPMCoefficient = {
      RPM: rpm,
      pressure: {
          a: parsedJSON[rpm].pressure.a,
          b: parsedJSON[rpm].pressure.b,
          c: parsedJSON[rpm].pressure.c,
          d: parsedJSON[rpm].pressure.d
        } as Coefficient,
      powerHp: {
          a: parsedJSON[rpm].powerHp.a,
          b: parsedJSON[rpm].powerHp.b,
          c: parsedJSON[rpm].powerHp.c,
          d: parsedJSON[rpm].powerHp.d
        } as Coefficient,
      inletHz_63: {
          a: parsedJSON[rpm].inletHz_63.a,
          b: parsedJSON[rpm].inletHz_63.b,
          c: parsedJSON[rpm].inletHz_63.c,
          d: parsedJSON[rpm].inletHz_63.d
        } as Coefficient,
      inletHz_125: {
          a: parsedJSON[rpm].inletHz_125.a,
          b: parsedJSON[rpm].inletHz_125.b,
          c: parsedJSON[rpm].inletHz_125.c,
          d: parsedJSON[rpm].inletHz_125.d
        } as Coefficient,
      inletHz_250: {
          a: parsedJSON[rpm].inletHz_250.a,
          b: parsedJSON[rpm].inletHz_250.b,
          c: parsedJSON[rpm].inletHz_250.c,
          d: parsedJSON[rpm].inletHz_250.d
        } as Coefficient,
      inletHz_500: {
          a: parsedJSON[rpm].inletHz_500.a,
          b: parsedJSON[rpm].inletHz_500.b,
          c: parsedJSON[rpm].inletHz_500.c,
          d: parsedJSON[rpm].inletHz_500.d
        } as Coefficient,
      inletHz_1000: {
          a: parsedJSON[rpm].inletHz_1000.a,
          b: parsedJSON[rpm].inletHz_1000.b,
          c: parsedJSON[rpm].inletHz_1000.c,
          d: parsedJSON[rpm].inletHz_1000.d
        } as Coefficient,
      inletHz_2000: {
          a: parsedJSON[rpm].inletHz_2000.a,
          b: parsedJSON[rpm].inletHz_2000.b,
          c: parsedJSON[rpm].inletHz_2000.c,
          d: parsedJSON[rpm].inletHz_2000.d
        } as Coefficient,
      inletHz_4000: {
          a: parsedJSON[rpm].inletHz_4000.a,
          b: parsedJSON[rpm].inletHz_4000.b,
          c: parsedJSON[rpm].inletHz_4000.c,
          d: parsedJSON[rpm].inletHz_4000.d
        } as Coefficient,
      inletHz_8000: {
          a: parsedJSON[rpm].inletHz_8000.a,
          b: parsedJSON[rpm].inletHz_8000.b,
          c: parsedJSON[rpm].inletHz_8000.c,
          d: parsedJSON[rpm].inletHz_8000.d
        } as Coefficient,
      outletHz_63: {
          a: parsedJSON[rpm].outletHz_63.a,
          b: parsedJSON[rpm].outletHz_63.b,
          c: parsedJSON[rpm].outletHz_63.c,
          d: parsedJSON[rpm].outletHz_63.d
        } as Coefficient,
      outletHz_125: {
          a: parsedJSON[rpm].outletHz_125.a,
          b: parsedJSON[rpm].outletHz_125.b,
          c: parsedJSON[rpm].outletHz_125.c,
          d: parsedJSON[rpm].outletHz_125.d
        } as Coefficient,
      outletHz_250: {
          a: parsedJSON[rpm].outletHz_250.a,
          b: parsedJSON[rpm].outletHz_250.b,
          c: parsedJSON[rpm].outletHz_250.c,
          d: parsedJSON[rpm].outletHz_250.d
        } as Coefficient,
      outletHz_500: {
          a: parsedJSON[rpm].outletHz_500.a,
          b: parsedJSON[rpm].outletHz_500.b,
          c: parsedJSON[rpm].outletHz_500.c,
          d: parsedJSON[rpm].outletHz_500.d
        } as Coefficient,
      outletHz_1000: {
          a: parsedJSON[rpm].outletHz_1000.a,
          b: parsedJSON[rpm].outletHz_1000.b,
          c: parsedJSON[rpm].outletHz_1000.c,
          d: parsedJSON[rpm].outletHz_1000.d
        } as Coefficient,
      outletHz_2000: {
          a: parsedJSON[rpm].outletHz_2000.a,
          b: parsedJSON[rpm].outletHz_2000.b,
          c: parsedJSON[rpm].outletHz_2000.c,
          d: parsedJSON[rpm].outletHz_2000.d
        } as Coefficient,
      outletHz_4000: {
          a: parsedJSON[rpm].outletHz_4000.a,
          b: parsedJSON[rpm].outletHz_4000.b,
          c: parsedJSON[rpm].outletHz_4000.c,
          d: parsedJSON[rpm].outletHz_4000.d
        } as Coefficient,
      outletHz_8000: {
          a: parsedJSON[rpm].outletHz_8000.a,
          b: parsedJSON[rpm].outletHz_8000.b,
          c: parsedJSON[rpm].outletHz_8000.c,
          d: parsedJSON[rpm].outletHz_8000.d
        } as Coefficient,
  };
  return newRPMCoefficients;
}

interface Config {
  configuration: Configuration,
  designIteration: DesignParameters,
  fla: number,
  mca: number,
  mocp: number,
  inputPower: number,
  efficiency: number,
  redundancy: number,
  inletSound: number,
  outletSound: number,
  sizing?: Control_Panel_Sizing | undefined,
  unitCost?: number,
  arrayWeight?: number,
}

export const fanSelectionToConfig = (foundSelection: any) => {
  let foundSizing: Control_Panel_Sizing = {
      cost: foundSelection.cost_breakdown.controls_cost,
      depth: foundSelection.selection_data.control_panel_depth,
      fan_count: foundSelection.fans.amount,
      height: foundSelection.selection_data.control_panel_height,
      weight: foundSelection.selection_data.control_panel_weight,
      width: foundSelection.selection_data.control_panel_width,
      // Shouldn't need these next two:
      amperage: null,
      id: null,
  }

  let foundConfiguration: Configuration = {
      blade_diameter: foundSelection.fan_desc.blade_diameter,
      blade_material: foundSelection.fan_desc.blade_material,
      damper_id: foundSelection.fan_desc.damper_id,
      depth: foundSelection.fan_desc.depth,
      fan_weight: foundSelection.fan_desc.weight,
      fla_460: foundSelection.fan_desc.fla_460,
      manufacturer: foundSelection.fan_desc.manufacturer,
      max_rpm: foundSelection.fan_desc.max_rpm,
      max_v_fla: foundSelection.fan_desc.max_v_fla,
      min_v_fla: foundSelection.fan_desc.min_v_fla,
      model: foundSelection.fan_desc.model,
      nominal_hp: foundSelection.fan_desc.nominal_hp,
      plate_dimensions: foundSelection.fan_desc.plate_dimensions,
      price: foundSelection.fan_desc.cost,
      // rmp_coefficients: JSON.stringify(foundSelection.fan_desc.rpm_coefficients),
      rmp_coefficients: null, // Try to make tools config work without this
      voltage: foundSelection.fan_desc.voltage,
      // shouldn't need any of these:
      fla_400: null,
      has_changed: null,
      id: null,
      products: null,
      products_aggregate: null,
  }

  let foundDI: DesignParameters = {
      fanCount: foundSelection.fans.amount,
      upper: {
          tsp: foundSelection.selection_data.upper_bound_tsp,
          rpm: foundSelection.selection_data.upper_bound_rpm,
          coefficient: JSObjectToRPMCoefficient(foundSelection.fan_desc.rpm_coefficients,foundSelection.selection_data.upper_bound_rpm),
      },
      lower: {
          tsp: foundSelection.selection_data.lower_bound_tsp,
          rpm: foundSelection.selection_data.lower_bound_rpm,
          coefficient: JSObjectToRPMCoefficient(foundSelection.fan_desc.rpm_coefficients,foundSelection.selection_data.lower_bound_rpm),
      },
      coefficient: {
            a: foundSelection.selection_data.interpolated_coefficients_a,
            b: foundSelection.selection_data.interpolated_coefficients_b,
            c: foundSelection.selection_data.interpolated_coefficients_c,
            d: foundSelection.selection_data.interpolated_coefficients_d
          } as Coefficient,
      interpolation: foundSelection.selection_data.interpolation,
  }

  let foundConfig: Config = {
      configuration: foundConfiguration, // TYPE
      designIteration: foundDI, // TYPE
      fla: foundSelection.selection_data.fla,
      mca: foundSelection.selection_data.mca,
      mocp: foundSelection.selection_data.mocp,
      inputPower: foundSelection.selection_data.horsepower,
      efficiency: foundSelection.selection_data.efficiency,
      redundancy: foundSelection.selection_data.redundancy,
      inletSound: foundSelection.selection_data.return_sum,
      outletSound: foundSelection.selection_data.discharge_sum,
      sizing: foundSizing, // TYPE
      unitCost: foundSelection.selection_data.total_cost,
      arrayWeight: foundSelection.selection_data.array_weight
  };

  return foundConfig;
}

export interface GeneralFan {
  Model: string;
  Manufacturer: string;
  Blade_Material: string;
  Cost: number;
  Fan_Weight: number;
  Nominal_HP: number;
  Max_RPM: number;
  Voltage: number;
  "460 FLA": number; // Is this present in every fan?
  Plate_Dimensions: number;
  Depth: number;
  Blade_Diameter: number;
  Damper_ID: number;
  K_Factor: number;
  Max_V_FLA: number;
  Min_V_FLA: number;
  RPM_Coefficients: {
    // No idea if each of these are present in every fan
    915?: RPMCoefficient;
    1391?: RPMCoefficient;
    1629?: RPMCoefficient;
    1869?: RPMCoefficient;
    2105?: RPMCoefficient;
    2225?: RPMCoefficient;
  };
}

export interface GeneralFanData {
  fans: GeneralFan[];
}

export interface Padding {
  Left_Padding: number;
  Right_Padding: number;
  Middle_Column_Padding: number;
  Top_Padding: number;
  Bottom_Padding: number;
  Middle_Row_Padding: number;
}

export interface FanFit {
  Manufacturer: string;
  Fan_Diameter: {
    315: Padding;
    350: Padding;
    400: Padding;
    450: Padding;
    500: Padding;
    560: Padding;
    630: Padding;
  };
}

export interface FanFitData {
  Fan_Fit: FanFit[];
}

// export interface ControlPanelData {
//   "Control Panel Sizing": ControlPanelSizing;
// }

// export interface ControlPanelSizing {
//   "1 Fan": FanArray;
//   "2 Fan": FanArray;
//   "3 Fan": FanArray;
//   "4 Fan": FanArray;
//   "5 Fan": FanArray;
//   "6 Fan": FanArray;
//   "7 Fan": FanArray;
//   "8 Fan": FanArray;
//   "9 Fan": FanArray;
//   "10 Fan": FanArray;
//   "11 Fan": FanArray;
//   "12 Fan": FanArray;
//   "13 Fan": FanArray;
// }

export interface FanArray {
  Cost: number;
  "15A": FanSizing;
  "20A": FanSizing;
  "25A": FanSizing;
  "30A": FanSizing;
  "35A": FanSizing;
  "40A": FanSizing;
  "45A": FanSizing;
  "50A": FanSizing;
  "60A": FanSizing;
  "70A": FanSizing;
  "80A": FanSizing;
  "90A": FanSizing;
  "100A": FanSizing;
  "110A": FanSizing;
  "125A": FanSizing;
}

export interface FanSizing {
  Height: number;
  Width: number;
  Depth: number;
}

const isGeneralFanData = (data: Object): data is GeneralFanData =>
  !!data.hasOwnProperty("fans");

// TODO: How many fields do we need to check to make sure this is general fan data
const isGeneralFan = (data: Object): data is GeneralFan =>
  !!data.hasOwnProperty("Model") &&
  !!data.hasOwnProperty("Manufacturer") &&
  !!data.hasOwnProperty("RPM_Coefficients");

const isFanFitData = (data: Object): data is FanFitData =>
  !!data.hasOwnProperty("Fan_Fit");

const isFanFit = (data: Object): data is FanFit =>
  !!data.hasOwnProperty("Manufacturer") &&
  !!data.hasOwnProperty("Fan_Diameter");

const isControlPanelData = (data: Object) =>
  !!data.hasOwnProperty("Control Panel Sizing");

const isControlPanelSizing = (data: any) =>
  !!data.hasOwnProperty("1 Fan") &&
  !!data.hasOwnProperty("2 Fan") &&
  !!data.hasOwnProperty("3 Fan") &&
  !!data.hasOwnProperty("4 Fan") &&
  !!data.hasOwnProperty("5 Fan") &&
  !!data.hasOwnProperty("6 Fan") &&
  !!data.hasOwnProperty("7 Fan") &&
  !!data.hasOwnProperty("8 Fan") &&
  !!data.hasOwnProperty("9 Fan") &&
  !!data.hasOwnProperty("10 Fan") &&
  !!data.hasOwnProperty("11 Fan") &&
  !!data.hasOwnProperty("12 Fan") &&
  !!data.hasOwnProperty("13 Fan");

const isFanArray = (data: any) =>
  !!data.hasOwnProperty("Cost") &&
  !!data.hasOwnProperty("15A") &&
  !!data.hasOwnProperty("20A") &&
  !!data.hasOwnProperty("25A") &&
  !!data.hasOwnProperty("30A") &&
  !!data.hasOwnProperty("35A") &&
  !!data.hasOwnProperty("40A") &&
  !!data.hasOwnProperty("45A") &&
  !!data.hasOwnProperty("50A") &&
  !!data.hasOwnProperty("60A") &&
  !!data.hasOwnProperty("70A") &&
  !!data.hasOwnProperty("80A") &&
  !!data.hasOwnProperty("90A") &&
  !!data.hasOwnProperty("100A") &&
  !!data.hasOwnProperty("110A") &&
  !!data.hasOwnProperty("125A");

const isFanSizing = (data: any) =>
  typeof data === "number" ||
  (
    !!data.hasOwnProperty("Height") &&
    !!data.hasOwnProperty("Width") &&
    !!data.hasOwnProperty("Depth") &&
    !!data.hasOwnProperty("Weight")
  )

export const isValidGeneralFile = (file: any) => {
  const json: GeneralFanData = JSON.parse(file);

  // Must provide fans
  if (!isGeneralFanData(json) || !json.fans.length) return false;

  let areFansValid = true;

  // are all fans valid
  for (let fan of json.fans) {
    const validGeneralFan = isGeneralFan(fan);

    if (!validGeneralFan) {
      areFansValid = false;
      break;
    }
  }

  return areFansValid;
};

export const isValidFanFitFile = (file: any) => {
  const json: FanFitData = JSON.parse(file);

  // Must provide fans
  if (!isFanFitData(json) || !json.Fan_Fit.length) return false;

  let areFansValid = true;

  // are all fans valid
  for (let fan of json.Fan_Fit) {
    const validGeneralFan = isFanFit(fan);

    if (!validGeneralFan) {
      areFansValid = false;
      break;
    }
  }

  return areFansValid;
};

export const isValidControlPanelFile = (file: any) => {
  // const json: ControlPanelData = JSON.parse(file);
  const json = JSON.parse(file);
  if (!isControlPanelData(json)) return false;
  for (let panelsize of Object.values(json)) {
    if (!isControlPanelSizing(panelsize)) return false;
    for (let fanArray of Object.values(panelsize as Object)) {
      if (!isFanArray(fanArray)) return false;
      for (let fan of Object.values(fanArray)) {
        if (!isFanSizing(fan)) return false;
      }
    }
  }
  return true;
};

export interface CostData {
  [name: string]: number;
}

export const isValidCostFile = (file: any) => {
  const json: CostData = JSON.parse(file);
  for (let [k, v] of Object.entries(json)){
    if (typeof k !== "string") return false;
    if (typeof v !== "number") return false;
  }
  return true;
};

const fileChecker = {
  isValidFanFitFile,
  isValidGeneralFile,
  isValidControlPanelFile,
  isValidCostFile,
};

export default fileChecker;
