import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

import { Control_Panel_Sizing, Cost, Quote } from "../generated/graphql";
import { formatDate } from "./date-utils";
import parseMoney, { formatMoney } from "./money-utils";
import { calculateFLA, calculateMOCPNew, getCostBreakdown } from "./generate-configuration-query";

import masLogo from '../assets/mas-logo.png';
import { quoteSumTotal } from "./quote";
import { getPanelCost } from "./calculate-custom-controls";

export const generateQuotePDF = ({
  jobName,
  user,
  quote,
  costs,
  sizings,
}: {
  jobName: string,
  user: { given_name?: string, family_name?: string, email?: string } | undefined,
  quote: Quote,
  costs: Cost[] | undefined,
  sizings: Control_Panel_Sizing[] | undefined,
}) => {
  const doc = new jsPDF();

  doc.addImage(masLogo, "PNG", 125, 0, 80, 40);

  doc.setFontSize(14);
  doc.text(`Project: ${jobName}`, 10, 19);
  doc.setFontSize(10);
  doc.text(`Date: ${formatDate(new Date().toUTCString())}`, 10, 29);

  doc.setFont('helvetica', 'bold');
  doc.text(quote.name, 10, 50);

  let anyDiscountedPrice = false;

  const products = quote.products.map((p, i) => {
    const quantity = p?.configuration_quantity ?? 1;
    const configuration = p.configuration!;

    // Calculate controls cost 
    const fla = calculateFLA(p.configuration.fla_460, p.voltage_override, p.configuration.min_v_fla, p.configuration.max_v_fla);
    const productMOCP = calculateMOCPNew({
      fla: fla,
      fanCount: p.configured_fan_count ?? 1,
      voltage: p.voltage_override ?? p.configuration.voltage,
      mas_ec_plus: p.mas_ec_plus,
    });
    const controlsCost = getPanelCost(p.configured_fan_count, productMOCP, sizings, p.mas_ec_plus, p.outdoor_rating);

    if (p.large_scale_pricing) anyDiscountedPrice = true;

    const costBreakdown = getCostBreakdown({
      fanCost: parseMoney(p.configuration?.price) ?? 0,
      fanCount: p.configured_fan_count ?? 1,
      // controlPanelCost: sizing?.cost ?? 0, // TODO: fix this as well
      controlPanelCost: controlsCost,
      bulkheadHeight: p.height ?? 0,
      bulkheadWidth: p.width ?? 0,
      costPerSQFT:
        parseMoney(
          (costs ?? []).find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value
        ) ?? 0,
      miscFlatCost:
        parseMoney((costs ?? []).find((c) => c.name === "Misc_Flat")?.value) ?? 0,
      miscVariableCost:
        parseMoney((costs ?? []).find((c) => c.name === "Misc_Variable:")?.value) ??
        0,
      damperCost:
        parseMoney((costs ?? []).find((c) => c.name === "Backdraft_Damper")?.value) ??
        0,
      hasDampers: p.backdraft_dampers ?? false,
      hasTransducers: p.pressure_transducers ?? false,
      largeScalePricing: p.large_scale_pricing,
      discountedFanCost: p.discounted_fan_price,
    });

    return ({
      index: i + 1,
      description: `${p.tag}: ${p.configured_fan_count} Fans, ${configuration?.model}, ${Math.round(p.air_flow ?? 0)} CFM,\n ${p.total_static_pressure} inwc, ${p.width}" W x ${p.height}" H , ${p.mas_ec_plus ? "MAS EC+ (BACnet)" : "MAS EC"}`,
      quantity,
      price: p.configuration?.price,
      unitPrice: formatMoney(costBreakdown.totalCost),
      total: formatMoney(quantity * costBreakdown.totalCost),
      originalPrice: p.large_scale_pricing ? formatMoney(costBreakdown.undiscountedTotalCost) : "",
    });
  });

  const totalPrice = quoteSumTotal(
    quote.products,
    sizings,
    costs
  );

  if (!anyDiscountedPrice){
      autoTable(doc, {
        startY: 53,
        margin: { top: 5, left: 10 },
        theme: 'plain',
        showHead: 'firstPage',
        showFoot: 'lastPage',
        headStyles: {
          fillColor: '#2D90FF',
          textColor: '#fff',
        },
        bodyStyles: {
          fillColor: '#fff',
          textColor: '#000',
          lineColor: '#2D90FF',
          lineWidth: {
            bottom: 0.2
          }
        },
        footStyles: {
          fillColor: '#fff',
          textColor: '#000',
          lineColor: '#2D90FF',
        },
        columnStyles: {
          0: {
            lineColor: '#2D90FF',
            lineWidth: {
              left: 0.2,
              bottom: 0.2
            }
          },
          4: {
            lineColor: '#2D90FF',
            lineWidth: {
              right: 0.2,
              bottom: 0.2
            }
          },
        },
        columns: [
          { header: 'Item', dataKey: 'index' },
          { header: 'Description', dataKey: 'description' },
          { header: 'Quantity', dataKey: 'quantity' },
          { header: 'Array Price', dataKey: 'unitPrice' },
          { header: 'Price Total', dataKey: 'total' },
        ],
        body: products,
        foot: [
          [
            {
              content: ' ',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  left: 0.2,
                  bottom: 0.2
                }
              }
            },
            {
              content: ' ',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  bottom: 0.2
                }
              }
            },
            {
              content: ' ',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  bottom: 0.2
                }
              }
            },
            {
              content: 'Total',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  bottom: 0.2
                }
              }
            },
            {
              content: totalPrice,
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  right: 0.2,
                  bottom: 0.2
                }
              }
            },
          ]
        ],
      });
  } else {
    autoTable(doc, {
      startY: 53,
      margin: { top: 5, left: 10 },
      theme: 'plain',
      showHead: 'firstPage',
      showFoot: 'lastPage',
      headStyles: {
        fillColor: '#2D90FF',
        textColor: '#fff',
      },
      bodyStyles: {
        fillColor: '#fff',
        textColor: '#000',
        lineColor: '#2D90FF',
        lineWidth: {
          bottom: 0.2
        }
      },
      footStyles: {
        fillColor: '#fff',
        textColor: '#000',
        lineColor: '#2D90FF',
      },
      columnStyles: {
        0: {
          lineColor: '#2D90FF',
          lineWidth: {
            left: 0.2,
            bottom: 0.2
          }
        },
        3: {
          textColor: "#FF0000",
          lineColor: '#2D90FF',
          lineWidth: {
            bottom: 0.2
          }
        },
        5: {
          lineColor: '#2D90FF',
          lineWidth: {
            right: 0.2,
            bottom: 0.2
          }
        },
      },
      columns: [
        { header: 'Item', dataKey: 'index' },
        { header: 'Description', dataKey: 'description' },
        { header: 'Quantity', dataKey: 'quantity' },
        { header: 'Non-Discounted Price', dataKey: 'originalPrice' },
        { header: 'Array Price', dataKey: 'unitPrice' },
        { header: 'Price Total', dataKey: 'total' },
      ],
      body: products,
      foot: [
        [
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                left: 0.2,
                bottom: 0.2
              }
            }
          },
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: 'Total',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: totalPrice,
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                right: 0.2,
                bottom: 0.2
              }
            }
          },
        ]
      ],
    });
  }

  doc.save(`${jobName} - ${quote.name} - MAS EC Quote.pdf`);
  doc.close();
};

export const generateFilterQuote = ({
  data
}: {
  data: {
    product: any,
    selection: any,
  }
}) => {
  const doc = new jsPDF();

  let products = [];

  if (data.product.type === "Combo") {
    products = [
      {
        index: 1,
        description: `Pre-filter \nEfficiency: ${data?.product?.pre_filter_rating.replace(/_/g, ' ')} \nDepth: ${data?.product?.pre_filter_depth}" \nLoading: ${data?.product?.loading}`,
        description2: `Final-filter \nEfficiency: ${data?.product?.final_filter_rating.replace(/_/g, ' ')} \nDepth: ${data?.product?.final_filter_depth}" \nLoading: ${data?.product?.loading}`,
        sizing: data?.selection.filters.map((filter: any) => `${filter.height}" x ${filter.width}"`).join(', \n'),
        quantity: data?.selection.filters.map((filter: any) => filter.count).join(', \n'),
        total: formatMoney(data?.selection.totalPrice),
      },
    ]
  } else {
    products = [
      {
        index: 1,
        description: `Efficiency: ${data?.product?.pre_filter_rating.replace(/_/g, ' ')} \nDepth: ${data?.product?.pre_filter_depth}" \nLoading: ${data?.product?.loading}`,  
        sizing: data?.selection.filters.map((filter: any) => `${filter.height}" x ${filter.width}"`).join(', \n'),
        quantity: data?.selection.filters.map((filter: any) => filter.count).join(', \n'),
        total: formatMoney(data?.selection.totalPrice),
      },
    ]
  }

  doc.addImage(masLogo, "PNG", 125, 0, 80, 40);
  doc.setFontSize(14);
  doc.text(`${data.product.type} Filter`, 10, 19);
  doc.setFontSize(10);
  doc.text(`Date: ${formatDate(new Date().toUTCString())}`, 10, 29);
  doc.setFont('helvetica', 'bold');
  // doc.text(quote.name, 10, 50);

  autoTable(doc, {
    startY: 53,
    margin: { top: 5, left: 10 },
    theme: 'plain',
    showHead: 'firstPage',
    showFoot: 'lastPage',
    headStyles: {
      fillColor: '#2D90FF',
      textColor: '#fff',
    },
    bodyStyles: {
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#2D90FF',
      lineWidth: {
        bottom: 0.2
      }
    },
    footStyles: {
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#2D90FF',
    },
    columnStyles: {
      0: {
        lineColor: '#2D90FF',
        lineWidth: {
          left: 0.2,
          bottom: 0.2
        }
      },
      5: {
        lineColor: '#2D90FF',
        lineWidth: {
          bottom: 0.2,
          right: 0.2,
        }
      },
    },
    columns: [
      { header: 'Item', dataKey: 'index' },
      { header: 'Description', dataKey: 'description' },
      { header: ' ', dataKey: 'description2' },
      { header: 'Sizing', dataKey: 'sizing' },
      { header: 'Quantity', dataKey: 'quantity' },
      { header: 'Price Total', dataKey: 'total' },
    ],
    body: products,
    foot: [
      [
        {
          content: ' ',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              left: 0.2,
              bottom: 0.2
            }
          }
        },
        {
          content: ' ',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              bottom: 0.2
            }
          }
        },
        {
          content: ' ',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              bottom: 0.2
            }
          }
        },
        {
          content: ' ',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              bottom: 0.2
            }
          }
        },
        {
          content: 'Total',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              bottom: 0.2
            }
          }
        },
        {
          content: formatMoney(data?.selection?.totalPrice),
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              right: 0.2,
              bottom: 0.2
            }
          }
        },
      ]
    ],
  });
  
  doc.save(`MAS ${data.product.type} Filter Quote.pdf`);
  doc.close();
};

export const generateCoilQuote = ({
  data
}: {
  data: {
    product: any,
    performance: any,
  }
}) => {
  const doc = new jsPDF();

  const products = [
    {
      index: 1,
      description: `Fins: ${data?.performance?.FinThickness} ${data?.product?.fin_material.charAt(0).toUpperCase() + data?.product?.fin_material.slice(1)}\nTubes: ${data?.performance?.TubeWallThickness} ${data?.product?.tube_material.charAt(0).toUpperCase() + data?.product?.tube_material.slice(1)}\nCasing: ${data?.product?.casing_gauge} ga ${data?.product?.casing_material.charAt(0).toUpperCase() + data?.product?.casing_material.slice(1)}`,  
      description2: `Headers: ${data?.performance?.SupplyHeaderDiameter}" ${data?.product?.header_material.charAt(0).toUpperCase() + data?.product?.header_material.slice(1)}\nConns: ${data?.performance?.SupplyConnectionSize}" ${data?.product?.connection_material} ${data?.product?.connection_type?.toUpperCase()}\nFeeds: ${data?.performance?.Nfeeds}`,
      quantity: 1,
      total: formatMoney(data?.performance?.Price * .80),
    }
  ]

  doc.addImage(masLogo, "PNG", 125, 0, 80, 40);
  doc.setFontSize(14);
  doc.text(`Coil: ${data?.performance?.ModelNumber}`, 10, 19);
  doc.setFontSize(10);
  doc.text(`Date: ${formatDate(new Date().toUTCString())}`, 10, 29);

  doc.setFont('helvetica', 'bold');
  // doc.text(quote.name, 10, 50);

  autoTable(doc, {
    startY: 53,
    margin: { top: 5, left: 10 },
    theme: 'plain',
    showHead: 'firstPage',
    showFoot: 'lastPage',
    headStyles: {
      fillColor: '#2D90FF',
      textColor: '#fff',
    },
    bodyStyles: {
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#2D90FF',
      lineWidth: {
        bottom: 0.2
      }
    },
    footStyles: {
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#2D90FF',
    },
    columnStyles: {
      0: {
        lineColor: '#2D90FF',
        lineWidth: {
          left: 0.2,
          bottom: 0.2
        }
      },
      4: {
        lineColor: '#2D90FF',
        lineWidth: {
          bottom: 0.2,
          right: 0.2,
        }
      },
    },
    columns: [
      { header: 'Item', dataKey: 'index' },
      { header: 'Description', dataKey: 'description' },
      { header: ' ', dataKey: 'description2' },
      { header: 'Quantity', dataKey: 'quantity' },
      { header: 'Price Total', dataKey: 'total' },
    ],
    body: products,
    foot: [
      [
        {
          content: ' ',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              left: 0.2,
              bottom: 0.2
            }
          }
        },
        {
          content: ' ',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              bottom: 0.2
            }
          }
        },
        {
          content: ' ',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              bottom: 0.2
            }
          }
        },
        {
          content: 'Total',
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              bottom: 0.2
            }
          }
        },
        {
          content: formatMoney(data?.performance?.Price * .80),
          styles: {
            lineColor: '#2D90FF',
            lineWidth: {
              right: 0.2,
              bottom: 0.2
            }
          }
        },
      ]
    ],
  });
  
  doc.save(`MAS Coil Quote.pdf`);
  doc.close();
};

const pdfGenerators = {
  generateQuotePDF,
  generateCoilQuote,
  generateFilterQuote,
};

export default pdfGenerators;