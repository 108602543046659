import generateDrawing from "@mashvac/daedex-common/src/drawing/drawing.js";
// import { SvgRenderer } from "../src/drawing/svgRenderer.js"
import { DxfRenderer } from "@mashvac/daedex-common/src/drawing/dxfRenderer.js";
import saveAs from "file-saver";
// const drawingSvg = generateDrawing(SvgRenderer(), system)

// This file is a WIP. 
// Combo filters not supported yet. 
// Panel Filters working but not yet verified as correct.

function prepareDataForDrawing(data: any): any {

  return {
    unit: {
      width: data.product.width,
      height: data.product.height,
      sections: [
        {
          id: data.selection.key,
          type: "filter",
          // requirements: {
          //   type: "FILTER",
          //   sizes: [
          //     {
          //       count: 24,
          //       width: 24,
          //       height: 24
          //     }
          //   ],
          //   loading: "FRONT",
          //   numSets: 1,
          //   filterType: "PANEL",
          //   arrangement: "FLAT",
          //   filterGuage: "INCLUDED",
          //   additionalLength: 0,
          //   panelFilterDepth: 2,
          //   panelFilterRating: "MERV_8"
          // },
          configuration: {
            type: "filter",
            nominal: {
              rects: data.selection.rects.map((rect: any) => ({
                x: rect.x,
                y: rect.y,
                width: rect.width,
                height: rect.height
              })),
              sizes: data.selection.filters.map((filter: any) => ({
                count: filter.count,
                width: filter.width,
                height: filter.height
              })),
              loading: "SIDE",
              efficiency: data.product.pre_filter_rating,
              filterType: data.product.type
            },
            // performance: {
            //   faceArea: data.selection.faceArea,
            //   faceVelocity: data.selection.faceVelocity,
            //   pressureDrop: {
            //     "mean": 0,
            //     "clean": 0,
            //     "dirty": 0
            //   }
            // }
          },
          measurements: {
            depth: data.product.pre_filter_depth
          },
          options: [],
          price: data.selection.price
        },
      ]
    }

  
      // project: {
      //   name: "Michael Test",
      //   date: "2/26/2025",
      //   product: "Daedex",
      //   quantity: 1,
      //   altitude: 0,
      //   owner: {
      //     name: "Danny",
      //     orgName: "Howdy CT"
      //   }
      // },
      // unit: {
      //   tag: "New unit",
      //   height: 80,
      //   width: 95,
      //   length: 0,
      //   weight: 0,
      //   baseRail: 0,
      //   performance: {
      //     cfm: "43000",
      //     staticPressure: {
      //       external: "3.2",
      //       internal: 0
      //     }
      //   },
      //   pricing: {
      //     total: null,
      //     quantity: 1,
      //     each: null
      //   },
      //   sections: [
      //     {
      //       id: "8e8af02d-a65c-4018-b9bd-c028a6ed2c5f",
      //       type: "filter",
      //       // requirements: {
      //       //   type: "FILTER",
      //       //   sizes: [
      //       //     {
      //       //       count: 24,
      //       //       width: 24,
      //       //       height: 24
      //       //     }
      //       //   ],
      //       //   loading: "FRONT",
      //       //   numSets: 1,
      //       //   filterType: "PANEL",
      //       //   arrangement: "FLAT",
      //       //   filterGuage: "INCLUDED",
      //       //   additionalLength: 0,
      //       //   panelFilterDepth: 2,
      //       //   panelFilterRating: "MERV_8"
      //       // },
      //       configuration: {
      //         type: "filter",
      //         nominal: {
      //           rects: [
      //             {
      //               x: 0,
      //               y: 0,
      //               width: 24,
      //               height: 12
      //             },
      //             {
      //               x: 24,
      //               y: 0,
      //               width: 24,
      //               height: 12
      //             },
      //             {
      //               x: 48,
      //               y: 0,
      //               width: 24,
      //               height: 12
      //             },
      //             {
      //               x: 0,
      //               y: 12,
      //               width: 24,
      //               height: 20
      //             },
      //             {
      //               x: 24,
      //               y: 12,
      //               width: 24,
      //               height: 20
      //             },
      //             {
      //               x: 48,
      //               y: 12,
      //               width: 24,
      //               height: 20
      //             },
      //             {
      //               x: 72,
      //               y: 12,
      //               width: 20,
      //               height: 20
      //             },
      //             {
      //               x: 0,
      //               y: 32,
      //               width: 24,
      //               height: 20
      //             },
      //             {
      //               x: 24,
      //               y: 32,
      //               width: 24,
      //               height: 20
      //             },
      //             {
      //               x: 48,
      //               y: 32,
      //               width: 24,
      //               height: 20
      //             },
      //             {
      //               x: 72,
      //               y: 32,
      //               width: 20,
      //               height: 20
      //             },
      //             {
      //               x: 0,
      //               y: 52,
      //               width: 24,
      //               height: 24
      //             },
      //             {
      //               x: 24,
      //               y: 52,
      //               width: 24,
      //               height: 24
      //             },
      //             {
      //               x: 48,
      //               y: 52,
      //               width: 24,
      //               height: 24
      //             },
      //             {
      //               x: 72,
      //               y: 52,
      //               width: 20,
      //               height: 24
      //             }
      //           ],
      //           sizes: [
      //             {
      //               count: 6,
      //               width: 24,
      //               height: 24
      //             },
      //             {
      //               count: 4,
      //               width: 20,
      //               height: 20
      //             },
      //             {
      //               count: 7,
      //               width: 24,
      //               height: 20
      //             },
      //             {
      //               count: 3,
      //               width: 24,
      //               height: 12
      //             }
      //           ],
      //           loading: "SIDE",
      //           efficiency: "MERV_13",
      //           filterType: "PANEL"
      //         },
      //       },
      //       measurements: {
      //         depth: 5
      //       },
      //       options: [],
      //       price: 0
      //     }
      //   ]
      // }
    }
  }


export const generateDrawingDXF = async ({
  data,
}: {
  data: any,
}) => {

  const system = prepareDataForDrawing(data);
  const drawingDxf = await generateDrawing(DxfRenderer(), system);
  const dxfString = drawingDxf.toString();

  const blob = new Blob([dxfString], { type: 'application/dxf' });
  saveAs(blob, `${data.product.pre_filter_rating} ${data.product.pre_filter_depth} Filter Drawing.dxf`);
}