import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Components
import GuideRoute from "./components/GuideRoute";
import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/Header";

// Pages
import Entry from "./pages/Entry";
import Profile from "./pages/Profile";
import PossibleConfigurations from "./pages/PossibleConfigurations";
import Job from "./pages/Job";
import Jobs from "./pages/Jobs";
import Users from "./pages/Users";
import UploadConfigurations from "./pages/UploadConfigurations";
import UploadModelFiles from "./pages/UploadModelFiles";

import { NON_AUTHORIZED_REDIRECT_URL } from "./constants";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToolsPage } from "./pages/Tools";

const App = () => {
  const [appSection, setAppSection] = useState<string>('MAS EC Selections');
  const handleAppSectionChange = (section: string) => {
    setAppSection(section);
  };
  
  return (
    <div className="App">
      <BrowserRouter>
        <Header appSectionChange={handleAppSectionChange} appSection={appSection}/>
        <Routes>
          {/* Public/Unauthenticated Routes */}
          <Route
            path="/"
            element={<Navigate to={NON_AUTHORIZED_REDIRECT_URL} />}
          />
          <Route
            path={NON_AUTHORIZED_REDIRECT_URL}
            element={
              <GuideRoute>
                <Entry />
              </GuideRoute>
            }
          />

          {/* Private Routes */}
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs"
            element={
              <PrivateRoute>
                <Jobs />
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs/:id"
            element={
              <PrivateRoute>
                <Job />
              </PrivateRoute>
            }
          />
          <Route
            path="/jobs/:job_id/products/:product_id/configurations"
            element={
              <PrivateRoute>
                <PossibleConfigurations />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-configurations"
            element={
              <PrivateRoute>
                <UploadConfigurations />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-model-files"
            element={
              <PrivateRoute>
                <UploadModelFiles />
              </PrivateRoute>
            }
          />
          <Route
            path="/tools"
            element={
              <PrivateRoute>
                <ToolsPage appSection={appSection} route="calculator"/>
              </PrivateRoute>
            }
          />
          <Route
            path="/tools/calculator"
            element={
              <PrivateRoute>
                <ToolsPage appSection={appSection} route="calculator"/>
              </PrivateRoute>
            }
          />
          <Route
            path="/tools/flats"
            element={
              <PrivateRoute>
                <ToolsPage appSection={appSection} route="flats" />
              </PrivateRoute>
            }
          />
          <Route
            path="/tools/search"
            element={
              <PrivateRoute>
                <ToolsPage appSection={appSection} route="search" />
              </PrivateRoute>
            }
          />
          <Route
            path="/tools/filters"
            element={
              <PrivateRoute>
                <ToolsPage appSection={appSection} route="filters" />
              </PrivateRoute>
            }
          />
          <Route
            path="/tools/coils"
            element={
              <PrivateRoute>
                <ToolsPage appSection={appSection} route="coils" />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-left"
      />
    </div>
  );
};

export default App;