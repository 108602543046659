import "./Tools.css"
import { useState, ChangeEvent } from "react";
import { Configuration, useGetCostsQuery, useGetControlPanelSizingQuery, Control_Panel_Sizing } from "../generated/graphql";
import { DesignParameters } from "../helpers/fan-curve";
import { Coefficient, JSObjectToRPMCoefficient } from "../helpers/file-checker";
import { validateAirflow, validatePressure, validateWidth, validateHeight } from "../helpers/product";
import { Input, FormFeedback, Spinner } from "reactstrap";
import { FanSelection, Knowledgebase, ProductSpec } from "@mashvac/daedex";
import { ToolsConfigDisplay } from "../components/ToolsConfigDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Config {
    configuration: Configuration,
    designIteration: DesignParameters,
    fla: number,
    mca: number,
    mocp: number,
    inputPower: number,
    efficiency: number,
    redundancy: number,
    inletSound: number,
    outletSound: number,
    sizing?: Control_Panel_Sizing | undefined,
    unitCost?: number,
    arrayWeight?: number,
}

export default function CalculatorDaedex() {

    const [productDetails, setProductDetails] = useState<any>({
        air_flow: 3000,
        total_static_pressure: 3,
        fan_count: 2,
        fan_model: "N88-56605",
        width: 100,
        height: 100,
        power: "460",
        backdraft_dampers: false,
        pressure_transducers: false,
        mas_ec_plus: false,
        voltage_override: 460,
        altitude: 0,
        large_scale_pricing: false,
        discounted_fan_price: "",
        over_100_redundancy: false,
    });

    const [touchedModel, setTouchedModel] = useState(false);
    const [touchedFanCount, setTouchedFanCount] = useState(false);
    const [touchedAirflow, setTouchedAirflow] = useState(false);
    const [touchedPressure, setTouchedPressure] = useState(false);
    const [touchedWidth, setTouchedWidth] = useState(false);
    const [touchedHeight, setTouchedHeight] = useState(false);
    const [touchedAltitude, setTouchedAltitude] = useState(false);
    const [configuration, setConfiguration] = useState<Config>();
    const [fanSelection, setFanSelection] = useState<FanSelection>();
    const [hasSearched, setHasSearched] = useState(false);
    const [loading, setLoading] = useState(false);

    const validAirflow =
        !touchedAirflow || validateAirflow(productDetails.air_flow);
    const validPressure =
        !touchedPressure || validatePressure(productDetails.total_static_pressure);
    const validWidth = !touchedWidth || validateWidth(productDetails.width);
    const validHeight = !touchedHeight || validateHeight(productDetails.height);

    const newSubmitNeeded = touchedModel || touchedFanCount || touchedAirflow || touchedPressure || touchedWidth || touchedHeight || touchedAltitude;

    const { data: costs } = useGetCostsQuery();
    const { data: panelSizing } = useGetControlPanelSizingQuery();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value: any = "";

        if (e.target.name === "outdoor_rating"){
            if (e.target.value === "indoor") value = false;
            if (e.target.value === "outdoor") value = true;
        } else if (e.target.name === "discounted_fan_price") {
            if (e.target.value === "") value = "";
            else {
                try {
                    value = parseFloat(e.target.value);
                } catch (error) {
                    console.error("Parse error: ", error);
                    return;
                }
            }
        } else if (e.target.type === "checkbox") {
            value = e.target.checked;
        } else if (e.target.type === "number") {
            try {
                value = parseFloat(e.target.value);
            } catch (error) {
                console.error("Parse error: ", error);
                return;
            }
        } else {
            value = e.target.value;
        }

        if (e.target.name === "altitude" && value > 10000) value = 10000;

        if (e.target.name === "mas_ec_plus" && e.target.checked === false){
            setProductDetails({ ...productDetails, [e.target.name]: value, "outdoor_rating": null });
        } else if (e.target.name === "power"){
            if (value === "460"){
                setProductDetails({ ...productDetails, [e.target.name]: value, "voltage_override": 460 });
            } else if (value === "208"){
                setProductDetails({ ...productDetails, [e.target.name]: value, "voltage_override": 208 });
            }
        } else {
            setProductDetails({ ...productDetails, [e.target.name]: value });
        }

        if(e.target.name === "fan_model") setTouchedModel(true);
        if(e.target.name === "fan_count") setTouchedFanCount(true);
        if(e.target.name === "altitude") setTouchedAltitude(true);
        if(e.target.name === "air_flow") setTouchedAirflow(true);
        if(e.target.name === "total_static_pressure") setTouchedPressure(true);
        if(e.target.name === "width") setTouchedWidth(true);
        if(e.target.name === "height") setTouchedHeight(true);
    }
    
    const KB = new Knowledgebase({debug: false, trace: false});

    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setHasSearched(true);
        daedexQuery();
        setTouchedModel(false);
        setTouchedFanCount(false);
        setTouchedAirflow(false);
        setTouchedPressure(false);
        setTouchedWidth(false);
        setTouchedHeight(false);
        setTouchedAltitude(false);
    }

    const daedexQuery = async () => {
        setLoading(true);

        let allConfigs = [];
        try {
            let selectionConfig : ProductSpec = {
                cfm: productDetails.air_flow,
                tsp: productDetails.total_static_pressure,
                width: productDetails.width,
                height: productDetails.height,
                power: parseInt(productDetails.power),
                altitude: productDetails.altitude,
                model: productDetails.fan_model,
                numFans: productDetails.fan_count,
            };

            if (productDetails.backdraft_dampers) selectionConfig.backdraftDampers = true;
            if (productDetails.over_100_redundancy) selectionConfig.overHundredRedundancy = true;
            if (productDetails.pressure_transducers) selectionConfig.pressureTransducers = true;
            if (productDetails.mas_ec_plus){
                selectionConfig.masECPlus = true;
                if (productDetails.outdoor_rating) selectionConfig.ECPlusType = "outdoor";
                else selectionConfig.ECPlusType = "indoor";
            } 
            if (productDetails.voltage_override !== 460 && productDetails.voltage_override !== 208) selectionConfig.voltageOverride = productDetails.voltage_override;
            if (productDetails.large_scale_pricing){
                selectionConfig.highVolumePricing = true;
                if (productDetails.discounted_fan_price !== ""){
                    selectionConfig.highVolumeFanPrice = productDetails.discounted_fan_price;
                }
            }

            const selections = KB.fanSelect(selectionConfig);
            
            for await (const element of selections){
                allConfigs.push(element);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        } finally {
            // Set configuration here with the correct config
            if (allConfigs.length > 0){
                setFanSelection(allConfigs[0]);
                let foundConfig = fanSelectionToConfig(allConfigs[0]);
                setConfiguration(foundConfig);
            } 
            else setConfiguration(undefined);

            setLoading(false);
        }
    }

    const fanSelectionToConfig = (foundSelection: any) => {
        let foundSizing: Control_Panel_Sizing = {
            cost: foundSelection.cost_breakdown.controls_cost,
            depth: foundSelection.selection_data.control_panel_depth,
            fan_count: foundSelection.fans.amount,
            height: foundSelection.selection_data.control_panel_height,
            weight: foundSelection.selection_data.control_panel_weight,
            width: foundSelection.selection_data.control_panel_width,
            // Shouldn't need these next two:
            amperage: null,
            id: null,
        }

        let foundConfiguration: Configuration = {
            blade_diameter: foundSelection.fan_desc.blade_diameter,
            blade_material: foundSelection.fan_desc.blade_material,
            damper_id: foundSelection.fan_desc.damper_id,
            depth: foundSelection.fan_desc.depth,
            fan_weight: foundSelection.fan_desc.weight,
            fla_460: foundSelection.fan_desc.fla_460,
            manufacturer: foundSelection.fan_desc.manufacturer,
            max_rpm: foundSelection.fan_desc.max_rpm,
            max_v_fla: foundSelection.fan_desc.max_v_fla,
            min_v_fla: foundSelection.fan_desc.min_v_fla,
            model: foundSelection.fan_desc.model,
            nominal_hp: foundSelection.fan_desc.nominal_hp,
            plate_dimensions: foundSelection.fan_desc.plate_dimensions,
            price: foundSelection.fan_desc.cost,
            // rmp_coefficients: JSON.stringify(foundSelection.fan_desc.rpm_coefficients),
            rmp_coefficients: null, // Try to make tools config work without this
            voltage: foundSelection.fan_desc.voltage,
            // shouldn't need any of these:
            fla_400: null,
            has_changed: null,
            id: null,
            products: null,
            products_aggregate: null,
        }

        let foundDI: DesignParameters = {
            fanCount: foundSelection.fans.amount,
            upper: {
                tsp: foundSelection.selection_data.upper_bound_tsp,
                rpm: foundSelection.selection_data.upper_bound_rpm,
                coefficient: JSObjectToRPMCoefficient(foundSelection.fan_desc.rpm_coefficients,foundSelection.selection_data.upper_bound_rpm),
            },
            lower: {
                tsp: foundSelection.selection_data.lower_bound_tsp,
                rpm: foundSelection.selection_data.lower_bound_rpm,
                coefficient: JSObjectToRPMCoefficient(foundSelection.fan_desc.rpm_coefficients,foundSelection.selection_data.lower_bound_rpm),
            },
            coefficient: {
                  a: foundSelection.selection_data.interpolated_coefficients_a,
                  b: foundSelection.selection_data.interpolated_coefficients_b,
                  c: foundSelection.selection_data.interpolated_coefficients_c,
                  d: foundSelection.selection_data.interpolated_coefficients_d
                } as Coefficient,
            interpolation: foundSelection.selection_data.interpolation,
        }

        let foundConfig: Config = {
            configuration: foundConfiguration, // TYPE
            designIteration: foundDI, // TYPE
            fla: foundSelection.selection_data.fla,
            mca: foundSelection.selection_data.mca,
            mocp: foundSelection.selection_data.mocp,
            inputPower: foundSelection.selection_data.horsepower,
            efficiency: foundSelection.selection_data.efficiency,
            redundancy: foundSelection.selection_data.redundancy,
            inletSound: foundSelection.selection_data.return_sum,
            outletSound: foundSelection.selection_data.discharge_sum,
            sizing: foundSizing, // TYPE
            unitCost: foundSelection.selection_data.total_cost,
            arrayWeight: foundSelection.selection_data.array_weight
        };

        return foundConfig;
    }

    return (<div className="tools-content">
        <form onSubmit={onSubmit}>
            <div>
                <div className="box-label">Fan Model</div>
                <Input
                    name="fan_model"
                    type="text"
                    value={productDetails.fan_model}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">Fan Count</div>
                <Input
                    name="fan_count"
                    type="number"
                    value={productDetails.fan_count}
                    onChange={handleChange}
                    invalid={productDetails.fan_count < 1}
                />
            </div>
            <div>
                <div className="box-label">Air Flow (CFM)</div>
                <Input
                    name="air_flow"
                    type="number"
                    placeholder="air flow"
                    value={productDetails.air_flow}
                    onChange={handleChange}
                    invalid={!validAirflow}
                    onBlur={() => setTouchedAirflow(true)}
                />
            </div>
            <div>
                <div className="box-label">Total SP</div>
                <Input
                    name="total_static_pressure"
                    type="number"
                    value={productDetails.total_static_pressure}
                    onChange={handleChange}
                    invalid={!validPressure}
                    onBlur={() => setTouchedPressure(true)}
                />
                <FormFeedback>2 decimal place only</FormFeedback>
            </div>
            <div>
                <div className="box-label">Width</div>
                <Input
                    name="width"
                    type="number"
                    value={productDetails.width}
                    onChange={handleChange}
                    invalid={!validWidth}
                    onBlur={() => setTouchedWidth(true)}
                />
                <FormFeedback>1 decimal place only</FormFeedback>
            </div>
            <div>
                <div className="box-label">Height</div>
                <Input
                    name="height"
                    type="number"
                    value={productDetails.height}
                    onChange={handleChange}
                    invalid={!validHeight}
                    onBlur={() => setTouchedHeight(true)}
                />
                <FormFeedback>1 decimal place only</FormFeedback>
            </div>
            <div>
                <div className="box-label">Power</div>
                <Input
                    name="power"
                    type="select"
                    value={productDetails.power}
                    onChange={handleChange}
                >
                    <option value={460}>460V/3~/60HZ</option>
                    <option value={208}>208V/3~/60HZ</option>
                </Input>
            </div>
            <div>
                <div className="box-label">Voltage Override <span className="small-text">({productDetails.power === "460" ? "380V" : "200V"}-{productDetails.power === "460" ? "480V" : "240V"})</span></div>
                <Input
                    name="voltage_override"
                    type="number"
                    value={productDetails.voltage_override}
                    max={productDetails.power === "460" ? 480 : 240}
                    min={productDetails.power === "460" ? 380 : 200}
                    onChange={handleChange}
                />
            </div>
            <div>
                <div className="box-label">altitude</div>
                <Input
                    name="altitude"
                    type="number"
                    value={productDetails.altitude}
                    max={10000}
                    min={0}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="backdraft_dampers"
                        type="checkbox"
                        checked={productDetails.backdraft_dampers || false}
                        onChange={handleChange}
                    /> Backdraft Dampers</label>
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="pressure_transducers"
                        type="checkbox"
                        checked={productDetails.pressure_transducers || false}
                        onChange={handleChange}
                    /> Pressure Transducers</label>
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="over_100_redundancy"
                        type="checkbox"
                        checked={productDetails.over_100_redundancy || false}
                        onChange={handleChange}
                    /> Over 100% Redundancy</label>
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="mas_ec_plus"
                        type="checkbox"
                        checked={productDetails.mas_ec_plus || false}
                        onChange={handleChange}
                    /> MAS EC+</label>
            </div>
            <div className={!!productDetails.mas_ec_plus ? "collapse-open" : "collapse-closed"}>
                <label className="radio-holder">
                    <input 
                    type="radio" 
                    name="outdoor_rating" 
                    className="radio-button"
                    value="indoor"
                    checked={!productDetails.outdoor_rating} 
                    onChange={handleChange}
                    />
                    Indoor
                </label>
                <label>
                    <input 
                    type="radio" 
                    name="outdoor_rating" 
                    className="radio-button"
                    value="outdoor"
                    checked={!!productDetails.outdoor_rating} 
                    onChange={handleChange}
                    />
                    Outdoor
                </label>
            </div>
            <div>
                <label className="box-label">
                    <Input
                        name="large_scale_pricing"
                        type="checkbox"
                        checked={productDetails.large_scale_pricing || false}
                        onChange={handleChange}
                    /> High Volume Pricing</label>
            </div>
            <div className={!!productDetails.large_scale_pricing ? "collapse-open last-collapse" : "collapse-closed"}>
                <label className="radio-holder">
                    <span className="small-text">Discounted Fan Price</span>
                    <input 
                    type="number" 
                    name="discounted_fan_price" 
                    placeholder="Default Price"
                    onChange={handleChange}
                    value={productDetails.discounted_fan_price || ""}
                    />
                </label>
            </div>
            <button type="submit">Submit</button>
        </form>
        <div className="results">
            {loading && <div className="loading">
                <Spinner />
            </div>}
            {!loading && !hasSearched && <div />}
            {!loading && !configuration && hasSearched && <div className="no-results">
                No results found
            </div>}
            {newSubmitNeeded && <div className="re-submit-needed">
                <FontAwesomeIcon icon="warning" className="icon-sides-padding" />
                Warning: Some information hasn't been updated. Press 'Submit' to update all displayed info.
                <FontAwesomeIcon icon="warning" className="icon-sides-padding" />
            </div>}
            {!loading && !!configuration && <div className="calculator-result">
                <ToolsConfigDisplay
                    fanSelection={fanSelection}
                    product={productDetails}
                    costs={costs?.cost}
                    key={configuration.configuration.id}
                    allSizings={panelSizing?.control_panel_sizing}
                />
            </div>}
        </div>
    </div>);
};