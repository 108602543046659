import {
    AlignmentType,
    BorderStyle,
    Document,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    TextRun,
    VerticalAlign,
    WidthType,
  } from "docx";
  import { saveAs } from "file-saver";
  import _ from "lodash";
  
  const styledTableCell = ({
    cellType,
    width = 10, 
    columnSpan = 1, 
    rowSpan = 1,
    indent = 0,
    spacing = 10,
    fontSize = 20,
    bold = false,
    text,
    units,
    borderBottom,
    verticalAlign,
  }: {
    cellType?: 'title' | 'subtitle' | 'header',
    width?: number, 
    columnSpan?: number, 
    rowSpan?: number,
    indent?: number,
    spacing?: number,
    fontSize?: number,
    bold?: boolean,
    text: any,
    units?: string,
    borderBottom?: number,
    verticalAlign?: VerticalAlign,
  }) => {
  
    const getCellFill = () => {
      if(cellType === 'header') return 'D8D8D8';
      if(cellType === 'title') return '1A4061';
      if(cellType === 'subtitle') return 'B8CCE4';
      return 'F2F2F2';
    }
    
    return new TableCell({
      borders: {
        bottom: {
          color: "FFFFFF",
          size: cellType === 'header' ? 1 : borderBottom ? borderBottom : 20,
          style: BorderStyle.SINGLE,
        },
        left: {
          color: "FFFFFF",
          size: 0,
          style: BorderStyle.NONE,
        },
        right: {
          color: "FFFFFF",
          size: 1,
          style: BorderStyle.SINGLE,
        },
        top: {
          color: "FFFFFF",
          size: cellType === 'header' ? 10 : 1,
          style: BorderStyle.SINGLE,
        },
      },
      shading: {
        fill: getCellFill(),
      },
      width: {
        size: width,
        type: WidthType.PERCENTAGE,
      },
      columnSpan: columnSpan,
      rowSpan: rowSpan,
      verticalAlign: cellType === 'title' ? VerticalAlign.CENTER : verticalAlign ? verticalAlign : VerticalAlign.TOP,
      children: [
        new Paragraph({
          alignment: cellType === 'title' ? AlignmentType.LEFT : AlignmentType.CENTER,
          indent: {
            left: indent
          },
          spacing: {
            before: spacing,
            after: spacing,
          },
          children: [
            new TextRun({
              text: text,
              font: 'calibri',
              size: cellType === 'header' ? 16 : fontSize,
              bold: cellType === 'header' || cellType === 'subtitle' || bold,
              color: cellType === 'title' ? 'FFFFFF' : '000000'
            }),
            units &&
            new TextRun({
              text: ' ' + units,
              font: 'calibri',
              size: 16,
              })
            ]
          })
        ],
    });
  };
  
  const generateSubmittalElements = ({
    docType,
    data,
  }: {
    docType: string,
    data: any,
  }) => {

    let tableElements: any[] = [];
  
    const titleRow = new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          children: [
            styledTableCell({
              cellType: 'title',
              width: 25,
              indent: 64,
              spacing: 20,
              text: `${docType}`,
              bold: true,
            }),
            styledTableCell({
              cellType: 'title',
              width: 25,
              indent: 64,
              fontSize: 16,
              text: "Component:"
            }),
            styledTableCell({
              cellType: 'title',
              width: 25,
              indent: 64,
              fontSize: 16,
              text: "Length:"
            }),
            styledTableCell({
              cellType: 'title',
              width: 25,
              indent: 64,
              fontSize: 16,
              text: "Shipping Section:"
            }),
          ]
        })
      ]
    });
    tableElements.push(titleRow);
  
    if(docType === 'Chilled Water Coil') {

      const tableGroup1 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Coil Model"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Total Capacity"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Sensible Capacity"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Number of Coils"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Number of Rows"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Fins per Inch"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Tube Diameter"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Tube Spacing         (Face x Row)",
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                width: 12.5,
                text: `${data.performance.ModelNumber.split("-")[0]}`
              }),
              styledTableCell({
                width: 12.5,
                text: `${Math.round(data.performance.TotalCapacity)}`,
                units: "Btu/hr"
              }),
              styledTableCell({
                width: 12.5,
                text: `${Math.round(data.performance.SensibleCapacity)}`,
                units: "Btu/hr"
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.product.coils_per_bank}`
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.performance.Nrows}`
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.performance.Fpi}`
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.product.tube_diameter}`,
                units: "in"
              }),
              new TableCell({
                borders: {
                  bottom: {
                    color: "FFFFFF",
                    size: 1,
                    style: BorderStyle.SINGLE,
                  },
                  left: {
                    color: "FFFFFF",
                    size: 0,
                    style: BorderStyle.NONE,
                  },
                  right: {
                    color: "FFFFFF",
                    size: 1,
                    style: BorderStyle.SINGLE,
                  },
                  top: {
                    color: "FFFFFF",
                    size: 1,
                    style: BorderStyle.SINGLE,
                  },
                },
                shading: {
                  fill: 'F2F2F2'
                },
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: `${data.product.tube_face_spacing}`,
                        font: 'calibri',
                        size: 20,
                      }),
                      new TextRun({
                        text: ' in',
                        font: 'calibri',
                        size: 16,
                      }),
                      new TextRun({
                        text: ' x ',
                        font: 'calibri',
                        size: 20,
                      }),
                      new TextRun({
                        text: `${data.product.tube_row_spacing}`,
                        font: 'calibri',
                        size: 20,
                      }),
                      new TextRun({
                        text: ' in',
                        font: 'calibri',
                        size: 16,
                      }),
                    ]
                  })
                ]
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup1);

      const tableGroup2 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 10,
                rowSpan: 3,
                text: "Air Volume"
              }),
              styledTableCell({
                cellType: 'header',
                width: 40,
                columnSpan: 4,
                rowSpan: 1,
                text: "Air Temperature"
              }),
              styledTableCell({
                cellType: 'header',
                width: 10,
                rowSpan: 3,
                text: "Coil Air Pressure Drop"
              }),
              styledTableCell({
                cellType: 'header',
                width: 10,
                rowSpan: 3,
                text: "Finned Height"
              }),
              styledTableCell({
                cellType: 'header',
                width: 10,
                rowSpan: 3,
                text: "Finned Length"
              }),
              styledTableCell({
                cellType: 'header',
                width: 10,
                rowSpan: 3,
                text: "Face Area"
              }),
              styledTableCell({
                cellType: 'header',
                width: 10,
                rowSpan: 3,
                text: "Face Velocity"
              }),
            ],
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                columnSpan: 2,
                text: "Entering"
              }),
              styledTableCell({
                cellType: 'header',
                columnSpan: 2,
                text: "Leaving"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                text: "Dry Bulb" // Entering
              }),
              styledTableCell({
                cellType: 'header',
                text: "Wet Bulb" // Entering
              }),
              styledTableCell({
                cellType: 'header',
                text: "Dry Bulb" // Leaving
              }),
              styledTableCell({
                cellType: 'header',
                text: "Wet Bulb" // Leaving
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                text: `${data.product.air_flow}`,
                units: "cfm"
              }),
              styledTableCell({
                text: `${data.product.entering_air_dry_bulb.toFixed(1)}`,
                units: "°F"
              }),
              styledTableCell({
                text: `${data.product.entering_air_wet_bulb.toFixed(1)}`,
                units: "°F"
              }),
              styledTableCell({
                text: `${data.performance.LeavingAirDryBulb.toFixed(1)}`,
                units: "°F"
              }),
              styledTableCell({
                text: `${data.performance.LeavingAirWetBulb.toFixed(1)}`,
                units: "°F"
              }),
              styledTableCell({
                text: `${data.performance.AirsidePressureDrop.toFixed(2)}`,
                units: "inWc"
              }),
              styledTableCell({
                text: `${data.performance.FH}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.performance.FL}`,
                units: "in"
              }),
              styledTableCell({
                text: `${(data.performance.FH * data.performance.FL / 144).toFixed(2)}`,
                units: "ft²"
              }),
              styledTableCell({
                text: `${Math.round(data.performance.FaceVelocity)}`,
                units: "ft/min"
              }),
            ],
          })
        ]
      });
      tableElements.push(tableGroup2);

      const tableGroup3 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 2/7,
                columnSpan: 2,
                text: `Water (${100 - data.product.glycol_mass_percent}%) ${data.product.fluid === "Water" ? "" : data.product.fluid === "PropyleneGlycol" ? `/ Propylene Glycol (${data.product.glycol_mass_percent}%)` : `/ Ethylene Glycol (${data.product.glycol_mass_percent}%)`}`
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Flow Rate"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Pressure Drop"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Velocity"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Volume"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Weight"
              })
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                text: "Entering"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                text: "Leaving"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                text: `${data.product.entering_fluid_temp.toFixed(1)}`,
                units: "°F"
              }),
              styledTableCell({
                text: `${data.performance.LeavingFluidTemp.toFixed(1)}`,
                units: "°F"
              }),
              styledTableCell({
                text: `${data.performance.GPM.toFixed(2)}`,
                units: "gpm"
              }),
              styledTableCell({
                text: `${data.performance.FluidPressureDrop.toFixed(2)}`,
                units: "ftHd"
              }),
              styledTableCell({
                text: `${data.performance.FluidVelocity.toFixed(2)}`,
                units: "ft/s"
              }),
              styledTableCell({
                text: `${(data.performance.InternalVolume1 * 7.480519).toFixed(1)}`, // convert to gallons
                units: "gal"
              }),
              styledTableCell({
                text: `${(data.performance.OperatingWeight).toFixed(2)}`,
                units: "lb"
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup3);

      const tableGroup4 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 100,
                columnSpan: 4,
                text: "Connection [Data Per Coil]"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Type"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Size"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Location"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Material"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                text: `${data.product.connection_type.toUpperCase()}`
              }),
              styledTableCell({
                text: `${data.performance.SupplyConnectionSize.toFixed(2)}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.product.coil_hand.replace("Hand", "")}`
              }),
              styledTableCell({
                text: `${data.product.connection_material}`
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup4);

      const tableGroup5 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 60,
                columnSpan: 3,
                text: "Material"
              }),
              styledTableCell({
                cellType: 'header',
                width: 20,
                rowSpan: 2,
                text: "Drain Pan"
              }),
              styledTableCell({
                cellType: 'header',
                width: 20,
                rowSpan: 2,
                text: "Drain Side"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 20,
                text: "Fin"
              }),
              styledTableCell({
                cellType: 'header',
                width: 20,
                text: "Tube"
              }),
              styledTableCell({
                cellType: 'header',
                width: 20,
                text: "Case"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                width: 20,
                text: `${data.product.fin_material.charAt(0).toUpperCase() + data.product.fin_material.slice(1)} ${data.performance.FinThickness.toString().replace(/^0+/, '')}`,
                units: "in"
              }),
              styledTableCell({
                width: 20,
                text: `${data.product.tube_material.charAt(0).toUpperCase() + data.product.tube_material.slice(1)} ${data.performance.TubeWallThickness.toString().replace(/^0+/, '')}`,
                units: "in"
              }),
              styledTableCell({
                width: 20,
                text: `${data.product.casing_material.charAt(0).toUpperCase() + data.product.casing_material.slice(1)}`
              }),
              styledTableCell({
                width: 20,
                text: `${data.product.drain_pan_material}`
              }),
              styledTableCell({
                width: 20,
                text: `${data.product.drain_pan_connection_side}`
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup5);

      const tableGroup6 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'subtitle',
                width: 100,
                columnSpan: 4,
                text: "Door",
                fontSize: 16,
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Location"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Drive Side Door Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Opp. Drv. Side Door Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Opening"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                text: `${data.product.door_location}`
              }),
              styledTableCell({
                text: `${data.product.door_width_drive_side}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.product.door_width_opposite_drive_side}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.product.door_opening}`
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup6);

    } else if(docType === 'Panel Filter') {
      
      const tableGroup1 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Type"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Efficiency"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Face Velocity"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Face Area"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Air Volume"
              }),
              styledTableCell({
                cellType: 'header',
                width: 12.5,
                text: "Filter Loading"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                width: 12.5,
                text: `${data.product.type}`
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.product.pre_filter_rating.replace(/_/g, ' ')}`,
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.selection.face_velocity.toFixed(0)}`,
                units: "ft/min"
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.selection.face_area.toFixed(1)}`,
                units: "ft²"
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.product.air_flow}`,
                units: "cfm"
              }),
              styledTableCell({
                width: 12.5,
                text: `${data.product.loading}`
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup1);

      const extrafilterRows = data.selection.filters.slice(1).map((filter) => {
        return new TableRow({
          children: [
            styledTableCell({
              text: `${filter.count}`,
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${filter.height}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${filter.width}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
          ]
        });
      });

      const tableGroup2 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 3/7,
                columnSpan: 3,
                text: "Air Pressure Drop"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Number of Filters"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Height"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                rowSpan: 2,
                text: "Depth"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                text: "Clean Air"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                text: "Mean Air"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/7,
                text: "Dirty Air"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                text: `${data.selection.pressureDrop.clean.toFixed(2)}`,
                units: "inWc",
                bold: data.product.pressure_drop === "clean" ? true : false,
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.mean.toFixed(2)}`,
                units: "inWc",
                bold: data.product.pressure_drop === "mean" ? true : false,
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.dirty.toFixed(2)}`,
                units: "inWc",
                bold: data.product.pressure_drop === "dirty" ? true : false,
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].count}`,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].height}`,
                units: "in",
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].width}`,
                units: "in",
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.product.pre_filter_depth}`,
                units: "in",
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
              }),
            ]
          }),
          ...extrafilterRows,
        ]
      });
      tableElements.push(tableGroup2);

      const tableGroup3 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'subtitle',
                width: 100,
                columnSpan: 4,
                text: "Door",
                fontSize: 16,
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Location"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Drive Side Door Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Opp. Drv. Side Door Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Opening"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                text: `${data.product.door_location}`
              }),
              styledTableCell({
                text: `${data.product.door_width_drive_side}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.product.door_width_opposite_drive_side}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.product.door_opening}`
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup3);

    } else if(docType === 'Combo Filter') {

      const tableGroup1 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Access"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Face Velocity"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Face Area"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Air Volume"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                width: 25,
                text: `${data.product.loading}`
              }),
              styledTableCell({
                width: 25,
                text: `${data.selection.face_velocity.toFixed(0)}`,
                units: "ft/min"
              }),
              styledTableCell({
                width: 25,
                text: `${data.selection.face_area.toFixed(1)}`,
                units: "ft²"
              }),
              styledTableCell({
                width: 25,
                text: `${data.product.air_flow}`,
                units: "cfm"
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup1);

      const additionalPreFilterRows = data.selection.filters.slice(1).map((filter) => {
        return new TableRow({
          children: [
            styledTableCell({
              text: `${filter.count}`,
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${filter.height}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${filter.width}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${data.product.pre_filter_depth}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
          ]
        });
      });

      const additionalFinalFilterRows = data.selection.filters.slice(1).map((filter) => {
        return new TableRow({
          children: [
            styledTableCell({
              text: `${filter.count}`,
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${filter.height}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${filter.width}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
            styledTableCell({
              text: `${data.product.final_filter_depth}`,
              units: "in",
              rowSpan: 1,
              borderBottom: 1,
            }),
          ]
        });
      });

      const tableGroup2 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                rowSpan: 2,
                text: "Portion"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                rowSpan: 2,
                text: "Type"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                rowSpan: 2,
                text: "Efficiency"
              }),
              styledTableCell({
                cellType: 'header',
                width: 4/11,
                columnSpan: 4,
                text: "Air Pressure Drop"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                rowSpan: 2,
                text: "Number of Filters"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                rowSpan: 2,
                text: "Height"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                rowSpan: 2,
                text: "Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                rowSpan: 2,
                text: "Depth"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                text: "Clean Air"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                text: "Mean Air"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                text: "Dirty Air"
              }),
              styledTableCell({
                cellType: 'header',
                width: 1/11,
                text: "User Spec"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                text: 'Pre-Filter',
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
              }),
              styledTableCell({
                text: '',
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.product.pre_filter_rating.replace(/_/g, ' ')}`,
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.clean.toFixed(2)}`,
                units: "inWc",
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                borderBottom: 1,
                bold: data.product.pressure_drop === "clean" ? true : false,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.mean.toFixed(2)}`,
                units: "inWc",
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                borderBottom: 1,
                bold: data.product.pressure_drop === "mean" ? true : false,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.dirty.toFixed(2)}`,
                units: "inWc",
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                borderBottom: 1,
                bold: data.product.pressure_drop === "dirty" ? true : false,
              }),
              styledTableCell({
                text: `${data.product.pressure_drop === "user" ? data?.product?.user_pressure_drop?.toFixed(2) : "n/a"}`,
                ...(data.product.pressure_drop === "user" && { units: "inWc" }),
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                borderBottom: 1,
                bold: data.product.pressure_drop === "user" ? true : false,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].count}`,
                rowSpan: 1,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].height}`,
                units: "in",
                rowSpan: 1,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].width}`,
                units: "in",
                rowSpan: 1,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.product.pre_filter_depth}`,
                units: "in",
                rowSpan: 1,
                borderBottom: 1,
              }),
            ]
          }),
          ...additionalPreFilterRows,
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                text: 'Filter',
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                borderBottom: 20,
              }),
              styledTableCell({
                text: '',
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
              }),
              styledTableCell({
                text: `${data.product.final_filter_rating.replace(/_/g, ' ')}`,
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.final_clean.toFixed(2)}`,
                units: "inWc",
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                bold: data.product.pressure_drop === "clean" ? true : false,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.final_mean.toFixed(2)}`,
                units: "inWc",
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                bold: data.product.pressure_drop === "mean" ? true : false,
              }),
              styledTableCell({
                text: `${data.selection.pressureDrop.final_dirty.toFixed(2)}`,
                units: "inWc",
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                bold: data.product.pressure_drop === "dirty" ? true : false,
              }),
              styledTableCell({
                text: `${data.product.pressure_drop === "user" ? data?.product?.user_pressure_drop?.toFixed(2) : "n/a"}`,
                ...(data.product.pressure_drop === "user" && { units: "inWc" }),
                rowSpan: data.selection.filters.length,
                verticalAlign: VerticalAlign.CENTER,
                bold: data.product.pressure_drop === "user" ? true : false,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].count}`,
                rowSpan: 1,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].height}`,
                units: "in",
                rowSpan: 1,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.selection.filters[0].width}`,
                units: "in",
                rowSpan: 1,
                borderBottom: 1,
              }),
              styledTableCell({
                text: `${data.product.final_filter_depth}`,
                units: "in",
                rowSpan: 1,
                borderBottom: 1,
              }),
            ]
          }),
          ...additionalFinalFilterRows,
        ]
      });
      tableElements.push(tableGroup2);

      const tableGroup3 = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'subtitle',
                width: 100,
                columnSpan: 4,
                text: "Door",
                fontSize: 16,
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Location"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Drive Side Door Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Opp. Drv. Side Door Width"
              }),
              styledTableCell({
                cellType: 'header',
                width: 25,
                text: "Opening"
              }),
            ]
          }),
          new TableRow({
            children: [
              styledTableCell({
                text: `${data.product.door_location}`
              }),
              styledTableCell({
                text: `${data.product.door_width_drive_side}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.product.door_width_opposite_drive_side}`,
                units: "in"
              }),
              styledTableCell({
                text: `${data.product.door_opening}`
              }),
            ]
          })
        ]
      });
      tableElements.push(tableGroup3);        
    }
      
    return [
      {
        children: [
          // new Paragraph({
          //   text: `Product: coil example`,
          // }),
          // new Paragraph({
          //   children: [],
          // }),
          new Paragraph({
            children: [],
          }),
          new Table({ 
            width: {
              size: 114,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              bottom: {
                style: BorderStyle.NIL,
              },
              left: {
                style: BorderStyle.NIL,
              },
              right: {
                style: BorderStyle.NIL,
              },
              top: {
                style: BorderStyle.NIL,
              },
              insideHorizontal: {
                style: BorderStyle.NIL,
              },
              insideVertical: {
                style: BorderStyle.NIL,
              },
            },
            alignment: AlignmentType.CENTER,
            rows: tableElements.map((element) => {
              return new TableRow({
                children: [
                  new TableCell({
                    children: [element]
                  })
                ]
              })
            })
          }),
        ],
      },
    ];
  };

  const generateDaikinDocx = async ({
    docType,
    data,
  }: {
    docType: 'Chilled Water Coil' | 'Panel Filter' | 'Combo Filter',
    data: any;
  }) => {
  
    // let numBlank = 0;
    // products.forEach((p) => {
    //   if(p.configuration === null) numBlank++;
    // });
  
    // if(numBlank > 0){
    //   toast.error("Unable to create submittal. Not all products have a config set.");
    //   return ;
    // }
  
    // const docElements = products.map((p) => generateSubmittalElements({
    //   product: p,
    // }))
  
    let docElements: any[] = [];

    docElements = [generateSubmittalElements({ docType: docType, data: data})];
  
    const doc = new Document({
      sections: _.flatten(docElements),
    });
  
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Mas ${docType} Submittal.docx`);
    });
  };
  
  export default generateDaikinDocx;